export class BaseBlockExplorer {
  public readonly url: string = '';

  public constructor(url = '') {
    this.url = url;
  }

  public getAddressUrl(address: string): string {
    return '';
  }

  public getTokenUrl(tokenAddress: string): string {
    return '';
  }

  public getTransactionUrl(transactionHash: string): string {
    return '';
  }

  public getNftUrl(address: string, transactionHash: string): string {
    return '';
  }
}

export class EtherscanBlockExplorer extends BaseBlockExplorer {
  public override getAddressUrl(address: string): string {
    return `${this.url}/address/${address}`;
  }

  public override getTokenUrl(tokenAddress: string): string {
    return `${this.url}/token/${tokenAddress}`;
  }

  public override getTransactionUrl(transactionHash: string): string {
    return `${this.url}/tx/${transactionHash}`;
  }

  public override getNftUrl(address: string, id: string): string {
    return `${this.url}/nft/${address}/${id}`;
  }
}
