import { useCallback, useState } from 'react';

export function useInternalState<T>(
  valueOverride: T | null | undefined,
  valueDefault: T,
  onUpdate?: (value: T) => void,
): [T, (value: T) => void] {
  const [state, setState] = useState(valueDefault);

  const setStateAndUpdate = useCallback((value: T) => {
    setState(value);

    onUpdate?.(value);
  }, [onUpdate]);

  return [valueOverride ?? state, setStateAndUpdate];
}
