import type { ReducersMapObject } from '@reduxjs/toolkit';
import type { BackendClientConfig } from '../backend';
import type { Profile } from '../entity';
import type { IndexClientConfig } from '../indexing';
import type { RealmDataClientConfig } from '../realm';
import type { AppStore, AppStoreSlices } from '../store';

import { BackendClient } from '../backend';
import { BlockchainReaders, EthereumManager, WalletManager } from '../blockchain';
import { AppCache, CacheStore } from '../cache';
import { DEFAULT_PROFILE, OperationCreator } from '../entity';
import { IndexClient } from '../indexing';
import { Logger } from '../log';
import { RealmDataClient } from '../realm';
import { createStore } from '../store';

function getOrThrow<T>(entity: T | null, message: string): T {
  if (entity == null) {
    throw new Error(message);
  }

  return entity;
}

export type AppOption = {
  walletConnect?: {
    projectId: string;
  };
  profiles?: Profile[];
  reducer?: ReducersMapObject;
  backend?: BackendClientConfig;
  realm?: RealmDataClientConfig;
  index?: IndexClientConfig;
};

export class App {
  public option: AppOption;
  public profiles: Profile[];
  public logger: Logger = new Logger();
  public localCache = new CacheStore(window.localStorage);
  public sessionCache = new CacheStore(window.sessionStorage);
  public cache: AppCache;
  public store: AppStore;
  public slices: AppStoreSlices;
  public blockchainReaders: BlockchainReaders;
  public walletManager: WalletManager;
  public ethereumManager: EthereumManager;

  private _backendClient: BackendClient | null = null;
  private _realmClient: RealmDataClient | null = null;
  private _indexClient: IndexClient | null = null;
  private _operationCreator: OperationCreator | null = null;

  public constructor(option?: AppOption) {
    this.option = option ?? {};
    this.profiles = this.option.profiles ?? [];

    this.cache = new AppCache(this.profiles, this.localCache, this.sessionCache);

    const { store, slices } = createStore({
      cache: this.cache,
      profiles: this.profiles,
      reducer: this.option.reducer,
    });

    this.store = store;
    this.slices = slices;
    this.blockchainReaders = new BlockchainReaders(this.profiles, this.logger);
    this.walletManager = new WalletManager(this.profiles, this.blockchainReaders, this.option.walletConnect?.projectId ?? '');

    this.ethereumManager = new EthereumManager({
      logger: this.logger,
      cache: this.cache,
      walletManager: this.walletManager,
      notify: (chainId, account, walletInfo) => {
        store.dispatch(slices.wallet.actions.setWallet({ chainId, account, walletInfo }));
      },
    });

    if (this.option.backend != null) {
      this._backendClient = new BackendClient(this.profiles, this.logger, this.option.backend);
    }

    if (this._backendClient != null) {
      this._operationCreator = new OperationCreator(this._backendClient);
    }

    if (this.option.realm != null) {
      this._realmClient = new RealmDataClient(this.profiles, this.option.realm);
    }

    if (this.option.index != null) {
      this._indexClient = new IndexClient(this.option.index);
    }
  }

  public get backendClient() {
    return getOrThrow(this._backendClient, 'backendClient has not been initialized.');
  }

  public get realmClient() {
    return getOrThrow(this._realmClient, 'realmClient has not been initialized.');
  }

  public get indexClient() {
    return getOrThrow(this._indexClient, 'indexClient has not been initialized.');
  }

  public get operationCreator() {
    return getOrThrow(this._operationCreator, 'operationCreator has not been initialized.');
  }

  public async init() {
    try {
      await this.ethereumManager.connectEagerly();
    } catch (e) {
      this.logger.log('App failed to init.', e);
    }
  }

  public getFirstProfileOrDefault(): Profile {
    return this.profiles[0] ?? DEFAULT_PROFILE;
  }

  public getProfileByUniChainId(uniChainId: string | null | undefined): Profile | undefined {
    return this.profiles.find((item) => item.chain.uniChainId === uniChainId);
  }

  public getProfileByChainId(chainId: number | null | undefined): Profile | undefined {
    return this.profiles.find((item) => item.chain.chainId === chainId);
  }

  public getProfileByChainIdOrDefault(chainId: number | null | undefined): Profile {
    return this.getProfileByChainId(chainId) ?? DEFAULT_PROFILE;
  }
}
