import { ZeroAddress } from 'ethers';

export type RawAddressConfig = {
  TokenBridgePier: string;
};

export type AddressConfig = {
  TokenBridgePier: string;
};

export const DEFAULT_ADDRESS_CONFIG: AddressConfig = {
  TokenBridgePier: ZeroAddress,
};

export function extractAddress(address: RawAddressConfig): AddressConfig {
  return {
    TokenBridgePier: address.TokenBridgePier,
  };
}
