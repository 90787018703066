import type { BaseService } from '../../blockchain';

import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useBlockchain, useTranslationComponent, useUiProfile } from '../../hook';
import { createCompareFunc } from '../../util';
import { ActionButton } from '../input';
import { Modal } from '../layout';

import styles from './ConnectWalletModal.module.scss';

export function ConnectWalletModal() {
  const { t } = useTranslationComponent(['wallet']);
  const { app, profile } = useUiProfile();
  const { connectWalletModalVisible, setConnectWalletModalVisible, connect } = useBlockchain();

  const services = useMemo(() => {
    return app.walletManager.services.sort(createCompareFunc((item) => item.isInstalled()));
  }, [app]);

  const [selectedService, setSelectedService] = useState<BaseService | null>(services[0] ?? null);
  const [loading, setLoading] = useState(false);

  const handleService = useCallback((newService: BaseService) => {
    const newWalletInfo = newService.getInfo();

    if (newService.isInstalled()) {
      setSelectedService(newService);
    } else {
      window.open(newWalletInfo.url, '_blank');
    }
  }, []);

  const handleConfirm = useCallback(async () => {
    if (selectedService == null) {
      return;
    }

    setLoading(true);

    try {
      await connect(selectedService, profile.chain.chainId);

      setConnectWalletModalVisible(false);
    } catch (error) {
      // Do nothing.
    }

    setLoading(false);
  }, [profile, connect, setConnectWalletModalVisible, selectedService]);

  return (
    <Modal
      visible={connectWalletModalVisible}
      onVisibleChange={setConnectWalletModalVisible}
      width={500}
      showCloseButton
    >
      <div className={styles.ConnectWalletModal}>
        <div className={styles.header}>{ t('wallet:chooseWallet') }</div>
        <div className={styles.options}>
          {
            services.map((item) => {
              const info = item.getInfo();

              return (
                <div
                  key={info.id}
                  onClick={() => handleService(item)}
                  className={classNames(styles.option, {
                    [styles.option_selected as string]: item === selectedService,
                  })}
                >
                  <img src={info.src} alt={info.name} className={styles.image} />
                  <div className={styles.name}>{ info.name }</div>
                </div>
              );
            })
          }
        </div>
        <div className={styles.buttons}>
          <ActionButton onClick={handleConfirm} loading={loading} className={styles.button}>
            { t('wallet:confirm') }
          </ActionButton>
        </div>
      </div>
    </Modal>
  );
}
