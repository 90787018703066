import type { SliderProps as SliderInternalProps } from '@mui/material';

import { Slider as SliderInternal, sliderClasses } from '@mui/base';
import { styled } from '@mui/material';
import { useCallback } from 'react';

const StyledSlider = styled(SliderInternal)`
  height: 0.25em;
  position: relative;
  display: block;
  color: var(--palette-primary-main);
  padding: 1em 0 1.5em;
  margin: 0 1em;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &.${sliderClasses.disabled} {
    cursor: not-allowed;
    filter: opacity(50%);
  }

  & .${sliderClasses.rail} {
    position: absolute;
    width: 100%;
    height: 0.25em;
    display: block;
    border-radius: 100em;
    background: currentcolor;
    filter: opacity(50%);
  }

  & .${sliderClasses.track} {
    height: 0.25em;
    position: absolute;
    display: block;
    border-radius: 100em;
    background: currentcolor;
  }

  & .${sliderClasses.thumb} {
    position: absolute;
    width: 1em;
    height: 1em;
    margin-left: -5px;
    margin-top: -5px;
    background: var(--palette-primary-main);
    border-radius: 50%;
    outline: 0;
  }

  & .${sliderClasses.markLabel} {
    color: var(--palette-normal-text);
    position: absolute;
    font-size: 0.8em;
    transform: translate(-50%, 1em);
  }
`;

export type SliderProps = {
  value?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  marks?: SliderInternalProps['marks'];
  className?: string;
  style?: React.CSSProperties;
};

export function Slider(props: SliderProps) {
  const {
    value = 0, onChange, disabled = false, min = 0, max = 1, step, marks, className, style,
  } = props;

  const handleChange = useCallback((event: Event, newValues: number | number[]) => {
    const newValue = Array.isArray(newValues) ? newValues[0] : newValues;

    onChange?.(newValue ?? 0);
  }, [onChange]);

  return (
    <div className={className} style={style}>
      <StyledSlider
        value={value}
        onChange={handleChange}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        marks={marks}
      />
    </div>
  );
}
