// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FaUasGdryzlWj2CRHyOw{overflow:auto}.FaUasGdryzlWj2CRHyOw .vxSaws2vN6pdqiyf8A5j{min-width:fit-content;display:flex;justify-content:flex-start}.FaUasGdryzlWj2CRHyOw .RijFamPEJ9p4IpcJJZKx{min-width:fit-content}.FaUasGdryzlWj2CRHyOw .RijFamPEJ9p4IpcJJZKx .GWyVFy6ZpfZHmvPmk53C{min-width:fit-content}.FaUasGdryzlWj2CRHyOw .RijFamPEJ9p4IpcJJZKx .GWyVFy6ZpfZHmvPmk53C ._4R3JdSK14L7ZXtPn4asJ{min-width:fit-content;display:flex}`, "",{"version":3,"sources":["webpack://./src/component/table/TableBase.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,4CACE,qBAAA,CACA,YAAA,CACA,0BAAA,CAEF,4CACE,qBAAA,CACA,kEACE,qBAAA,CACA,yFACE,qBAAA,CACA,YAAA","sourcesContent":[".base {\n  overflow: auto;\n  .headers {\n    min-width: fit-content;\n    display: flex;\n    justify-content: flex-start;\n  }\n  .rows {\n    min-width: fit-content;\n    .row {\n      min-width: fit-content;\n      .cells {\n        min-width: fit-content;\n        display: flex;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `FaUasGdryzlWj2CRHyOw`,
	"headers": `vxSaws2vN6pdqiyf8A5j`,
	"rows": `RijFamPEJ9p4IpcJJZKx`,
	"row": `GWyVFy6ZpfZHmvPmk53C`,
	"cells": `_4R3JdSK14L7ZXtPn4asJ`
};
export default ___CSS_LOADER_EXPORT___;
