import type { InitInfo } from './BaseService';
import type { WalletInfo } from './WalletInfo';

import { BrowserProvider } from 'ethers';
import { SafeAppProvider } from '@gnosis.pm/safe-apps-provider';
import SafeAppsSDK from '@gnosis.pm/safe-apps-sdk';
import { BaseService } from './BaseService';
import { WalletInfos } from './WalletInfo';

export class GnosisSafeService extends BaseService {
  public override getInfo(): WalletInfo {
    return WalletInfos.GnosisSafe;
  }

  public override isInstalled(): boolean {
    // Can only be used within the iframe in Gnosis Safe interface.
    return window.top !== window;
  }

  public override async requestUnlock(chainId: number): Promise<InitInfo | null> {
    const appsSdk = new SafeAppsSDK();
    const safe = await appsSdk.safe.getInfo();

    this.eipProvider = new SafeAppProvider(safe, appsSdk);
    this.web3Provider = new BrowserProvider(this.eipProvider, 'any');

    return {
      chainId: safe.chainId,
      account: safe.safeAddress,
    };
  }
}
