import { createInstance } from 'i18next';
import { resources } from './Resource';

const i18n = createInstance({
  fallbackLng: 'en',
  resources,
  interpolation: {
    escapeValue: false,
  },
  returnObjects: true,
});

i18n.init();

export {
  i18n,
};
