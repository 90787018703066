export class Counter {
  private readonly increment: number;

  private count: number;

  public constructor(start: number, increment: number) {
    this.increment = increment;
    this.count = start - increment;
  }

  public next(): number {
    this.count += this.increment;

    return this.count;
  }
}
