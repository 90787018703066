import type { EipProvider } from './EipProvider';
import type { WalletInfo } from './WalletInfo';

import { BaseWindowEthereumService } from './BaseWindowEthereumService';
import { WalletInfos } from './WalletInfo';

type WindowBitKeep = {
  bitkeep?: {
    ethereum?: EipProvider;
  };
};

export class BitKeepService extends BaseWindowEthereumService {
  public constructor() {
    super((window as WindowBitKeep).bitkeep?.ethereum);
  }

  public override getInfo(): WalletInfo {
    return WalletInfos.BitKeep;
  }

  public override isInstalled(): boolean {
    // Can be used in desktop browsers with BitKeep extension or the built-in browser of BitKeep
    // mobile app.
    return (window as WindowBitKeep).bitkeep?.ethereum != null;
  }
}
