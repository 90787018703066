import { useMemo } from 'react';
import { TranslucentPane } from 'component';

import styles from './CompletePane.module.scss';

export type CompletePaneProps = {
  step: string;
  title: string;
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function CompletePane(props: CompletePaneProps) {
  const { step, title, onClick, className, style, children } = props;

  const paneStyle = useMemo(() => {
    return {
      ...style,
      cursor: (onClick == null) ? undefined : 'pointer',
    };
  }, [onClick, style]);

  return (
    <TranslucentPane onClick={onClick} className={`${styles.CompletePane} ${className}`} style={paneStyle}>
      <div className={styles.left}>{ step }</div>
      <div className={styles.mid}>{ title }</div>
      <div className={styles.right}>{ children }</div>
    </TranslucentPane>
  );
}
