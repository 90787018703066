import { TranslucentPane } from './TranslucentPane';

import styles from './FormPane.module.scss';

export type FormPaneProps = {
  step: string;
  title: string;
  description: string;
  right?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function FormPane(props: FormPaneProps) {
  const { step, title, description, right, className = '', style, children } = props;

  return (
    <TranslucentPane className={`${styles.FormPane} ${className}`} style={style}>
      <div className={styles.top}>
        <div className={styles.left}>{ step }</div>
        <div className={styles.mid}>
          <div className={styles.title}>{ title }</div>
          <div className={styles.description}>{ description }</div>
        </div>
        <div className={styles.right}>{ right }</div>
      </div>
      { children }
    </TranslucentPane>
  );
}
