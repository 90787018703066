// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y_JgBv1ggCG8o7nzIRqN{display:flex;flex-flow:row wrap;align-items:center;gap:.5em}.y_JgBv1ggCG8o7nzIRqN.vKRNs_QIkYcF9AhomxOL{justify-content:center}.y_JgBv1ggCG8o7nzIRqN.vXRYrteijY7NXMI8wA23{justify-content:flex-start}.y_JgBv1ggCG8o7nzIRqN.cdtTMmFT6lD21g8y_TXA{justify-content:flex-end}.y_JgBv1ggCG8o7nzIRqN.XnCVysJChcGrcrtossxT>*{flex:1 1 0}.y_JgBv1ggCG8o7nzIRqN.dbcR2JTyc0AcCpOLz2Al>*{flex:0 0 100%}.y_JgBv1ggCG8o7nzIRqN .PSlONTk16uhWdBFRyZjZ{width:20em;background:linear-gradient(to right, #C24DFE, #6658DF);color:#fff;font-weight:bold;border-radius:100em;padding:.5em}.y_JgBv1ggCG8o7nzIRqN .PSlONTk16uhWdBFRyZjZ:disabled{filter:opacity(20%) grayscale(100%);cursor:not-allowed}`, "",{"version":3,"sources":["webpack://./src/component/transaction/ButtonSection.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,QAAA,CACA,2CACE,sBAAA,CAEF,2CACE,0BAAA,CAEF,2CACE,wBAAA,CAGA,6CACE,UAAA,CAIF,6CACE,aAAA,CAGJ,4CACE,UAAA,CACA,sDAAA,CACA,UAAA,CACA,gBAAA,CACA,mBAAA,CACA,YAAA,CACA,qDACE,mCAAA,CACA,kBAAA","sourcesContent":[".ButtonSection {\n  display: flex;\n  flex-flow: row wrap;\n  align-items: center;\n  gap: 0.5em;\n  &.mode_center {\n    justify-content: center;\n  }\n  &.mode_start {\n    justify-content: flex-start;\n  }\n  &.mode_end {\n    justify-content: flex-end;\n  }\n  &.mode_justified {\n    > * {\n      flex: 1 1 0;\n    }\n  }\n  &.mode_vertical {\n    > * {\n      flex: 0 0 100%;\n    }\n  }\n  .button {\n    width: 20em;\n    background: linear-gradient(to right, #C24DFE, #6658DF);\n    color: white;\n    font-weight: bold;\n    border-radius: 100em;\n    padding: 0.5em;\n    &:disabled {\n      filter: opacity(20%) grayscale(100%);\n      cursor: not-allowed;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ButtonSection": `y_JgBv1ggCG8o7nzIRqN`,
	"mode_center": `vKRNs_QIkYcF9AhomxOL`,
	"mode_start": `vXRYrteijY7NXMI8wA23`,
	"mode_end": `cdtTMmFT6lD21g8y_TXA`,
	"mode_justified": `XnCVysJChcGrcrtossxT`,
	"mode_vertical": `dbcR2JTyc0AcCpOLz2Al`,
	"button": `PSlONTk16uhWdBFRyZjZ`
};
export default ___CSS_LOADER_EXPORT___;
