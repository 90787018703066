import BinanceWalletLogo from '../../asset/wallet/BinanceWallet.png';
import BitKeepLogo from '../../asset/wallet/BitKeep.svg';
import Coin98Logo from '../../asset/wallet/Coin98.svg';
import CoinbaseWalletLogo from '../../asset/wallet/CoinbaseWallet.png';
import CoreLogo from '../../asset/wallet/Core.png';
import GnosisSafeLogo from '../../asset/wallet/GnosisSafe.png';
import ImTokenLogo from '../../asset/wallet/imToken.svg';
import MetaMaskLogo from '../../asset/wallet/MetaMask.svg';
import TrustWalletLogo from '../../asset/wallet/TrustWallet.svg';
import WalletConnectLogo from '../../asset/wallet/WalletConnect.svg';
import OkxWalletLogo from '../../asset/wallet/OkxWallet.png';

import { Chains } from '../../entity';

const allChainss = Object.values(Chains).map((chain) => chain.chainId);

export type WalletInfo = {
  readonly id: string;
  readonly name: string;
  readonly src: string;
  readonly url: string;
  readonly supportedChainIds: number[];
  readonly supportAddToken: boolean;
  readonly supportSwitchChain: boolean;
};

const MetaMask: WalletInfo = {
  id: 'MetaMask',
  name: 'MetaMask',
  src: MetaMaskLogo,
  url: 'https://metamask.io/',
  supportedChainIds: allChainss,
  supportAddToken: true,
  supportSwitchChain: true,
};

const WalletConnect: WalletInfo = {
  id: 'WalletConnect',
  name: 'WalletConnect',
  src: WalletConnectLogo,
  url: 'https://walletconnect.org/',
  supportedChainIds: allChainss,
  supportAddToken: true,
  supportSwitchChain: true,
};

const imToken: WalletInfo = {
  id: 'imToken',
  name: 'imToken',
  src: ImTokenLogo,
  url: 'https://token.im/',
  supportedChainIds: allChainss,
  supportAddToken: false,
  supportSwitchChain: false,
};

const Coin98: WalletInfo = {
  id: 'Coin98',
  name: 'Coin98',
  src: Coin98Logo,
  url: 'https://www.coin98.com/',
  supportedChainIds: allChainss,
  supportAddToken: false,
  supportSwitchChain: false,
};

const TrustWallet: WalletInfo = {
  id: 'TrustWallet',
  name: 'Trust Wallet',
  src: TrustWalletLogo,
  url: 'https://trustwallet.com/',
  supportedChainIds: [
    Chains.EthereumMainnet.chainId,
    Chains.Goerli.chainId,
    Chains.Sepolia.chainId,
    Chains.BscMainnet.chainId,
    Chains.BscTestnet.chainId,
    Chains.PolygonMainnet.chainId,
    Chains.PolygonMumbai.chainId,
    Chains.AvalancheMainnet.chainId,
    Chains.AvalancheFuji.chainId,
  ],
  supportAddToken: false,
  supportSwitchChain: false,
};

const CoinbaseWallet: WalletInfo = {
  id: 'CoinbaseWallet',
  name: 'Coinbase Wallet',
  src: CoinbaseWalletLogo,
  url: 'https://wallet.coinbase.com/',
  supportedChainIds: [
    Chains.EthereumMainnet.chainId,
    Chains.Goerli.chainId,
    Chains.Sepolia.chainId,
    Chains.PolygonMainnet.chainId,
    Chains.PolygonMumbai.chainId,
    Chains.AvalancheMainnet.chainId,
    Chains.AvalancheFuji.chainId,
  ],
  supportAddToken: false,
  supportSwitchChain: false,
};

const GnosisSafe: WalletInfo = {
  id: 'Safe',
  name: 'Safe',
  src: GnosisSafeLogo,
  url: 'https://gnosis-safe.io/',
  supportedChainIds: [
    Chains.EthereumMainnet.chainId,
    Chains.Goerli.chainId,
    Chains.Sepolia.chainId,
    Chains.BscMainnet.chainId,
    Chains.BscTestnet.chainId,
    Chains.PolygonMainnet.chainId,
    Chains.PolygonMumbai.chainId,
    Chains.AvalancheFuji.chainId,
    Chains.AvalancheMainnet.chainId,
  ],
  supportAddToken: false,
  supportSwitchChain: false,
};

const BinanceWallet: WalletInfo = {
  id: 'BinanceWallet',
  name: 'Binance Wallet',
  src: BinanceWalletLogo,
  url: 'https://www.bnbchain.world/en/binance-wallet',
  supportedChainIds: [
    Chains.EthereumMainnet.chainId,
    Chains.BscMainnet.chainId,
    Chains.BscTestnet.chainId,
  ],
  supportAddToken: false,
  supportSwitchChain: false,
};

const BitKeep: WalletInfo = {
  id: 'BitKeep',
  name: 'BitKeep',
  src: BitKeepLogo,
  url: 'https://bitkeep.com/download',
  supportedChainIds: allChainss,
  supportAddToken: false,
  supportSwitchChain: false,
};

const Core: WalletInfo = {
  id: 'Core',
  name: 'Core',
  src: CoreLogo,
  url: 'https://chrome.google.com/webstore/detail/core/agoakfejjabomempkjlepdflaleeobhb',
  supportedChainIds: [
    Chains.AvalancheMainnet.chainId,
    Chains.AvalancheFuji.chainId,
  ],
  supportAddToken: false,
  supportSwitchChain: false,
};

const OKXWallet: WalletInfo = {
  id: 'OKXWallet',
  name: 'OKX Wallet',
  src: OkxWalletLogo,
  url: 'https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge',
  supportedChainIds: allChainss,
  supportAddToken: true,
  supportSwitchChain: true,
};

export const WalletInfos = {
  MetaMask,
  WalletConnect,
  imToken,
  Coin98,
  TrustWallet,
  CoinbaseWallet,
  GnosisSafe,
  BinanceWallet,
  BitKeep,
  Core,
  OKXWallet,
} as const;
