import { ConnectWalletModal } from 'ccms-web-core';
import { Outlet } from 'react-router-dom';
import { ResponsiveNavBar } from 'component';
import { Footer } from './Footer';

import styles from './App.module.scss';

export function App() {
  return (
    <div className={styles.App}>
      <ResponsiveNavBar />
      <div className={styles.page}>
        <Outlet />
      </div>
      <Footer />
      <ConnectWalletModal />
    </div>
  );
}
