import type { PopperProps } from '@mui/base';
import type { FadeProps } from '@mui/material';

import { useCallback, useMemo, useRef, useState } from 'react';
import { Popper } from '@mui/base';
import { Fade } from '@mui/material';

import styles from './Popup.module.scss';

export type PopupProps = {
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  content?: React.ReactNode;
  disabled?: boolean;
  placement?: PopperProps['placement'];
  fadeTimeout?: FadeProps['timeout'];
  zIndex?: React.CSSProperties['zIndex'];
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function Popup(props: PopupProps) {
  const {
    visible,
    onVisibleChange,
    content,
    disabled = false,
    placement = 'bottom-start',
    fadeTimeout = 200,
    zIndex = 1000,
    className = '',
    style,
    children,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(visible ?? false);

  const handleMouseEnter = useCallback(() => {
    onVisibleChange?.(true);

    setOpen(true);
  }, [onVisibleChange]);

  const handleMouseLeave = useCallback(() => {
    onVisibleChange?.(false);

    setOpen(false);
  }, [onVisibleChange]);

  const showPopup = useMemo(() => {
    return (visible ?? open) && !disabled;
  }, [visible, open, disabled]);

  const slotProps = useMemo(() => {
    return {
      root: {
        style: {
          zIndex,
        },
      },
    };
  }, [zIndex]);

  return (
    <div
      ref={ref}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
      style={style}
    >
      { children }
      <Popper
        anchorEl={ref.current}
        placement={placement}
        open={showPopup}
        transition
        slotProps={slotProps}
      >
        {
          ({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={fadeTimeout}>
              <div className={styles.Popup}>
                <div className={styles.content}>{ content }</div>
              </div>
            </Fade>
          )
        }
      </Popper>
    </div>
  );
}
