import type { App } from '../app';
import type { Theme } from './ThemeProvider';
import type { MuiProviderProps } from './MuiProvider';

import { Provider } from 'react-redux';
import { AppProvider } from './AppProvider';
import { MuiProvider } from './MuiProvider';
import { ThemeProvider } from './ThemeProvider';

export type WebFullProviderProps = {
  app: App;
  themes: Theme[];
  cssVariableTarget: HTMLElement;
  muiThemeOption?: MuiProviderProps['themeOption'];
  children?: React.ReactNode;
};

export function WebFullProvider(props: WebFullProviderProps) {
  const { app, themes, cssVariableTarget, muiThemeOption, children } = props;

  return (
    <AppProvider app={app}>
      <Provider store={app.store}>
        <ThemeProvider themes={themes} cssVariableTarget={cssVariableTarget}>
          <MuiProvider themeOption={muiThemeOption}>
            { children }
          </MuiProvider>
        </ThemeProvider>
      </Provider>
    </AppProvider>
  );
}
