import { Fragment, useCallback, useMemo, useState } from 'react';
import { useInternalState } from '../../hook';
import { mergeClassNames } from '../../util';
import { LoadingSpin } from '../layout/LoadingSpin';
import { Pagination } from './Pagination';

import stylesBase from './TableBase.module.scss';

export type TableStyles = {
  base?: string;
  headers?: string;
  header?: string;
  rows?: string;
  row?: string;
  cells?: string;
  cell?: string;
  expansion?: string;
  pagination?: string;
};

export type TableRowOption = {
  isExpanded: boolean;
};

export type TableColumn<T> = {
  render?: (element: T, option: TableRowOption) => React.ReactNode;
  title?: React.ReactNode;
  width?: number;
  grow?: number;
  align?: string;
};

type TableHeaderProps<T> = {
  column: TableColumn<T>;
  styles: TableStyles;
};

function TableHeader<T>(props: TableHeaderProps<T>) {
  const { column, styles } = props;
  const { title, grow = 0, width, align } = column;

  const style = {
    width,
    flex: `${grow} ${grow} auto`,
    textAlign: align as React.CSSProperties['textAlign'],
  };

  return <div className={styles.header} style={style}>{ title }</div>;
}

type TableRowProps<T> = {
  row: T;
  columns: TableColumn<T>[];
  isExpanded: boolean;
  onClick: () => void;
  expand?: (row: T) => React.ReactNode;
  styles: TableStyles;
};

function TableRow<T>(props: TableRowProps<T>) {
  const { row, columns, isExpanded, onClick, expand, styles } = props;

  const rowOption = {
    isExpanded,
  };

  const cellsStyle = (expand == null) ? undefined : { cursor: 'pointer' };

  return (
    <div className={styles.row}>
      <div onClick={onClick} className={styles.cells} style={cellsStyle}>
        {
          columns.map((col, colIndex) => {
            const { render, grow = 0, width, align = 'left' } = col;

            const style = {
              width,
              flex: `${grow} ${grow} auto`,
              textAlign: align as React.CSSProperties['textAlign'],
            };

            return (
              <div key={colIndex} className={styles.cell} style={style}>{ render?.(row, rowOption) }</div>
            );
          })
        }
      </div>
      { isExpanded && <div className={styles.expansion}>{ expand?.(row) }</div> }
    </div>
  );
}

export type TableOption = {
  page: number;
  pageInitial: number;
  pageSize: number;
  pageSizeInitial: number;
  pageSizes: number[];
};

export type RenderPaginationProps = {
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageSizes: number[];
  pageCount: number;
};

export type TableProps<T> = {
  columns?: TableColumn<T>[];
  rows?: T[];
  count?: number;
  loading?: boolean;
  option?: Partial<TableOption>;
  onOptionChange?: (option: Partial<TableOption>) => void;
  rowId?: (row: T, index: number) => string;
  expand?: (row: T) => React.ReactNode;
  renderEmpty?: () => React.ReactNode;
  renderPagination?: (props: RenderPaginationProps) => React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

function rowIdDefault<T>(row: T, index: number): string {
  return index.toString();
}

function renderPaginationDefault(props: RenderPaginationProps) {
  const { page, setPage, pageSize, setPageSize, pageSizes, pageCount } = props;

  return (
    <Pagination
      page={page}
      onPageChange={setPage}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      pageSizes={pageSizes}
      pageCount={pageCount}
    />
  );
}

export type TableBaseProps<T> = TableProps<T> & {
  styles?: TableStyles;
};

export function TableBase<T>(props: TableBaseProps<T>) {
  const {
    columns = [],
    rows = [],
    count,
    loading = false,
    option = {},
    onOptionChange,
    rowId = rowIdDefault,
    expand,
    renderEmpty,
    renderPagination = renderPaginationDefault,
    className = '',
    style,
    styles: stylesCustom = {},
  } = props;

  const styles = mergeClassNames(stylesBase, stylesCustom) as TableStyles;

  const handlePageChange = useCallback((v: number) => {
    onOptionChange?.({ ...option, page: v });
  }, [option, onOptionChange]);

  const handlePageSizeChange = useCallback((v: number) => {
    onOptionChange?.({ ...option, pageSize: v });
  }, [option, onOptionChange]);

  // Manage page and pageSize internally if the parent does not control them.
  const [page, setPage] = useInternalState(option.page, option.pageInitial ?? DEFAULT_PAGE, handlePageChange);
  const [pageSize, setPageSize] = useInternalState(option.pageSize, option.pageSizeInitial ?? option.pageSizes?.[0] ?? DEFAULT_PAGE_SIZE, handlePageSizeChange);

  const [expandedRowIds, setExpandedRowIds] = useState(new Set<string>());

  const handleToggleRowExpansion = useCallback((id: string) => {
    if (expand == null) {
      return;
    }

    setExpandedRowIds((rowIds) => {
      const newRowIndexes = new Set(rowIds);

      if (rowIds.has(id)) {
        newRowIndexes.delete(id);
      } else {
        newRowIndexes.add(id);
      }

      return newRowIndexes;
    });
  }, [expand]);

  // If count is provided, the pagination will be handled by the parent, otherwise the pagination
  // will be handled internally.
  const { tableRows, rowCount } = useMemo(() => {
    if (count == null) {
      const offset = (page - 1) * pageSize;

      return {
        tableRows: rows.map((value, index) => ({ rowId: rowId(value, index), value }))
          .slice(offset, offset + pageSize),
        rowCount: rows.length,
      };
    }

    return {
      tableRows: rows.map((value, index) => ({ rowId: rowId(value, index), value })),
      rowCount: count,
    };
  }, [rows, count, rowId, page, pageSize]);

  const pageCount = useMemo(() => {
    return Math.ceil(rowCount / pageSize);
  }, [pageSize, rowCount]);

  return (
    <div className={`${styles.base} ${className}`} style={style}>
      <div className={styles.headers}>
        { columns.map((col, index) => <TableHeader key={index} column={col} styles={styles} />) }
      </div>
      <div className={styles.rows}>
        <LoadingSpin loading={loading}>
          { (rows.length <= 0 && !loading) && <Fragment key="loading">{ renderEmpty?.() }</Fragment> }
          {
            tableRows.map((row) => (
              <TableRow
                key={row.rowId}
                row={row.value}
                columns={columns}
                isExpanded={expandedRowIds.has(row.rowId)}
                onClick={() => handleToggleRowExpansion(row.rowId)}
                expand={expand}
                styles={styles}
              />
            ))
          }
        </LoadingSpin>
      </div>
      {
        (rowCount > 0) && (
          <div className={styles.pagination}>
            {
              renderPagination({
                page,
                setPage,
                pageSize,
                setPageSize,
                pageSizes: option.pageSizes ?? [],
                pageCount,
              })
            }
          </div>
        )
      }
    </div>
  );
}
