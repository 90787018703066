// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xDgHrGyFLYFjxOH3BG5p{color:inherit;text-decoration:none}.xDgHrGyFLYFjxOH3BG5p .bWrcjNj95OqziLud9mfJ{font-size:1em;vertical-align:middle;margin-left:.2em}`, "",{"version":3,"sources":["webpack://./src/component/layout/AdaptiveLink.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,oBAAA,CACA,4CACE,aAAA,CACA,qBAAA,CACA,gBAAA","sourcesContent":[".AdaptiveLink {\n  color: inherit;\n  text-decoration: none;\n  .icon {\n    font-size: 1em;\n    vertical-align: middle;\n    margin-left: 0.2em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdaptiveLink": `xDgHrGyFLYFjxOH3BG5p`,
	"icon": `bWrcjNj95OqziLud9mfJ`
};
export default ___CSS_LOADER_EXPORT___;
