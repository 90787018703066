import styles from './CircularProgress.module.scss';

export type CircularProgressProps = {
  size?: React.CSSProperties['width'];
};

export function CircularProgress(props: CircularProgressProps) {
  const { size = '1em' } = props;

  return (
    <svg className={styles.CircularProgress} style={{ width: size, height: size }} viewBox="0 0 50 50">
      <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5" strokeMiterlimit="10" />
    </svg>
  );
}
