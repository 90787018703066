export class ObservableSubject<T> {
  private state: T;
  private listener: (newState: T) => void;

  public constructor(state: T, listener: (newState: T) => void) {
    this.state = state;
    this.listener = listener;
  }

  public get(): T {
    return this.state;
  }

  public set(newState: T): void {
    this.setStateAndNotify(newState);
  }

  public update(updater: (currentState: T) => T): void {
    this.setStateAndNotify(updater(this.state));
  }

  private setStateAndNotify(newState: T): void {
    this.state = newState;

    this.listener(newState);
  }
}
