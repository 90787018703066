export type TokenGroup = {
  readonly symbol: string;
};

export const DEFAULT_TOKEN_GROUP: TokenGroup = {
  symbol: '',
};

export const TokenGroups = {
  WAMT: {
    symbol: 'WAMT',
  } as TokenGroup,
  INSUR: {
    symbol: 'INSUR',
  } as TokenGroup,
  ETH: {
    symbol: 'ETH',
  } as TokenGroup,
  BNB: {
    symbol: 'BNB',
  } as TokenGroup,
  MATIC: {
    symbol: 'MATIC',
  } as TokenGroup,
  AVAX: {
    symbol: 'AVAX',
  } as TokenGroup,
};
