import type { EipProvider } from './EipProvider';
import type { WalletInfo } from './WalletInfo';

import { BaseWindowEthereumService } from './BaseWindowEthereumService';
import { WalletInfos } from './WalletInfo';

type WindowBinanceWallet = {
  BinanceChain?: EipProvider;
};

export class BinanceWalletService extends BaseWindowEthereumService {
  public constructor() {
    super((window as unknown as WindowBinanceWallet).BinanceChain);
  }

  public override getInfo(): WalletInfo {
    return WalletInfos.BinanceWallet;
  }

  public override isInstalled(): boolean {
    // Can be used in desktop browsers with Binance Wallet extension.
    return (window as unknown as WindowBinanceWallet).BinanceChain != null;
  }
}
