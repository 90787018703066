import classNames from 'classnames';

export type ClassNameMap = {
  [key: string]: string | undefined;
};

export function mergeClassNames(...maps: ClassNameMap[]): ClassNameMap {
  const keys = new Set(maps.flatMap((item) => Object.keys(item)));

  return Array.from(keys).reduce<ClassNameMap>((previous, current) => {
    previous[current] = classNames(maps.map((item) => item[current]));

    return previous;
  }, {});
}
