import styles from './TranslucentPane.module.scss';

export type TranslucentPaneProps = {
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function TranslucentPane(props: TranslucentPaneProps) {
  const { onClick, className = '', style, children } = props;

  return (
    <div onClick={onClick} className={`${styles.TranslucentPane} ${className}`} style={style}>
      { children }
    </div>
  );
}
