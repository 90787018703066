import type { Profile, Token } from '../entity';

import { DateTime, Duration } from 'luxon';
import { DEFAULT_PROFILE, DEFAULT_TOKEN } from '../entity';
import { decodeAddress } from './Encode';
import { equalsIgnoreCase } from './StringUtil';

export class Transform {
  public static toBooleanOrNull(input: boolean | string | null | undefined): boolean | null {
    return (input == null) ? null : input === true;
  }

  public static toBoolean(input: boolean | string | null | undefined): boolean {
    return input === true;
  }

  public static toStringOrNull(input: string | number | null | undefined): string | null {
    return (input == null) ? null : input.toString();
  }

  public static toString(input: string | number | null | undefined): string {
    return this.toStringOrNull(input) ?? '';
  }

  public static toNumber(input: string | bigint | number | null | undefined): number {
    return Number(input ?? 0);
  }

  public static toDecodedAddress(input: string | null | undefined): string {
    return decodeAddress(this.toString(input));
  }

  public static toDurationFromSecondsOrNull(input: string | number | null | undefined): Duration | null {
    return (input == null) ? null : Duration.fromObject({ seconds: Transform.toNumber(input) });
  }

  public static toDurationFromSeconds(input: string | number | null | undefined): Duration {
    return Transform.toDurationFromSecondsOrNull(input) ?? Duration.fromMillis(0);
  }

  public static toTimeFromSecondsOrNull(input: string | number | null | undefined): DateTime | null {
    return (input == null) ? null : DateTime.fromSeconds(Transform.toNumber(input));
  }

  public static toTimeFromSeconds(input: string | number | null | undefined): DateTime {
    return Transform.toTimeFromSecondsOrNull(input) ?? DateTime.fromMillis(0);
  }

  public static toTimeFromMilliseconds(input: string | number | null | undefined): DateTime {
    return DateTime.fromMillis(Transform.toNumber(input));
  }

  public static toTimeFromIso(input: string | null | undefined): DateTime {
    return DateTime.fromISO(Transform.toString(input));
  }

  public static toTimeFromFormat(input: string | null | undefined, format: string): DateTime {
    return DateTime.fromFormat(Transform.toString(input), format);
  }

  public static toProfileFromUniChainId(profiles: Profile[], input: string | number | null | undefined): Profile {
    return profiles.find((profile) => profile.chain.uniChainId === this.toString(input)) ?? DEFAULT_PROFILE;
  }

  public static toToken(profile: Profile, input: string | null | undefined): Token {
    if (input == null) {
      return DEFAULT_TOKEN;
    }

    return profile.tokens.find((token) => equalsIgnoreCase(token.address, input)) ?? DEFAULT_TOKEN;
  }

  public static toTokens(profile: Profile, input: string[] | null | undefined): Token[] {
    return input?.map((item) => Transform.toToken(profile, item)) ?? [];
  }
}
