import type { Profile } from './Profile';

export class AppError extends Error {
  public readonly profile: Profile;

  public constructor(option: {
    message: string;
    profile: Profile;
    cause?: any;
  }) {
    super(option.message, { cause: option.cause });

    // Set the prototype explicitly so that the instanceof operator works as expected.
    Object.setPrototypeOf(this, new.target.prototype);

    this.profile = option.profile;
  }
}

export class UserRejectionError extends AppError {
}

export class ContractError extends AppError {
  public readonly code: string;

  public constructor(option: ConstructorParameters<typeof AppError>['0'] & {
    code: string;
  }) {
    super(option);

    this.code = option.code;
  }
}
