import classNames from 'classnames';
import { CircularProgress } from './CircularProgress';

import styles from './LoadingSpin.module.scss';

export type LoadingSpinProps = {
  loading?: boolean;
  size?: number;
  top?: number;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function LoadingSpin(props: LoadingSpinProps) {
  const { loading = false, size = 40, className = '', style, children } = props;

  const divClassName = classNames(styles.LoadingSpin, {
    [styles.LoadingSpin_loading as string]: loading,
  }, className);

  const divStyle = {
    minHeight: size,
    ...style,
  };

  return (
    <div className={divClassName} style={divStyle}>
      <div className={styles.backdrop}>{ children }</div>
      {
        loading && (
          <div className={styles.body}>
            <CircularProgress size={size} />
          </div>
        )
      }
    </div>
  );
}
