// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oQrsXYRxoFbYDHoO2NI0{width:100%;display:flex;justify-content:center}.oQrsXYRxoFbYDHoO2NI0 .vnomTUG8taDYYEPD8nRT{box-sizing:border-box;width:100%}`, "",{"version":3,"sources":["webpack://./src/component/layout/Container.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,sBAAA,CACA,4CACE,qBAAA,CACA,UAAA","sourcesContent":[".Container {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  .body {\n    box-sizing: border-box;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `oQrsXYRxoFbYDHoO2NI0`,
	"body": `vnomTUG8taDYYEPD8nRT`
};
export default ___CSS_LOADER_EXPORT___;
