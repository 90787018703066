export default {
  en: {
    wallet: 'Wallet',
    chain: 'Chain',
    disconnect: 'Disconnect',
    connectWallet: 'Connect Wallet',
    chooseWallet: 'Choose Wallet',
    symbolTokenAddress: '{{symbol}} Token Address',
    addTokenToWallet: 'Add {{token}} to {{wallet}}',
    notice: 'MetaMask Mobile currently does not support Avalanche via WalletConnect. Please use MetaMask browser extension on desktop instead.',
    switchTo: 'Switch to {{name}}',
    confirm: 'Confirm',
  },
};
