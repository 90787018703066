import { decodeAddress, encodeAddress } from '@polkadot/keyring';
import { hexToU8a, isHex } from '@polkadot/util';
import { PublicKey } from '@solana/web3.js';
import { bech32 } from 'bech32';
import { getAddress } from 'ethers';

const SRC_EMPTY = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

function isValidEvmAddress(address: string): boolean {
  try {
    getAddress(address);

    return true;
  } catch (error) {
    return false;
  }
}

function isValidSolanaAddress(address: string): boolean {
  try {
    return PublicKey.isOnCurve(address);
  } catch (error) {
    return false;
  }
}

// https://polkadot.js.org/docs/util-crypto/examples/validate-address
function isValidPolkadotAddress(address: string): boolean {
  try {
    encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address));

    return true;
  } catch (error) {
    return false;
  }
}

// https://docs.terra.money/docs/develop/terra-js/common-examples.html#validate-a-terra-address
function isValidTerraAddress(address: string): boolean {
  try {
    return bech32.decode(address).prefix === 'terra';
  } catch (error) {
    return false;
  }
}

export type ChainGroup = {
  readonly code: string;
  readonly name: string;
  readonly standard: string;
  readonly src: string;
  readonly isValidAddress: (address: string) => boolean;
};

export const DEFAULT_CHAIN_GROUP: ChainGroup = {
  code: '',
  name: '',
  standard: '',
  src: SRC_EMPTY,
  isValidAddress: () => false,
};

export const ChainGroups = {
  Ethereum: {
    code: 'Ethereum',
    name: 'Ethereum',
    standard: 'ERC20',
    src: require('../asset/chain/Ethereum.png'),
    isValidAddress: isValidEvmAddress,
  },
  BSC: {
    code: 'BSC',
    name: 'BSC',
    standard: 'BEP20',
    src: require('../asset/chain/BSC.png'),
    isValidAddress: isValidEvmAddress,
  },
  Polygon: {
    code: 'Polygon',
    name: 'Polygon',
    standard: 'ERC20',
    src: require('../asset/chain/Polygon.png'),
    isValidAddress: isValidEvmAddress,
  },
  Avalanche: {
    code: 'Avalanche',
    name: 'Avalanche',
    standard: 'ERC20',
    src: require('../asset/chain/Avalanche.png'),
    isValidAddress: isValidEvmAddress,
  },
  Arbitrum: {
    code: '',
    name: 'Arbitrum',
    standard: '',
    src: require('../asset/chain/Arbitrum.png'),
    isValidAddress: isValidEvmAddress,
  },
  Aurora: {
    code: '',
    name: 'Aurora',
    standard: '',
    src: require('../asset/chain/Aurora.png'),
    isValidAddress: isValidEvmAddress,
  },
  Bifrost: {
    code: '',
    name: 'Bifrost',
    standard: '',
    src: require('../asset/chain/Bifrost.png'),
    isValidAddress: isValidPolkadotAddress,
  },
  Boba: {
    code: '',
    name: 'Boba',
    standard: '',
    src: require('../asset/chain/Boba.png'),
    isValidAddress: isValidEvmAddress,
  },
  Celo: {
    code: '',
    name: 'Celo',
    standard: '',
    src: require('../asset/chain/Celo.png'),
    isValidAddress: isValidEvmAddress,
  },
  Cronos: {
    code: '',
    name: 'Cronos',
    standard: '',
    src: require('../asset/chain/Cronos.png'),
    isValidAddress: isValidEvmAddress,
  },
  Fantom: {
    code: '',
    name: 'Fantom',
    standard: '',
    src: require('../asset/chain/Fantom.png'),
    isValidAddress: isValidEvmAddress,
  },
  Fuse: {
    code: '',
    name: 'Fuse',
    standard: '',
    src: require('../asset/chain/Fuse.png'),
    isValidAddress: isValidEvmAddress,
  },
  Gnosis: {
    code: '',
    name: 'Gnosis',
    standard: '',
    src: require('../asset/chain/Gnosis.png'),
    isValidAddress: isValidEvmAddress,
  },
  Harmony: {
    code: '',
    name: 'Harmony',
    standard: '',
    src: require('../asset/chain/Harmony.png'),
    isValidAddress: isValidEvmAddress,
  },
  HECO: {
    code: '',
    name: 'HECO',
    standard: 'HRC20',
    src: require('../asset/chain/HECO.png'),
    isValidAddress: isValidEvmAddress,
  },
  ICON: {
    code: '',
    name: 'ICON',
    standard: '',
    src: require('../asset/chain/ICON.png'),
    isValidAddress: isValidEvmAddress,
  },
  Moonbeam: {
    code: '',
    name: 'Moonbeam',
    standard: 'ERC20',
    src: require('../asset/chain/Moonbeam.png'),
    isValidAddress: isValidEvmAddress,
  },
  Moonriver: {
    code: '',
    name: 'Moonriver',
    standard: '',
    src: require('../asset/chain/Moonriver.png'),
    isValidAddress: isValidEvmAddress,
  },
  Metis: {
    code: '',
    name: 'Metis',
    standard: '',
    src: require('../asset/chain/Metis.png'),
    isValidAddress: isValidEvmAddress,
  },
  OKC: {
    code: '',
    name: 'OKC',
    standard: '',
    src: require('../asset/chain/OKC.png'),
    isValidAddress: isValidEvmAddress,
  },
  Ontology: {
    code: '',
    name: 'Ontology',
    standard: '',
    src: require('../asset/chain/Ontology.png'),
    isValidAddress: isValidEvmAddress,
  },
  Optimism: {
    code: '',
    name: 'Optimism',
    standard: '',
    src: require('../asset/chain/Optimism.png'),
    isValidAddress: isValidEvmAddress,
  },
  Solana: {
    code: '',
    name: 'Solana',
    standard: '',
    src: require('../asset/chain/Solana.png'),
    isValidAddress: isValidSolanaAddress,
  },
  Terra: {
    code: '',
    name: 'Terra',
    standard: '',
    src: require('../asset/chain/Terra.png'),
    isValidAddress: isValidTerraAddress,
  },
  Thorchain: {
    code: '',
    name: 'Thorchain',
    standard: '',
    src: require('../asset/chain/Thorchain.png'),
    isValidAddress: isValidEvmAddress,
  },
  Velas: {
    code: '',
    name: 'Velas',
    standard: '',
    src: require('../asset/chain/Velas.png'),
    isValidAddress: isValidEvmAddress,
  },
} satisfies { [name: string]: ChainGroup } ;

export const chainGroups: ChainGroup[] = Object.values(ChainGroups);
