type SearchParam = {
  [key: string]: string | number;
};

export function getSearchParams(url: string): URLSearchParams {
  return new URL(url).searchParams;
}

export function createSearchParams(params: SearchParam): URLSearchParams {
  return Object.entries(params).reduce((previous, [key, value]) => {
    previous.set(key, value.toString());

    return previous;
  }, new URLSearchParams());
}

export function createUrl(path: string, params: SearchParam): string {
  const search = createSearchParams(params).toString();

  return `${path}?${search}`;
}
