import styles from './EllipsisText.module.scss';

export type EllipsisTextProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function EllipsisText(props: EllipsisTextProps) {
  const { className = '', style, children } = props;

  return (
    <div className={`${styles.EllipsisText} ${className}`} style={style}>{ children }</div>
  );
}
