import type { Profile } from '../entity';

import { HttpClient } from '../http';

export type IndexClientConfig = {
  endpoints: {
    url: string;
    apiKey: string;
  }[];
};

export class IndexClient {
  private readonly clients: HttpClient[];

  public constructor(config: IndexClientConfig) {
    this.clients = config.endpoints.map((endpoint) => {
      return new HttpClient({
        url: endpoint.url,
        headers: {
          'X-API-Key': endpoint.apiKey,
        },
      });
    });
  }

  public requestIndex(option: {
    profile: Profile;
    transactionHash: string;
    eventNames: string[];
  }): Promise<void> {
    const { profile, transactionHash, eventNames } = option;

    const body = {
      chainName: profile.chain.group.code,
      transactionHash,
      eventNames,
    };

    return Promise.any(this.clients.map((client) => client.post<never, typeof body>('/evmactiveindex', body)));
  }
}
