// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zUWZk7LMEPJRKR6_OoXs{padding-top:.5em}.zUWZk7LMEPJRKR6_OoXs .Mu3F8otwztQNmfak_AYa{background:var(--palette-normal-main);color:var(--palette-normal-text);border-radius:.5em;box-shadow:0 0 .5em 0 var(--palette-shadow)}`, "",{"version":3,"sources":["webpack://./src/component/layout/Popup.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,4CACE,qCAAA,CACA,gCAAA,CACA,kBAAA,CACA,2CAAA","sourcesContent":[".Popup {\n  padding-top: 0.5em;\n  .content {\n    background: var(--palette-normal-main);\n    color: var(--palette-normal-text);\n    border-radius: 0.5em;\n    box-shadow: 0 0 0.5em 0 var(--palette-shadow);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Popup": `zUWZk7LMEPJRKR6_OoXs`,
	"content": `Mu3F8otwztQNmfak_AYa`
};
export default ___CSS_LOADER_EXPORT___;
