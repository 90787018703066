import type { Profile, Token } from '../entity';

import { useCallback, useState } from 'react';
import { TokenAmount } from '../entity';
import { useApp } from './UseApp';

type BalanceEntry = {
  profile: Profile;
  owner: string;
  balance: TokenAmount;
};

export function useBalances() {
  const { app } = useApp();

  const [balances, setBalances] = useState<BalanceEntry[]>([]);

  const getBalance = useCallback((profile: Profile, owner: string, token: Token) => {
    const entry = balances.find((item) => item.profile === profile && item.owner === owner && item.balance.token === token);

    return entry?.balance ?? TokenAmount.zero(token);
  }, [balances]);

  const queryBalance = useCallback(async (profile: Profile, owner: string, token: Token) => {
    try {
      const reader = await app.blockchainReaders.getReader(profile);
      const balance = await reader.getBalance(token, owner);

      setBalances((state) => {
        return state.filter((item) => item.profile !== profile || item.owner !== owner || item.balance.token !== token)
          .concat({ profile, owner, balance });
      });
    } catch (e) {
      // Do nothing.
    }
  }, [app]);

  return {
    getBalance,
    queryBalance,
  };
}
