// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.qGjC3j0ZbqVa2BZD9v4F){font-family:inherit;white-space:pre-wrap;margin:0}`, "",{"version":3,"sources":["webpack://./src/component/html/Pre.module.scss"],"names":[],"mappings":"AACA,8BACE,mBAAA,CACA,oBAAA,CACA,QAAA","sourcesContent":["// Reduce CSS specificity using :where() pseudo-class.\n:where(.Pre) {\n  font-family: inherit;\n  white-space: pre-wrap;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Pre": `qGjC3j0ZbqVa2BZD9v4F`
};
export default ___CSS_LOADER_EXPORT___;
