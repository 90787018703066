import type { PayloadAction } from '@reduxjs/toolkit';
import type { Profile } from '../entity';
import type { createWalletSlice } from './WalletSlice';

import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PROFILE, getProfileByChainId } from '../entity';

export type UiProfileSliceState = {
  profile: Profile;
};

export function createUiProfileSlice(profiles: Profile[], walletSlice?: ReturnType<typeof createWalletSlice>) {
  const initialState: UiProfileSliceState = {
    profile: profiles[0] ?? DEFAULT_PROFILE,
  };

  return createSlice({
    name: 'uiProfile',
    initialState,
    reducers: {
      setUiProfile(state: UiProfileSliceState, action: PayloadAction<{ profile: Profile }>) {
        state.profile = action.payload.profile;
      },
    },
    extraReducers: walletSlice == null ? undefined : (builder) => {
      builder.addCase(walletSlice.actions.setWallet, (state, action) => {
        const { chainId } = action.payload;

        const profile = getProfileByChainId(profiles, chainId);

        if (profile != null) {
          state.profile = profile;
        }
      });
    },
  });
}

export function selectUiProfileSlice(rootState: { uiProfile: UiProfileSliceState }) {
  return rootState.uiProfile;
}
