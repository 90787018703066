import { Switch as SwitchInternal, switchClasses } from '@mui/base';
import { styled } from '@mui/material';
import { useCallback } from 'react';

const Root = styled('span')`
  width: 2.5em;
  height: 1.2em;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &.${switchClasses.disabled} {
    cursor: not-allowed;
    filter: opacity(50%);
  }

  & .${switchClasses.track} {
    display: block;
    position: absolute;
    inset: 0;
    background: var(--palette-grey-main);
    border-radius: 100em;
  }

  & .${switchClasses.thumb} {
    width: 1em;
    height: 1em;
    position: relative;
    top: 0.1em;
    left: 0.1em;
    display: block;
    background: var(--palette-normal-main);
    border-radius: 100em;
    transition: all 200ms ease;
  }

  & .${switchClasses.input} {
    position: absolute;
    inset: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
    cursor: inherit;
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 1.4em;
    }

    .${switchClasses.track} {
      background: var(--palette-primary-main);
    }
  }
`;

export type SwitchProps = {
  value?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export function Switch(props: SwitchProps) {
  const { value = false, onChange, disabled, className, style } = props;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  }, [onChange]);

  return (
    <SwitchInternal
      checked={value}
      onChange={handleChange}
      disabled={disabled}
      slots={{ root: Root }}
      className={className}
      style={style}
    />
  );
}
