import { ArrowDropDown } from '@mui/icons-material';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useElementSize } from '../../hook';
import { containsIgnoreCase } from '../../util';
import { Input } from '../html/Input';
import { Popup } from '../layout/Popup';

import styles from './Select.module.scss';

export type SelectOption<T> = {
  value: T;
  label?: string;
};

type SelectPopupProps<T> = {
  options: T[];
  onChange: (option: T) => void;
  renderOption: (option: T) => React.ReactNode;
  minWidth: string | number;
};

function SelectPopup<T>(props: SelectPopupProps<T>) {
  const { options, onChange, renderOption, minWidth } = props;

  return (
    <div className={styles.options} style={{ minWidth }}>
      {
        options.map((option, index) => (
          <div key={index} onClick={() => onChange(option)} className={styles.option}>
            { renderOption(option) }
          </div>
        ))
      }
    </div>
  );
}

function renderOptionDefault<T>(option: SelectOption<T>) {
  return option.label;
}

export type SelectProps<P, Q> = {
  value?: P;
  onChange?: (value: P) => void;
  options: Q[];
  disabled?: boolean;
  search?: boolean;
  placeholder?: string;
  renderOption?: (option: Q) => React.ReactNode;
  renderSelected?: (option: Q) => React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export function Select<P, Q extends SelectOption<P>>(props: SelectProps<P, Q>) {
  const {
    value,
    onChange,
    options = [],
    disabled = false,
    search = false,
    placeholder,
    renderSelected,
    renderOption = renderOptionDefault,
    className = '', style,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const { width } = useElementSize(ref);

  const [visible, setVisible] = useState(false);
  const [input, setInput] = useState('');

  const handleSelect = useCallback((newOption: Q) => {
    onChange?.(newOption.value);

    setVisible(false);
    setInput(newOption.label ?? '');
  }, [onChange]);

  const filteredOptions = useMemo(() => {
    if (search) {
      return options.filter((option) => containsIgnoreCase(option.label ?? '', input));
    }

    return options;
  }, [options, search, input]);

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === value);
  }, [value, options]);

  return (
    <Popup
      visible={visible}
      onVisibleChange={setVisible}
      disabled={disabled || filteredOptions.length <= 0}
      content={
        <SelectPopup
          options={filteredOptions}
          onChange={handleSelect}
          renderOption={renderOption}
          minWidth={width}
        />
      }
      className={`${styles.Select} ${className}`}
      style={style}
    >
      <div ref={ref}>
        {
          search ? (
            <Input
              value={input}
              onChange={setInput}
              disabled={disabled}
              placeholder={placeholder}
              className={styles.input}
            />
          ) : (
            <div className={styles.selected}>
              <div className={styles.body}>
                {
                  (selectedOption != null) && (
                    renderSelected?.(selectedOption) ?? renderOption(selectedOption)
                  )
                }
              </div>
              <ArrowDropDown />
            </div>
          )
        }
      </div>
    </Popup>
  );
}
