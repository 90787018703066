import ConfigDev from './ConfigDev';
import ConfigProd from './ConfigProd';

const configs = {
  [ConfigDev.env]: ConfigDev,
  [ConfigProd.env]: ConfigProd,
};

const config = configs[process.env.REACT_APP_BUILD ?? ConfigDev.env] ?? ConfigDev;

export default config;
