import type { EipProvider } from './EipProvider';
import type { WalletInfo } from './WalletInfo';

import { BaseWindowEthereumService } from './BaseWindowEthereumService';
import { WalletInfos } from './WalletInfo';

type WindowImToken = {
  ethereum?: EipProvider & {
    isImToken?: boolean;
  };
};

export class ImTokenService extends BaseWindowEthereumService {
  public override getInfo(): WalletInfo {
    return WalletInfos.imToken;
  }

  public override isInstalled(): boolean {
    // Can only be used in the built-in browser of imToken mobile app.
    return (window as WindowImToken).ethereum?.isImToken ?? false;
  }
}
