import type { ChainGroup } from './ChainGroup';

import { BaseBlockExplorer, EtherscanBlockExplorer } from './BlockExplorer';
import { ChainGroups, DEFAULT_CHAIN_GROUP } from './ChainGroup';

export type Chain = {
  readonly chainId: number;
  readonly uniChainId: string; // Used by API to identify a chain.
  readonly name: string;
  readonly group: ChainGroup;
  readonly symbol: string; // Native token symbol.
  readonly decimals: number; // Native token decimals.
  readonly blockTime: number; // The average block time in seconds.
  readonly rpcUrl: string | null;
  readonly blockExplorer: BaseBlockExplorer;
  readonly isMainnet: boolean;
};

export const DEFAULT_CHAIN: Chain = {
  chainId: -1,
  uniChainId: '',
  name: 'Unknown',
  group: DEFAULT_CHAIN_GROUP,
  symbol: '',
  decimals: 0,
  blockTime: 0,
  rpcUrl: null,
  blockExplorer: new BaseBlockExplorer(),
  isMainnet: false,
};

export const Chains = {
  EthereumMainnet: {
    chainId: 1,
    uniChainId: '12000',
    name: 'Ethereum',
    group: ChainGroups.Ethereum,
    symbol: 'ETH',
    decimals: 18,
    blockTime: 12,
    rpcUrl: null,
    blockExplorer: new EtherscanBlockExplorer('https://etherscan.io'),
    isMainnet: true,
  },
  Goerli: {
    chainId: 5,
    uniChainId: '',
    name: 'Goerli',
    group: ChainGroups.Ethereum,
    symbol: 'ETH',
    decimals: 18,
    blockTime: 12,
    rpcUrl: null,
    blockExplorer: new EtherscanBlockExplorer('https://goerli.etherscan.io'),
    isMainnet: false,
  },
  Sepolia: {
    chainId: 11155111,
    uniChainId: '2000',
    name: 'Sepolia',
    group: ChainGroups.Ethereum,
    symbol: 'ETH',
    decimals: 18,
    blockTime: 12,
    rpcUrl: null,
    blockExplorer: new EtherscanBlockExplorer('https://sepolia.etherscan.io'),
    isMainnet: false,
  },
  BscMainnet: {
    chainId: 56,
    uniChainId: '12003',
    name: 'BSC',
    group: ChainGroups.BSC,
    symbol: 'BNB',
    decimals: 18,
    blockTime: 3,
    rpcUrl: 'https://bsc-dataseed.binance.org',
    blockExplorer: new EtherscanBlockExplorer('https://bscscan.com'),
    isMainnet: true,
  },
  BscTestnet: {
    chainId: 97,
    uniChainId: '2003',
    name: 'BSC Testnet',
    group: ChainGroups.BSC,
    symbol: 'BNB',
    decimals: 18,
    blockTime: 3,
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    blockExplorer: new EtherscanBlockExplorer('https://testnet.bscscan.com'),
    isMainnet: false,
  },
  PolygonMainnet: {
    chainId: 137,
    uniChainId: '12001',
    name: 'Polygon',
    group: ChainGroups.Polygon,
    symbol: 'MATIC',
    decimals: 18,
    blockTime: 2,
    rpcUrl: 'https://polygon-rpc.com',
    blockExplorer: new EtherscanBlockExplorer('https://polygonscan.com'),
    isMainnet: true,
  },
  PolygonMumbai: {
    chainId: 80001,
    uniChainId: '2001',
    name: 'Polygon Mumbai',
    group: ChainGroups.Polygon,
    symbol: 'MATIC',
    decimals: 18,
    blockTime: 2,
    rpcUrl: 'https://rpc-mumbai.matic.today',
    blockExplorer: new EtherscanBlockExplorer('https://mumbai.polygonscan.com'),
    isMainnet: false,
  },
  AvalancheMainnet: {
    chainId: 43114,
    uniChainId: '12002',
    name: 'Avalanche',
    group: ChainGroups.Avalanche,
    symbol: 'AVAX',
    decimals: 18,
    blockTime: 2,
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    blockExplorer: new EtherscanBlockExplorer('https://avascan.info/blockchain/c'),
    isMainnet: true,
  },
  AvalancheFuji: {
    chainId: 43113,
    uniChainId: '2002',
    name: 'Avalanche FUJI',
    group: ChainGroups.Avalanche,
    symbol: 'AVAX',
    decimals: 18,
    blockTime: 2,
    rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc',
    blockExplorer: new EtherscanBlockExplorer('https://testnet.avascan.info/blockchain/c'),
    isMainnet: false,
  },
  ArbitrumMainnet: {
    chainId: 42161,
    uniChainId: '',
    name: 'Arbitrum',
    group: ChainGroups.Arbitrum,
    symbol: 'AETH',
    decimals: 18,
    blockTime: 0.3,
    rpcUrl: 'https://arb1.arbitrum.io/rpc',
    blockExplorer: new EtherscanBlockExplorer('https://arbiscan.io'),
    isMainnet: true,
  },
  AuroraMainnet: {
    chainId: 1313161554,
    uniChainId: '',
    name: 'Aurora',
    group: ChainGroups.Aurora,
    symbol: 'ETH',
    decimals: 18,
    blockTime: 1,
    rpcUrl: 'https://mainnet.aurora.dev',
    blockExplorer: new EtherscanBlockExplorer('https://aurorascan.dev'),
    isMainnet: true,
  },
  BobaMainnet: {
    chainId: 288,
    uniChainId: '',
    name: 'Boba',
    group: ChainGroups.Boba,
    symbol: 'ETH',
    decimals: 18,
    blockTime: 3600,
    rpcUrl: 'https://mainnet.boba.network',
    blockExplorer: new EtherscanBlockExplorer('https://bobascan.com'),
    isMainnet: true,
  },
  CeloMainnet: {
    chainId: 42220,
    uniChainId: '',
    name: 'Celo',
    group: ChainGroups.Celo,
    symbol: 'CELO',
    decimals: 18,
    blockTime: 5,
    rpcUrl: 'https://forno.celo.org',
    blockExplorer: new EtherscanBlockExplorer('https://explorer.celo.org'),
    isMainnet: true,
  },
  CronosMainnet: {
    chainId: 25,
    uniChainId: '',
    name: 'Cronos',
    group: ChainGroups.Cronos,
    symbol: 'CRO',
    decimals: 18,
    blockTime: 5.5,
    rpcUrl: 'https://evm-cronos.crypto.org',
    blockExplorer: new EtherscanBlockExplorer('https://cronos.crypto.org/explorer'),
    isMainnet: true,
  },
  FuseMainnet: {
    chainId: 122,
    uniChainId: '',
    name: 'Fuse',
    group: ChainGroups.Fuse,
    symbol: 'FUSE',
    decimals: 18,
    blockTime: 5,
    rpcUrl: 'https://rpc.fuse.io',
    blockExplorer: new EtherscanBlockExplorer('https://explorer.fuse.io'),
    isMainnet: true,
  },
  GnosisMainnet: {
    chainId: 100,
    uniChainId: '',
    name: 'Gnosis',
    group: ChainGroups.Gnosis,
    symbol: 'xDAI',
    decimals: 18,
    blockTime: 5,
    rpcUrl: 'https://rpc.gnosischain.com',
    blockExplorer: new EtherscanBlockExplorer('https://gnosisscan.io'),
    isMainnet: true,
  },
  FantomMainnet: {
    chainId: 250,
    uniChainId: '',
    name: 'Fantom',
    group: ChainGroups.Fantom,
    symbol: 'FTM',
    decimals: 18,
    blockTime: 1,
    rpcUrl: 'https://rpc.ankr.com/fantom',
    blockExplorer: new EtherscanBlockExplorer('https://ftmscan.com'),
    isMainnet: true,
  },
  MoonbeamMainnet: {
    chainId: 1284,
    uniChainId: '',
    name: 'Moonbeam',
    group: ChainGroups.Moonbeam,
    symbol: 'GLMR',
    decimals: 18,
    blockTime: 12,
    rpcUrl: 'https://rpc.api.moonbeam.network',
    blockExplorer: new EtherscanBlockExplorer('https://moonbeam.moonscan.io'),
    isMainnet: true,
  },
  MoonbeamTestnet: {
    chainId: 1287,
    uniChainId: '',
    name: 'Moonbase Alpha',
    group: ChainGroups.Moonbeam,
    symbol: 'DEV',
    decimals: 18,
    blockTime: 12,
    rpcUrl: 'https://rpc.api.moonbase.moonbeam.network',
    blockExplorer: new EtherscanBlockExplorer('https://moonbase.moonscan.io'),
    isMainnet: false,
  },
  MoonriverMainnet: {
    chainId: 1285,
    uniChainId: '',
    name: 'Moonriver',
    group: ChainGroups.Moonriver,
    symbol: 'MOVR',
    decimals: 18,
    blockTime: 12,
    rpcUrl: 'https://rpc.api.moonriver.moonbeam.network',
    blockExplorer: new EtherscanBlockExplorer('https://blockscout.moonriver.moonbeam.network'),
    isMainnet: true,
  },
  OkcMainnet: {
    chainId: 66,
    uniChainId: '',
    name: 'OKC',
    group: ChainGroups.OKC,
    symbol: 'OKT',
    decimals: 18,
    blockTime: 4,
    rpcUrl: 'https://exchainrpc.okex.org',
    blockExplorer: new EtherscanBlockExplorer('https://www.oklink.com/en/okc'),
    isMainnet: true,
  },
  OptimismMainnet: {
    chainId: 10,
    uniChainId: '',
    name: 'Optimism',
    group: ChainGroups.Optimism,
    symbol: 'ETH',
    decimals: 18,
    blockTime: 2,
    rpcUrl: 'https://mainnet.optimism.io',
    blockExplorer: new EtherscanBlockExplorer('https://optimistic.etherscan.io'),
    isMainnet: true,
  },
  VelasMainnet: {
    chainId: 106,
    uniChainId: '',
    name: 'Velas',
    group: ChainGroups.Velas,
    symbol: 'VLX',
    decimals: 18,
    blockTime: 0.4,
    rpcUrl: 'https://evmexplorer.velas.com/rpc',
    blockExplorer: new EtherscanBlockExplorer('https://evmexplorer.velas.com'),
    isMainnet: true,
  },
} satisfies { [name: string]: Chain };

export const allChains: Chain[] = Object.values(Chains);

export const mainnetChains: Chain[] = allChains.filter((chain) => chain.isMainnet);
export const testnetChains: Chain[] = allChains.filter((chain) => !chain.isMainnet);

export function getChain(chainId: string | number | null | undefined): Chain | undefined {
  return allChains.find((chain) => chain.chainId === Number(chainId));
}

export function getChainOrDefault(chainId: number | null | undefined): Chain {
  return getChain(chainId) ?? DEFAULT_CHAIN;
}
