import { useCallback } from 'react';

import styles from './TextArea.module.scss';

export type TextAreaProps = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  onChange?: (value: string, event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export function TextArea(props: TextAreaProps) {
  const { onChange, className } = props;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event.target.value, event);
  }, [onChange]);

  return (
    <textarea
      {...props}
      onChange={handleChange}
      className={`${styles.TextArea} ${className}`}
    />
  );
}
