import type { TableProps } from 'ccms-web-core';

import { TableBase } from 'ccms-web-core';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './StripeTable.module.scss';

export type StripeTableProps<T> = TableProps<T> & {
  noData?: React.ReactNode;
};

export function StripeTable<T>(props: StripeTableProps<T>) {
  const { noData } = props;

  const { t } = useTranslation(['common']);

  const renderEmpty = useCallback(() => {
    return <div className={styles.empty}>{ noData ?? t('common:noData') }</div>;
  }, [noData, t]);

  return (
    <TableBase
      {...props}
      renderEmpty={renderEmpty}
      styles={styles}
    />
  );
}
