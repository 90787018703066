import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, styled } from '@mui/material';
import { useCallback } from 'react';

import styles from './Modal.module.scss';

const StyledDialog = styled(Dialog)`
  & .MuiBackdrop-root {
    background: var(--palette-backdrop);
  }
  & .MuiDialog-paper {
    background: none;
    box-shadow: none;
    margin: 0;
  }
`;

const sxContent = {
  padding: 0,
};

export type ModalProps = {
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  width?: number;
  showCloseButton?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function Modal(props: ModalProps) {
  const {
    visible = false, onVisibleChange, width, showCloseButton = false, className = '', style,
    children,
  } = props;

  const handleClose = useCallback((event: React.MouseEvent) => {
    event.stopPropagation(); // Avoid triggering click events on the parent.

    onVisibleChange?.(false);
  }, [onVisibleChange]);

  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation(); // Avoid triggering click events on the parent.
  }, []);

  const sxDialog = {
    '& .MuiDialog-paper': {
      maxWidth: (width == null) ? 'none' : `${width}px`,
    },
  };

  return (
    <StyledDialog open={visible} onClose={handleClose} scroll="body" fullWidth sx={sxDialog}>
      <DialogContent sx={sxContent} onClick={handleClick}>
        <div className={`${styles.Modal} ${className}`} style={style}>
          { showCloseButton && <Close onClick={handleClose} className={styles.close} /> }
          { children }
        </div>
      </DialogContent>
    </StyledDialog>
  );
}
