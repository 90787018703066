export class Logger {
  private readonly logs: string[];

  public constructor() {
    this.logs = [];
  }

  public getLogs(): string[] {
    return this.logs;
  }

  public log(...args: any[]): void {
    // eslint-disable-next-line no-console
    console.log(...args);

    const line = args
      .map((item) => {
        if (item === undefined) {
          return 'undefined';
        }

        if (item === null) {
          return 'null';
        }

        // Capture error stack.
        if (typeof item.stack === 'string') {
          return item.stack;
        }

        if (typeof item.toString === 'function') {
          return item.toString() as string;
        }

        return JSON.stringify(item);
      })
      .join(' ');

    this.logs.push(line);
  }
}
