// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CGNgY8g3oIr6wkRL2LaK{position:relative;background:var(--palette-normal-main);color:var(--palette-normal-text);border-radius:1.5em;overflow:hidden}.CGNgY8g3oIr6wkRL2LaK .ofE8nlkue3m6wpS1tIyk{position:absolute;top:.5em;right:.5em;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/component/layout/Modal.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,qCAAA,CACA,gCAAA,CACA,mBAAA,CACA,eAAA,CACA,4CACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".Modal {\n  position: relative;\n  background: var(--palette-normal-main);\n  color: var(--palette-normal-text);\n  border-radius: 1.5em;\n  overflow: hidden;\n  .close {\n    position: absolute;\n    top: 0.5em;\n    right: 0.5em;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Modal": `CGNgY8g3oIr6wkRL2LaK`,
	"close": `ofE8nlkue3m6wpS1tIyk`
};
export default ___CSS_LOADER_EXPORT___;
