// Create a compare function that can be used in Array.prototype.sort().
// @param evaluateFunc takes an array item as parameter and returns true if it should be placed at
//        the front, otherwise false.
export function createCompareFunc<T>(evaluateFunc: (item: T) => boolean): (a: T, b: T) => number {
  return (a: T, b: T) => {
    const priorityA = evaluateFunc(a);
    const priorityB = evaluateFunc(b);

    if (priorityA === priorityB) {
      return 0;
    }

    return (priorityA && !priorityB) ? -1 : 1;
  };
}

export function unique<K, V>(array: V[], keyFunc: (element: V) => K): V[] {
  const map = new Map<K, V>();

  for (const element of array) {
    map.set(keyFunc(element), element);
  }

  return Array.from(map.values());
}

export function keyBy<T>(array: T[], keyFunc: (element: T) => string): { [key: string]: T } {
  return array.reduce<{ [key: string]: T }>((previous, current) => {
    previous[keyFunc(current)] = current;

    return previous;
  }, {});
}

// Same as the above keyBy but with number as the keys. Typescript does not support restricting
// generic type at compile time.
export function keyByNumber<T>(array: T[], keyFunc: (element: T) => number): { [key: number]: T } {
  return array.reduce<{ [key: number]: T }>((previous, current) => {
    previous[keyFunc(current)] = current;

    return previous;
  }, {});
}

export async function asyncReduceToObject<P, Q>(
  array: P[],
  keyValueFunc: (element: P) => Promise<[string | number, Q]>,
): Promise<{ [key: string | number]: Q }> {
  const results = await Promise.all(array.map(keyValueFunc));

  return results.reduce<{ [key: string | number]: Q }>((previous, [key, value]) => {
    previous[key] = value;

    return previous;
  }, {});
}

export function notNullish<T>(value: T | null | undefined): value is T {
  return value != null;
}
