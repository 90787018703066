import { Select } from './Select';

import styles from './IconSelect.module.scss';

export type IconSelectOption<T> = {
  value: T;
  title: string;
  src: string;
};

function renderOption<T>(option: IconSelectOption<T>) {
  return (
    <div className={styles.option}>
      <img src={option.src} alt="" className={styles.image} />
      <span>{ option.title }</span>
    </div>
  );
}

export type IconSelectProps<T> = {
  value: T;
  onChange?: (value: T) => void;
  options: IconSelectOption<T>[];
  disabled?: boolean;
  renderSelected?: (option: IconSelectOption<T>) => React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export function IconSelect<T>(props: IconSelectProps<T>) {
  const { value, onChange, options, disabled, renderSelected, className, style } = props;

  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      renderOption={renderOption}
      renderSelected={renderSelected}
      disabled={disabled}
      className={className}
      style={style}
    />
  );
}
