import type { Profile } from '../../entity';
import type { BlockchainReaders } from '../BlockchainReaders';
import type { BaseService } from './BaseService';

import { BinanceWalletService } from './BinanceWalletService';
import { BitKeepService } from './BitKeepService';
import { Coin98Service } from './Coin98Service';
import { CoinbaseWalletService } from './CoinbaseWalletService';
import { CoreService } from './CoreService';
import { GnosisSafeService } from './GnosisSafeService';
import { ImTokenService } from './ImTokenService';
import { MetaMaskService } from './MetaMaskService';
import { OkxService } from './OkxService';
import { TrustWalletService } from './TrustWalletService';
import { WalletConnectService } from './WalletConnectService';

export class WalletManager {
  public readonly profiles: Profile[];
  public readonly services: BaseService[];

  public constructor(profiles: Profile[], blockchainReaders: BlockchainReaders, walletConnectProjectId: string) {
    this.profiles = profiles;
    this.services = [
      new MetaMaskService(),
      new ImTokenService(),
      new Coin98Service(),
      new TrustWalletService(),
      new WalletConnectService(profiles, blockchainReaders, walletConnectProjectId),
      new CoinbaseWalletService(profiles, blockchainReaders),
      new GnosisSafeService(),
      new BinanceWalletService(),
      new BitKeepService(),
      new CoreService(),
      new OkxService(),
    ];
  }

  public getWalletServiceById(id: string | null | undefined): BaseService | undefined {
    return this.services.find((item) => item.getInfo().id === id);
  }
}
