// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EHXrYty_l2zDy2UOliXQ .XYv7FvF5DzDlP2A5ySf3{margin-top:1em}`, "",{"version":3,"sources":["webpack://./src/component/transaction/MessageSection.module.scss"],"names":[],"mappings":"AACE,4CACE,cAAA","sourcesContent":[".MessageSection {\n  .message {\n    margin-top: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MessageSection": `EHXrYty_l2zDy2UOliXQ`,
	"message": `XYv7FvF5DzDlP2A5ySf3`
};
export default ___CSS_LOADER_EXPORT___;
