export default {
  en: {
    statusTooltip: {
      text: 'You can redeem the tokens after the transaction is confirmed. The <0>execution latency</0> depends on the blockchain used.',
      embed: ['https://docs.metarisk.com/metarisk-bridge-documentation/supported-network/supported-network'],
    },
    messagingFeeTooltip: {
      text: 'Collected by validators. <0>View Details</0>',
      embed: ['https://docs.metarisk.com/metarisk-bridge-documentation/about-fees/fees'],
    },
  },
};
