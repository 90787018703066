// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BNfbF0Dd7UgipngFqYYq{display:inline-flex;align-items:flex-start;gap:.5em;position:relative;cursor:pointer}.BNfbF0Dd7UgipngFqYYq input{position:absolute;width:0;height:0}.BNfbF0Dd7UgipngFqYYq .WH54OplrnoImLc0GNqd8{width:1em;height:1em;border:1px solid var(--palette-primary-main);border-radius:2px}.BNfbF0Dd7UgipngFqYYq .WH54OplrnoImLc0GNqd8::after{content:"";display:none;position:absolute;top:.15em;left:.35em;width:.2em;height:.5em;border:solid var(--palette-primary-text);border-width:0 3px 3px 0;transform:rotate(45deg)}.BNfbF0Dd7UgipngFqYYq input:checked~.WH54OplrnoImLc0GNqd8{background:var(--palette-primary-main)}.BNfbF0Dd7UgipngFqYYq input:checked~.WH54OplrnoImLc0GNqd8::after{display:block}`, "",{"version":3,"sources":["webpack://./src/component/input/Checkbox.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,sBAAA,CACA,QAAA,CACA,iBAAA,CACA,cAAA,CACA,4BACE,iBAAA,CACA,OAAA,CACA,QAAA,CAEF,4CACE,SAAA,CACA,UAAA,CACA,4CAAA,CACA,iBAAA,CACA,mDACE,UAAA,CACA,YAAA,CACA,iBAAA,CACA,SAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CACA,wCAAA,CACA,wBAAA,CACA,uBAAA,CAGJ,0DACE,sCAAA,CAEF,iEACE,aAAA","sourcesContent":[".Checkbox {\n  display: inline-flex;\n  align-items: flex-start;\n  gap: 0.5em;\n  position: relative;\n  cursor: pointer;\n  input {\n    position: absolute;\n    width: 0;\n    height: 0;\n  }\n  .checkmark {\n    width: 1em;\n    height: 1em;\n    border: 1px solid var(--palette-primary-main);\n    border-radius: 2px;\n    &::after {\n      content: \"\";\n      display: none;\n      position: absolute;\n      top: 0.15em;\n      left: 0.35em;\n      width: 0.2em;\n      height: 0.5em;\n      border: solid var(--palette-primary-text);\n      border-width: 0 3px 3px 0;\n      transform: rotate(45deg);\n    }\n  }\n  input:checked ~ .checkmark {\n    background: var(--palette-primary-main);\n  }\n  input:checked ~ .checkmark::after {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Checkbox": `BNfbF0Dd7UgipngFqYYq`,
	"checkmark": `WH54OplrnoImLc0GNqd8`
};
export default ___CSS_LOADER_EXPORT___;
