import { useMemo } from 'react';

import styles from './UnorderedList.module.scss';

export type UnorderedListProps = {
  margin?: React.CSSProperties['margin'];
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode | React.ReactNode[];
};

export function UnorderedList(props: UnorderedListProps) {
  const { margin = 0, className = '', style, children = [] } = props;

  const items: React.ReactNode[] = useMemo(() => {
    return Array.isArray(children) ? children : [children];
  }, [children]);

  const ulStyle = useMemo(() => {
    return {
      ...style,
      ['--unordered-list-margin' as any]: margin,
    };
  }, [margin, style]);

  return (
    <ul className={`${styles.UnorderedList} ${className}`} style={ulStyle}>
      {
        items.map((item, index) => (
          <li key={index} className={styles.item}>{ item }</li>
        ))
      }
    </ul>
  );
}
