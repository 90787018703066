import type { Profile } from '../entity';
import type { Logger } from '../log';

import { Chooser } from '../util/Chooser';
import { BlockchainReader } from './BlockchainReader';

type BlockchainRouters = {
  [chainId: number]: Chooser<BlockchainReader>;
};

function initRouters(profiles: Profile[], logger: Logger): BlockchainRouters {
  const routers: BlockchainRouters = {};

  for (const profile of profiles) {
    const providers = profile.rpc.urls.map((url) => new BlockchainReader(logger, profile, url));

    routers[profile.chain.chainId] = new Chooser(providers, (provider) => provider.getCurrentBlock());
  }

  return routers;
}

export class BlockchainReaders {
  private readonly routers: BlockchainRouters;

  public constructor(profiles: Profile[], logger: Logger) {
    this.routers = initRouters(profiles, logger);
  }

  public getReader(profile: Profile): Promise<BlockchainReader> {
    const router = this.routers[profile.chain.chainId];

    if (router == null) {
      throw new Error(`Router for chainId ${profile.chain.chainId} not found.`);
    }

    return router.get();
  }
}
