import type { Profile, Token } from '../entity';

import { useCallback, useEffect, useMemo } from 'react';
import { TokenAmount } from '../entity';
import { useBalances } from './UseBalances';

export type UseBalanceReturn = [
  TokenAmount,
  () => Promise<void>,
];

export function useBalance(
  profile: Profile | null | undefined,
  owner: string | null | undefined,
  token: Token | null | undefined,
): UseBalanceReturn {
  const { getBalance, queryBalance } = useBalances();

  const balance = useMemo(() => {
    return (profile == null || owner == null || owner === '' || token == null)
      ? TokenAmount.zero(token)
      : getBalance(profile, owner, token);
  }, [profile, owner, token, getBalance]);

  const queryTokenBalance = useCallback(() => {
    if (profile == null || owner == null || owner === '' || token == null) {
      return Promise.resolve();
    }

    return queryBalance(profile, owner, token);
  }, [profile, owner, token, queryBalance]);

  useEffect(() => {
    queryTokenBalance();
  }, [queryTokenBalance]);

  return [balance, queryTokenBalance];
}
