import Chain from './common/Chain';
import Error from './common/Error';
import Transaction from './common/Transaction';
import Wallet from './common/Wallet';

export const resources = {
  en: {
    chain: Chain.en,
    error: Error.en,
    transaction: Transaction.en,
    wallet: Wallet.en,
  },
};
