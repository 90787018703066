import type { PayloadAction } from '@reduxjs/toolkit';
import type { WalletInfo } from '../blockchain';

import { createSlice } from '@reduxjs/toolkit';

type SetWalletActionPayload = {
  chainId: number | null | undefined;
  account: string | null | undefined;
  walletInfo: WalletInfo | null | undefined;
};

export type WalletSliceState = {
  connectWalletModalVisible: boolean;
  walletInfo: WalletInfo | null | undefined;
  chainId: number | null | undefined;
  account: string | null | undefined;
};

export function createWalletSlice() {
  const initialState: WalletSliceState = {
    connectWalletModalVisible: false,
    walletInfo: null,
    chainId: null,
    account: null,
  };

  return createSlice({
    name: 'wallet',
    initialState,
    reducers: {
      setConnetWalletModalVisible(state, action: PayloadAction<boolean>) {
        state.connectWalletModalVisible = action.payload;
      },
      setWallet(state, action: PayloadAction<SetWalletActionPayload>) {
        state.walletInfo = action.payload.walletInfo;
        state.chainId = action.payload.chainId;
        state.account = action.payload.account;
      },
    },
  });
}

export function selectWalletSlice(state: { wallet: WalletSliceState }) {
  return state.wallet;
}
