// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.FwXk1R93dtUsUt30X20X){min-width:0;color:inherit;font-size:inherit;background:none;outline:none;border:none}:where(.FwXk1R93dtUsUt30X20X)::-webkit-outer-spin-button,:where(.FwXk1R93dtUsUt30X20X)::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}:where(.FwXk1R93dtUsUt30X20X)[type=number]{-moz-appearance:textfield}`, "",{"version":3,"sources":["webpack://./src/component/html/Input.module.scss"],"names":[],"mappings":"AACA,8BACE,WAAA,CACA,aAAA,CACA,iBAAA,CACA,eAAA,CACA,YAAA,CACA,WAAA,CAGA,kHAEE,uBAAA,CACA,QAAA,CAIF,2CAEE,yBAAA","sourcesContent":["// Reduce CSS specificity using :where() pseudo-class.\n:where(.Input) {\n  min-width: 0;\n  color: inherit;\n  font-size: inherit;\n  background: none;\n  outline: none;\n  border: none;\n\n  // Hide the arrow keys that comes default with input=number in WebKit browsers.\n  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {\n    /* stylelint-disable-next-line property-no-vendor-prefix */\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  // Hide the arrow keys that comes default with input=number in Firefox.\n  &[type=\"number\"] {\n    /* stylelint-disable-next-line property-no-vendor-prefix */\n    -moz-appearance: textfield;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Input": `FwXk1R93dtUsUt30X20X`
};
export default ___CSS_LOADER_EXPORT___;
