import type { EipProvider } from './EipProvider';
import type { WalletInfo } from './WalletInfo';

import { BaseWindowEthereumService } from './BaseWindowEthereumService';
import { WalletInfos } from './WalletInfo';

type WindowTrustWallet = {
  ethereum?: EipProvider & {
    isTrust?: boolean;
  };
};

export class TrustWalletService extends BaseWindowEthereumService {
  public override getInfo(): WalletInfo {
    return WalletInfos.TrustWallet;
  }

  public override isInstalled(): boolean {
    // Can be used in the built-in browser of Trust Wallet mobile app.
    return (window as WindowTrustWallet).ethereum?.isTrust ?? false;
  }
}
