// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bZy3Y_OzOYcno2mzOVob .O60a_pxFNoenVWocQ7tK{width:100%}.bZy3Y_OzOYcno2mzOVob .IGSgUcpATsvXoe1AguPQ{display:flex;justify-content:flex-start;align-items:center;cursor:pointer}.bZy3Y_OzOYcno2mzOVob .IGSgUcpATsvXoe1AguPQ .ONviK2tBkmnG6kq14hAg{flex:1 1 auto;display:flex;align-items:center}.DYXBpeZPQrHxGM1RGnP3{max-height:25em;padding:.25em 0;overflow:auto}.DYXBpeZPQrHxGM1RGnP3 .GuU2uha13T_xhvVgE1x6{padding:.25em .5em;cursor:pointer}.DYXBpeZPQrHxGM1RGnP3 .GuU2uha13T_xhvVgE1x6:hover{background:var(--palette-normal-highlight)}`, "",{"version":3,"sources":["webpack://./src/component/input/Select.module.scss"],"names":[],"mappings":"AACE,4CACE,UAAA,CAEF,4CACE,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,cAAA,CACA,kEACE,aAAA,CACA,YAAA,CACA,kBAAA,CAKN,sBACE,eAAA,CACA,eAAA,CACA,aAAA,CACA,4CACE,kBAAA,CACA,cAAA,CACA,kDACE,0CAAA","sourcesContent":[".Select {\n  .input {\n    width: 100%;\n  }\n  .selected {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    cursor: pointer;\n    .body {\n      flex: 1 1 auto;\n      display: flex;\n      align-items: center;\n    }\n  }\n}\n\n.options {\n  max-height: 25em;\n  padding: 0.25em 0;\n  overflow: auto;\n  .option {\n    padding: 0.25em 0.5em;\n    cursor: pointer;\n    &:hover {\n      background: var(--palette-normal-highlight);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Select": `bZy3Y_OzOYcno2mzOVob`,
	"input": `O60a_pxFNoenVWocQ7tK`,
	"selected": `IGSgUcpATsvXoe1AguPQ`,
	"body": `ONviK2tBkmnG6kq14hAg`,
	"options": `DYXBpeZPQrHxGM1RGnP3`,
	"option": `GuU2uha13T_xhvVgE1x6`
};
export default ___CSS_LOADER_EXPORT___;
