import { App } from 'ccms-web-core';
import config from 'config';
import { initI18n } from 'i18n/i18n';

function initApp() {
  const app = new App(config.app);

  app.init();

  return app;
}

export function init() {
  const i18n = initI18n();
  const app = initApp();

  return {
    i18n,
    app,
  };
}
