import type { Profile } from '../entity';
import type { CacheStore } from './CacheStore';

export const Key = {
  Theme: 'Theme',
  ViewMode: 'ViewMode',
  ChainId: 'ChainId',
  WalletServiceId: 'WalletServiceId',
} as const;

export class AppCache {
  public readonly profiles: Profile[];
  private readonly cacheLocal: CacheStore;
  private readonly cacheSession: CacheStore;

  public constructor(profiles: Profile[], cacheLocal: CacheStore, cacheSession: CacheStore) {
    this.profiles = profiles;
    this.cacheLocal = cacheLocal;
    this.cacheSession = cacheSession;
  }

  public getUserSettings() {
    return {
      theme: this.cacheLocal.getItem(Key.Theme),
      viewMode: this.cacheLocal.getItem(Key.ViewMode),
    };
  }

  public updateUserSettings(option: {
    theme?: string;
    viewMode?: string;
  }) {
    this.cacheLocal.setItemIfNotNull(Key.Theme, option.theme);
    this.cacheLocal.setItemIfNotNull(Key.ViewMode, option.viewMode);
  }

  public getConnectionInfo() {
    const chainId = this.cacheSession.getItem(Key.ChainId);

    return {
      chainId: (chainId == null) ? null : Number(chainId),
      serviceId: this.cacheSession.getItem(Key.WalletServiceId),
    };
  }

  public setConnectionInfo(chainId: number | null | undefined, serviceId: string | null | undefined) {
    if (chainId == null) {
      this.cacheSession.removeItem(Key.ChainId);
    } else {
      this.cacheSession.setItem(Key.ChainId, chainId.toString());
    }

    if (serviceId == null) {
      this.cacheSession.removeItem(Key.WalletServiceId);
    } else {
      this.cacheSession.setItem(Key.WalletServiceId, serviceId);
    }
  }
}
