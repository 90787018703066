export class CacheStore {
  private readonly storage: Storage;

  public constructor(storage: Storage) {
    this.storage = storage;
  }

  public clear(): void {
    this.storage.clear();
  }

  public getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  public setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public setItemIfNotNull(key: string, value: string | null | undefined): void {
    if (value != null) {
      this.storage.setItem(key, value);
    }
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }
}
