import type { Profile } from '../entity';
import type { Logger } from '../log';
import type * as types from './BackendClientType';

import { HttpClient } from '../http';

export type BackendClientConfig = {
  url: string;
  apiKey: string;
};

export class BackendClient {
  private readonly profiles: Profile[];
  private readonly logger: Logger;
  private readonly client: HttpClient;

  public constructor(profiles: Profile[], logger: Logger, config: BackendClientConfig) {
    this.profiles = profiles;
    this.logger = logger;

    this.client = new HttpClient({
      url: config.url,
      headers: {
        'X-API-Key': config.apiKey,
      },
    });
  }

  public async getMessageStatus(option: {
    profile: Profile;
    transactionHash: string;
  }) {
    const { profile, transactionHash } = option;

    try {
      const body = {
        uniChainId: profile.chain.uniChainId,
        transactionHash,
      };

      const { message } = await this.client.post<types.GetMessageStatusData, typeof body>('/GetMessageStatus', body);

      return {
        message,
      };
    } catch (e) {
      this.logger.log(`Failed to get message status for transaction ${profile.chain.name}-${transactionHash}.`, e);

      throw e;
    }
  }

  public async getMessageSignature(option: {
    profile: Profile;
    transactionHash: string;
  }) {
    const { profile, transactionHash } = option;

    try {
      const body = {
        uniChainId: profile.chain.uniChainId,
        transactionHash,
      };

      const { data } = await this.client.post<types.GetMessageSignatureData, typeof body>('/GetMessageSignature', body);

      return {
        message: data.message,
        signatures: data.signatures,
      };
    } catch (e) {
      this.logger.log(`Failed to get message signatures for transaction ${profile.chain.name}-${transactionHash}.`, e);

      throw e;
    }
  }
}
