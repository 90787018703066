import type { Operation, Token } from '../entity';
import type { EthereumManager } from './EthereumManager';

import { TokenAmount } from '../entity';

export class BlockchainOperator {
  private readonly ethereumManager: EthereumManager;

  public constructor(manager: EthereumManager) {
    this.ethereumManager = manager;
  }

  public async getAllowance(getContract: Operation['getContract'], token: Token) {
    const contractManager = await this.ethereumManager.getContractManagerOrThrow();

    const contract = getContract(contractManager);
    const tokenContract = contractManager.getTokenContract(token);

    const address = await contract.getAddress();
    const owner = await this.ethereumManager.getAccount();
    const value = await tokenContract.allowance(owner, address);

    return TokenAmount.fromBigInt(token, value);
  }

  public async approve(operation: Operation, allowance: bigint) {
    if (operation.token == null) {
      throw new Error('token is null');
    }

    const contractManager = await this.ethereumManager.getContractManagerOrThrow();

    const contract = operation.getContract(contractManager);
    const tokenContract = contractManager.getTokenContract(operation.token);

    const address = await contract.getAddress();
    const response = await tokenContract.approve(address, allowance);
    const receipt = await response.wait();

    return { response, receipt };
  }

  public async sendTransaction(operation: Operation) {
    const contractManager = await this.ethereumManager.getContractManagerOrThrow();

    const account = await this.ethereumManager.getAccount();
    const tx = await operation.populateTx(contractManager, account);
    const signer = await this.ethereumManager.getSignerOrThrow();

    return signer.sendTransaction(tx);
  }

  public waitTransaction(hash: string) {
    return this.ethereumManager.getProviderOrThrow().waitForTransaction(hash);
  }

  public async signMessage(message: string) {
    const signer = await this.ethereumManager.getSignerOrThrow();

    return signer.signMessage(message);
  }
}
