import type { BrowserProvider, Signer } from 'ethers';
import type { AppCache } from '../cache';
import type { Logger } from '../log';
import type { BaseService, InitInfo, WalletInfo, WalletManager } from './wallet';

import { getProfileByChainId, ObservableSubject } from '../entity';
import { WalletInfos } from './wallet';
import { ContractManager } from './ContractManager';

type EthereumManagerState = {
  chainId: number | null;
  account: string | null;
  service: BaseService | null;
};

export type EthereumManagerConfig = {
  logger: Logger;
  cache: AppCache;
  walletManager: WalletManager;
  notify: (
    chainId: number | null | undefined,
    account: string | null | undefined,
    walletInfo: WalletInfo | null | undefined,
  ) => void;
};

export class EthereumManager {
  private logger: Logger;
  private cache: AppCache;
  private walletManager: WalletManager;
  private state: ObservableSubject<EthereumManagerState>;

  public constructor(config: EthereumManagerConfig) {
    this.logger = config.logger;
    this.cache = config.cache;
    this.walletManager = config.walletManager;

    this.state = new ObservableSubject<EthereumManagerState>({
      chainId: null,
      account: null,
      service: null,
    }, (newState) => {
      const { chainId, account, service } = newState;

      // Update cache.
      this.cache.setConnectionInfo(chainId, service?.getInfo().id);

      // Notify listener.
      config.notify(chainId, account, service?.getInfo());
    });
  }

  public async connectEagerly() {
    if (window.top !== window) {
      // Connect to Gnosis Safe if this app is in an iframe.
      const service = this.walletManager.getWalletServiceById(WalletInfos.GnosisSafe.id);

      if (service != null) {
        return this.requestConnect(service, 0);
      }
    }

    const cache = this.cache.getConnectionInfo();

    if (cache.serviceId != null && cache.chainId != null) {
      // Connect to wallet if it was previously unlocked.
      const service = this.walletManager.getWalletServiceById(cache.serviceId);

      if (service != null) {
        return this.requestConnect(service, cache.chainId);
      }
    }

    return Promise.resolve();
  }

  private async init(initInfo?: InitInfo | null): Promise<EthereumManagerState> {
    const chainId = initInfo?.chainId ?? await this.getChainId();
    const account = initInfo?.account ?? await this.getAccount();

    this.state.update((current) => ({ ...current, chainId, account }));

    return this.state.get();
  }

  public async requestConnect(service: BaseService, chainId: number): Promise<EthereumManagerState> {
    try {
      // Do not update the chain ID because the actual connected chain may be different.
      this.state.update((current) => ({ ...current, service }));

      const initInfo = await service.requestUnlock(chainId);

      service.onChainChanged(() => this.init());
      service.onAccountsChanged(() => this.init());

      return this.init(initInfo);
    } catch (error) {
      this.logger.log(`Failed to connect chain ${chainId} using ${service.getInfo().name}.`, error);

      throw error;
    }
  }

  public async requestDisconnect(): Promise<void> {
    const { service } = this.state.get();

    if (service == null) {
      return;
    }

    try {
      service.removeAllListeners();

      await service.requestLock();

      this.state.set({ chainId: null, account: null, service: null });
    } catch (error) {
      this.logger.log(`Failed to disconnect using ${service.getInfo().name}.`, error);

      throw error;
    }
  }

  public getServiceOrThrow(): BaseService {
    const service = this.state.get().service;

    if (service == null) {
      throw new Error('service is null.');
    }

    return service;
  }

  public getChainId(): Promise<number> {
    return this.getServiceOrThrow().getChainId();
  }

  public async getAccount(): Promise<string> {
    const signer = await this.getSignerOrThrow();

    return signer.getAddress();
  }

  public getProviderOrThrow(): BrowserProvider {
    const provider = this.getServiceOrThrow().getWeb3Provider();

    if (provider == null) {
      throw new Error('provider is null.');
    }

    return provider;
  }

  public getSignerOrThrow(): Promise<Signer> {
    return this.getProviderOrThrow().getSigner();
  }

  public async getContractManagerOrThrow(): Promise<ContractManager> {
    const profile = getProfileByChainId(this.walletManager.profiles, this.state.get().chainId);

    if (profile == null) {
      throw new Error('contractManager is null.');
    }

    const signer = await this.getSignerOrThrow();

    return new ContractManager(signer, profile.address);
  }
}
