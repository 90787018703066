import type { Profile, QueryOption, Transfer } from '../entity';

import { useCallback } from 'react';
import { useQueryRows } from './UseQueryRows';

export function useTransfers() {
  const { app, row, rows, count, loading, reset, queryRows } = useQueryRows<Transfer>();

  const queryTransfers = useCallback((option: QueryOption, message?: string) => {
    return queryRows(() => app.realmClient.queryTransfers(option), message ?? 'Failed to query transfers.');
  }, [app, queryRows]);

  const queryTransfer = useCallback((senderProfile: Profile, senderTransactionHash: string) => {
    return queryTransfers({
      filters: {
        items: [{
          key: 'sender_uni_chain_id',
          value: senderProfile.chain.uniChainId,
        }, {
          key: 'sender_transaction_hash',
          value: senderTransactionHash,
        }],
      },
      sortKey: 'sender_epoch',
      sortOrder: 'DESC',
    }, `Failed to query the transfers of ${senderProfile.chain.name}-${senderTransactionHash}.`);
  }, [queryTransfers]);

  const queryTransfersByWallet = useCallback((wallet: string | null | undefined) => {
    reset();

    if (wallet == null) {
      return Promise.resolve(null);
    }

    return queryTransfers({
      filters: {
        or: [{
          items: [{
            key: 'sender_wallet',
            value: wallet,
          }],
        }, {
          items: [{
            key: 'receiver_wallet',
            value: wallet,
          }],
        }],
      },
      sortKey: 'sender_epoch',
      sortOrder: 'DESC',
    }, `Failed to query the transfers of ${wallet}.`);
  }, [reset, queryTransfers]);

  return {
    transfer: row,
    transfers: rows,
    transfersCount: count,
    transfersLoading: loading,
    queryTransfers,
    queryTransfer,
    queryTransfersByWallet,
  };
}
