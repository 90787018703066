import type { ButtonProps } from 'ccms-web-core';

import classNames from 'classnames';
import { Button } from 'ccms-web-core';

import styles from './CapsuleButton.module.scss';

export function CapsuleButton(props: ButtonProps) {
  const { className } = props;

  return <Button {...props} className={classNames(styles.CapsuleButton, className)} />;
}
