import type { FilterGroup, Operator, QueryOption, SortOrder } from '../entity';

import { gql } from 'graphql-request';
import { Counter } from '../util';

type QueryVariable = {
  name: string;
  type: string;
  value: string | number | boolean;
};

type QueryFragment = {
  node: string;
  variables: QueryVariable[];
};

type Variables = {
  [key: string]: string | number | boolean;
};

const operators = {
  EQUAL: '',
  NOT_EQUAL: '_ne',
  LT: '_lt',
  LTE: '_lte',
  GT: '_gt',
  GTE: '_gte',
  IN: '_in',
  NOT_IN: '_nin',
};

function getOperator(operator: Operator | null | undefined): string {
  return operators[operator ?? 'EQUAL'];
}

function getVariableType(value: string | number | boolean): string {
  switch (typeof value) {
    case 'number':
      return 'Int';

    case 'boolean':
      return 'Boolean';

    default:
      return 'String';
  }
}

function createFragmentQueryParts(items: FilterGroup[], counter: Counter): QueryFragment {
  if (items.length <= 0) {
    return {
      node: '',
      variables: [],
    };
  }

  const parts = items.map((item) => createFragmentQueryPart(item, counter));

  return {
    node: `[${parts.map((part) => part.node).join(', ')}]`,
    variables: parts.flatMap((part) => part.variables),
  };
}

function createFragmentQueryPart(filter: FilterGroup, counter: Counter): QueryFragment {
  const variables = (filter.items ?? []).map((item) => {
    return {
      name: `var${counter.next()}`,
      type: getVariableType(item.value),
      value: item.value,
      field: `${item.key}${getOperator(item.operator)}`,
    };
  });

  const partAnd = createFragmentQueryParts(filter.and ?? [], counter);
  const partOr = createFragmentQueryParts(filter.or ?? [], counter);

  const lines = [
    variables.map((variable) => `${variable.field}: $${variable.name}`).join(', '),
    (partAnd.node === '') ? '' : `AND: ${partAnd.node}`,
    (partOr.node === '') ? '' : `OR: ${partOr.node}`,
  ].filter((line) => line !== '').join(', ');

  return {
    node: `{ ${lines} }`,
    variables: [...variables, ...partAnd.variables, ...partOr.variables],
  };
}

function createFragmentQuery(filter: FilterGroup | null | undefined): QueryFragment {
  if (filter == null) {
    return {
      node: '',
      variables: [],
    };
  }

  const part = createFragmentQueryPart(filter, new Counter(0, 1));

  return {
    node: `query: ${part.node}`,
    variables: part.variables,
  };
}

function createFragmentSort(sortKey: string | null | undefined, sortOrder: SortOrder | null | undefined): QueryFragment {
  const order = (sortOrder === 'DESC') ? 'DESC' : 'ASC';

  return {
    node: (sortKey == null) ? '' : `sortBy: ${sortKey.toUpperCase()}_${order}`,
    variables: [],
  };
}

function createFragmentLimit(limit: number | null | undefined): QueryFragment {
  return {
    node: (limit == null) ? '' : `limit: ${limit}`,
    variables: [],
  };
}

function collectFragments(option: QueryOption): QueryFragment {
  const fragmentQuery = createFragmentQuery(option.filters);
  const fragmentLimit = createFragmentLimit(option.limit);
  const fragmentSort = createFragmentSort(option.sortKey, option.sortOrder);

  const nodes = [fragmentQuery.node, fragmentLimit.node, fragmentSort.node].filter((item) => item !== '');

  return {
    node: (nodes.length <= 0) ? '' : `(${nodes.join(', ')})`,
    variables: fragmentQuery.variables.concat(fragmentLimit.variables).concat(fragmentSort.variables),
  };
}

function createNodeField(fields: string[]): string {
  return fields.join(' ');
}

function createNodeVariable(variables: QueryVariable[]): string {
  if (variables.length <= 0) {
    return '';
  }

  return `(${variables.map((item) => `$${item.name}: ${item.type}!`).join(', ')})`;
}

function createVariables(variables: QueryVariable[]): Variables {
  return variables.reduce<Variables>((previous, current) => {
    return { ...previous, [current.name]: current.value };
  }, {});
}

/**
 * Construct a GraphQL query for MongoDB Atlas.
 *
 * Query:
 * ```graphql
 * query ($var1: String!, $var2: Int!) {
 *   entities (
 *     query: {
 *       age_gte: $var1,
 *       OR: [{
 *         name: $var2
 *       }, {
 *         name: $var3
 *       }]
 *     },
 *     sortBy: AGE_DESC,
 *     limit: 10
 *   ) {
 *     name
 *     age
 *   }
 * }
 * ```
 *
 * Variables:
 * ```json
 * {
 *   "var1": 18,
 *   "var2": "Alice",
 *   "var3": "Bob"
 * }
 * ```
 */
export function buildQuery(entityName: string, fields: string[], option: QueryOption) {
  const collection = collectFragments(option);
  const nodeVariable = createNodeVariable(collection.variables);
  const nodeField = createNodeField(fields);

  return {
    query: gql`
      query ${nodeVariable} {
        ${entityName} ${collection.node} {
          ${nodeField}
        }
      }
    `,
    variables: createVariables(collection.variables),
  };
}
