import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { useCallback } from 'react';

import styles from './Radio.module.scss';

export type RadioProps = {
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function Radio(props: RadioProps) {
  const { value = false, onChange, disabled = false, className = '', style, children } = props;

  const handleClick = useCallback(() => {
    if (!disabled) {
      onChange?.(!value);
    }
  }, [value, onChange, disabled]);

  return (
    <div onClick={handleClick} className={`${styles.Radio} ${className}`} style={style}>
      {
        value
          ? <RadioButtonChecked className={styles.icon} />
          : <RadioButtonUnchecked className={styles.icon} />
      }
      <div>{ children }</div>
    </div>
  );
}
