import type { UseTransactionReturn } from '../../hook';

import { ErrorMessage } from './ErrorMessage';
import { WarningMessage } from './WarningMessage';

import styles from './MessageSection.module.scss';

export type MessageSectionProps = {
  tx: UseTransactionReturn;
  className?: string;
  style?: React.CSSProperties;
};

export function MessageSection(props: MessageSectionProps) {
  const { tx, className = '', style } = props;

  return (
    <div className={`${styles.MessageSection} ${className}`} style={style}>
      <WarningMessage className={styles.message}>{ tx.warning }</WarningMessage>
      <ErrorMessage error={tx.error} className={styles.message} />
    </div>
  );
}
