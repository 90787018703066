import Common from './common/Common';
import Bridge from './common/Bridge';

export const resources = {
  en: {
    bridge: Bridge.en,
    common: Common.en,
  },
  zh: {},
};
