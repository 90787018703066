import type { AppConfig } from './AppConfig';

import { Chains, Profile, TokenCreator, extractAddress } from 'ccms-web-core';
import avalanche from './address/prod-avalanche.json';
import bsc from './address/prod-bsc.json';
import eth from './address/prod-eth.json';
import polygon from './address/prod-polygon.json';

const tcEth = TokenCreator.createForEth(eth);
const tcBsc = TokenCreator.createForBsc(bsc);
const tcPolygon = TokenCreator.createForPolygon(polygon);
const tcAvalanche = TokenCreator.createForAvalanche(avalanche);

const infura = {
  projectId: '3e3bfbadccc9443d93e776661ac25ef0',
};

const config: AppConfig = {
  env: 'prod',
  app: {
    backend: {
      url: 'https://bridge.metarisk.com/api',
      apiKey: 'MetaRiskGIjeC29VvR5dG3WsDqkHFSSRnErlxR8FP2iaRuh4XuodFpHdF4rFlVSq',
    },
    realm: {
      url: 'https://bridge.metarisk.com/gql',
      apiKey: 'MetaRiskGIjeC29VvR5dG3WsDqkHFSSRnErlxR8FP2iaRuh4XuodFpHdF4rFlVSq',
    },
    index: {
      endpoints: [{
        url: 'https://bridge.metarisk.com/activeIndex',
        apiKey: 'MetaRiskGIjeC29VvR5dG3WsDqkHFSSRnErlxR8FP2iaRuh4XuodFpHdF4rFlVSq',
      }],
    },
    profiles: [
      new Profile({
        chain: Chains.EthereumMainnet,
        rpc: {
          urls: [
            `https://mainnet.infura.io/v3/${infura.projectId}`,
            'https://rpc.ankr.com/eth',
          ],
        },
        address: extractAddress(eth),
        nativeToken: tcEth.nativeToken,
        tokensBridge: [tcEth.insur],
        tokens: tcEth.tokens,
      }),
      new Profile({
        chain: Chains.BscMainnet,
        rpc: {
          urls: [
            'https://bsc-dataseed.binance.org',
            'https://bsc-dataseed1.defibit.io',
            'https://rpc.ankr.com/bsc',
          ],
        },
        address: extractAddress(bsc),
        nativeToken: tcBsc.nativeToken,
        tokensBridge: [tcBsc.insur],
        tokens: tcBsc.tokens,
      }),
      new Profile({
        chain: Chains.PolygonMainnet,
        rpc: {
          urls: [
            `https://polygon-mainnet.infura.io/v3/${infura.projectId}`,
            'https://rpc.ankr.com/polygon',
            'https://polygon-rpc.com',
          ],
        },
        address: extractAddress(polygon),
        nativeToken: tcPolygon.nativeToken,
        tokensBridge: [tcPolygon.insur],
        tokens: tcPolygon.tokens,
      }),
      new Profile({
        chain: Chains.AvalancheMainnet,
        rpc: {
          urls: [
            'https://avalanche-mainnet.infura.io/v3/3e3bfbadccc9443d93e776661ac25ef0',
          ],
        },
        address: extractAddress(avalanche),
        nativeToken: tcAvalanche.nativeToken,
        tokensBridge: [tcAvalanche.insur],
        tokens: tcAvalanche.tokens,
      }),
    ],
  },
};

export default config;
