import type { QueryOption } from '../entity';

import { request } from 'graphql-request';
import { buildQuery } from './QueryBuilder';

type Variables = {
  [key: string]: string | number | boolean;
};

export type GraphqlClientConfig = {
  url: string;
  apiKey: string;
};

export class GraphqlClient {
  private readonly url: string;
  private readonly apiKey: string;

  public constructor(config: GraphqlClientConfig) {
    this.url = config.url;
    this.apiKey = config.apiKey;
  }

  public query<T>(query: string, variables: Variables): Promise<T> {
    return request<T>(this.url, query, variables, {
      'X-API-Key': this.apiKey,
    });
  }

  public async queryList<T>(entityName: string, fields: string[], option: QueryOption) {
    const { query, variables } = buildQuery(entityName, fields, option);

    const result = await this.query<{ [key: string]: T[] }>(query, variables);

    const rows = result[entityName] ?? [];

    return {
      rows,
      count: rows.length,
    };
  }
}
