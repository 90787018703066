import type { TFunction } from 'i18next';

import { ContractError, UserRejectionError } from '../entity';

export type TranslateErrorResult = string | {
  text: string;
  embed: string[];
};

export function translateError(t: TFunction, error: any) {
  if (error instanceof UserRejectionError) {
    return t('error:userRejection');
  }

  if (error instanceof ContractError) {
    return t(`error:code.${error.code}`);
  }

  return t('error:default');
}
