// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YskIRrSfy5aoyXUCpjjN{display:inline-flex;align-items:center;gap:.2em}.YskIRrSfy5aoyXUCpjjN ._Vow4pu0g13Iu5zrs2zG{color:var(--palette-primary-main);font-size:1em}`, "",{"version":3,"sources":["webpack://./src/component/layout/Tooltip.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,kBAAA,CACA,QAAA,CACA,4CACE,iCAAA,CACA,aAAA","sourcesContent":[".Tooltip {\n  display: inline-flex;\n  align-items: center;\n  gap: 0.2em;\n  .icon {\n    color: var(--palette-primary-main);\n    font-size: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tooltip": `YskIRrSfy5aoyXUCpjjN`,
	"icon": `_Vow4pu0g13Iu5zrs2zG`
};
export default ___CSS_LOADER_EXPORT___;
