/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Interface, type ContractRunner } from "ethers";
import type {
  TokenBridgePier,
  TokenBridgePierInterface,
} from "../TokenBridgePier";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "AmountIsZero",
    type: "error",
  },
  {
    inputs: [],
    name: "LowBalance",
    type: "error",
  },
  {
    inputs: [],
    name: "ReceiverChainIDMismatch",
    type: "error",
  },
  {
    inputs: [],
    name: "ReceiverMismatch",
    type: "error",
  },
  {
    inputs: [],
    name: "SenderChainIDMismatch",
    type: "error",
  },
  {
    inputs: [],
    name: "SenderMismatch",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferLimit",
    type: "error",
  },
  {
    inputs: [],
    name: "UnsupportedChain",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint8",
        name: "version",
        type: "uint8",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "senderChainID",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "senderWallet",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "senderToken",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "senderAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "receiverChainID",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "receiverWallet",
            type: "bytes",
          },
        ],
        indexed: false,
        internalType: "struct TokenBridgePier.TokenTransfer",
        name: "tokenTransfer",
        type: "tuple",
      },
      {
        indexed: false,
        internalType: "address",
        name: "receiverToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "receiverTokenAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "feeOwner",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "feeRelayer",
        type: "uint256",
      },
    ],
    name: "TokenReceived",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "senderChainID",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "senderWallet",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "senderToken",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "senderAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "receiverChainID",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "receiverWallet",
            type: "bytes",
          },
        ],
        indexed: false,
        internalType: "struct TokenBridgePier.TokenTransfer",
        name: "tokenTransfer",
        type: "tuple",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "messagingFee",
        type: "uint256",
      },
    ],
    name: "TokenSent",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "ccmsChainID",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "tokenBridgePier",
        type: "bytes32",
      },
    ],
    name: "enableChain",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_transferLimit",
        type: "uint256",
      },
    ],
    name: "enableToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "senderChainID",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "senderToken",
        type: "bytes",
      },
      {
        internalType: "address",
        name: "receiverToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "exchangeRate",
        type: "uint256",
      },
    ],
    name: "enableTokenToToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "exchangeRates",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feePool",
    outputs: [
      {
        internalType: "contract IFeePool",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "senderChainID",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "senderWallet",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "senderToken",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "senderAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "receiverChainID",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "receiverWallet",
            type: "bytes",
          },
        ],
        internalType: "struct TokenBridgePier.TokenTransfer",
        name: "tokenTransfer",
        type: "tuple",
      },
    ],
    name: "getMessagingFee",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        internalType: "struct MessagePierLib.Fee[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "senderChainID",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "senderWallet",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "senderToken",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "senderAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "receiverChainID",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "receiverWallet",
            type: "bytes",
          },
        ],
        internalType: "struct TokenBridgePier.TokenTransfer",
        name: "tokenTransfer",
        type: "tuple",
      },
    ],
    name: "getTokenBridgeFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "messagePier",
    outputs: [
      {
        internalType: "contract IMessagePier",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "myChainID",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "otherTokenBridgePiers",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "sourceChainId",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "sourcePierAddress",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "messageId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "targetChainId",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "targetPierAddress",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "messageSenderAddress",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        internalType: "struct MessagePierLib.Message",
        name: "message",
        type: "tuple",
      },
      {
        internalType: "bytes[]",
        name: "signatures",
        type: "bytes[]",
      },
    ],
    name: "receiveToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "receiverTokens",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "senderChainID",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "senderWallet",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "senderToken",
            type: "bytes",
          },
          {
            internalType: "uint256",
            name: "senderAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "receiverChainID",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "receiverWallet",
            type: "bytes",
          },
        ],
        internalType: "struct TokenBridgePier.TokenTransfer",
        name: "tokenTransfer",
        type: "tuple",
      },
    ],
    name: "sendToken",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IMessagePier",
        name: "_messagePier",
        type: "address",
      },
      {
        internalType: "contract ITokenPool",
        name: "_tokenPool",
        type: "address",
      },
      {
        internalType: "contract IFeePool",
        name: "_feePool",
        type: "address",
      },
    ],
    name: "setup",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenPool",
    outputs: [
      {
        internalType: "contract ITokenPool",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "transferLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class TokenBridgePier__factory {
  static readonly abi = _abi;
  static createInterface(): TokenBridgePierInterface {
    return new Interface(_abi) as TokenBridgePierInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): TokenBridgePier {
    return new Contract(address, _abi, runner) as unknown as TokenBridgePier;
  }
}
