import type { UseTransactionReturn } from '../../hook';
import type { ButtonSectionProps } from './ButtonSection';

import { ButtonSection } from './ButtonSection';
import { MessageSection } from './MessageSection';

import styles from './ConfirmSection.module.scss';

export type ConfirmSectionProps = {
  tx: UseTransactionReturn;
  onConfirm?: ButtonSectionProps['onConfirm'];
  target?: ButtonSectionProps['target'];
  mode?: ButtonSectionProps['mode'];
  confirmText?: ButtonSectionProps['confirmText'];
  className?: string;
  style?: React.CSSProperties;
};

export function ConfirmSection(props: ConfirmSectionProps) {
  const { tx, onConfirm, target, mode, confirmText, className = '', style } = props;

  return (
    <div className={`${styles.ConfirmSection} ${className}`} style={style}>
      <MessageSection tx={tx} />
      <ButtonSection
        tx={tx}
        onConfirm={onConfirm}
        target={target}
        mode={mode}
        confirmText={confirmText}
        className={styles.buttons}
      />
    </div>
  );
}
