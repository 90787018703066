import type { AppConfig } from './AppConfig';

import { Chains, Profile, TokenCreator, extractAddress } from 'ccms-web-core';
import avalanche from './address/dev-avalanche.json';
import bsc from './address/dev-bsc.json';
import eth from './address/dev-eth.json';
import polygon from './address/dev-polygon.json';

const tcEth = TokenCreator.createForEth(eth);
const tcBsc = TokenCreator.createForBsc(bsc);
const tcPolygon = TokenCreator.createForPolygon(polygon);
const tcAvalanche = TokenCreator.createForAvalanche(avalanche);

const infura = {
  projectId: '928677c8717f43f8ba74c4c22da1dde7',
};

const config: AppConfig = {
  env: 'dev',
  app: {
    backend: {
      url: 'https://bridge.metarisk.one/api',
      apiKey: 'MetaRiskDevAPI0123456789',
    },
    realm: {
      url: 'https://bridge.metarisk.one/gql',
      apiKey: 'MetaRiskDevAPI0123456789',
    },
    index: {
      endpoints: [{
        url: 'https://bridge.metarisk.one/activeIndex',
        apiKey: 'MetaRiskDevAPI0123456789',
      }],
    },
    profiles: [
      new Profile({
        chain: Chains.Sepolia,
        rpc: {
          urls: [
            `https://sepolia.infura.io/v3/${infura.projectId}`,
          ],
        },
        address: extractAddress(eth),
        nativeToken: tcEth.nativeToken,
        tokensBridge: [tcEth.wamt, tcEth.insur],
        tokens: tcEth.tokens,
      }),
      new Profile({
        chain: Chains.BscTestnet,
        rpc: {
          urls: [
            'https://data-seed-prebsc-1-s1.binance.org:8545',
            'https://data-seed-prebsc-2-s1.binance.org:8545',
          ],
        },
        address: extractAddress(bsc),
        nativeToken: tcBsc.nativeToken,
        tokensBridge: [tcBsc.wamt, tcBsc.insur],
        tokens: tcBsc.tokens,
      }),
      new Profile({
        chain: Chains.PolygonMumbai,
        rpc: {
          urls: [
            `https://polygon-mumbai.infura.io/v3/${infura.projectId}`,
          ],
        },
        address: extractAddress(polygon),
        nativeToken: tcPolygon.nativeToken,
        tokensBridge: [tcPolygon.wamt, tcPolygon.insur],
        tokens: tcPolygon.tokens,
      }),
      new Profile({
        chain: Chains.AvalancheFuji,
        rpc: {
          urls: [
            'https://api.avax-test.network/ext/bc/C/rpc',
          ],
        },
        address: extractAddress(avalanche),
        nativeToken: tcAvalanche.nativeToken,
        tokensBridge: [tcAvalanche.wamt, tcAvalanche.insur],
        tokens: tcAvalanche.tokens,
      }),
    ],
  },
};

export default config;
