import { WebFullProvider } from 'ccms-web-core';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { themes } from 'util/Theme';
import { init } from './Init';
import { NavProvider } from './NavProvider';

import './index.scss';

function main() {
  const { app } = init();

  const container = document.getElementById('root');

  if (container != null) {
    const root = createRoot(container);

    root.render((
      <StrictMode>
        <WebFullProvider
          app={app}
          themes={themes}
          cssVariableTarget={document.body}
        >
          <NavProvider />
        </WebFullProvider>
      </StrictMode>
    ));
  }
}

main();
