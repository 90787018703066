import styles from './WarningMessage.module.scss';

export type WarningMessageProps = {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function WarningMessage(props: WarningMessageProps) {
  const { className = '', style, children } = props;

  if (children == null || children === '') {
    return null;
  }

  return (
    <div className={`${styles.WarningMessage} ${className}`} style={style}>{ children }</div>
  );
}
