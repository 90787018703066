import type { BrowserProvider } from 'ethers';
import type { Chain, Token } from '../../entity';
import type { EipProvider } from './EipProvider';
import type { WalletInfo } from './WalletInfo';

const EipEvent = {
  CHAIN_CHANGED: 'chainChanged',
  ACCOUNTS_CHANGED: 'accountsChanged',
} as const;

export type InitInfo = {
  chainId: number;
  account: string;
};

export type ChainChangedHandler = (chainId: string) => void;
export type AccountsChangedHandler = (accounts: string[]) => void;

export abstract class BaseService {
  protected eipProvider: EipProvider | null;
  protected web3Provider: BrowserProvider | null;
  private handlersChainChanged: ChainChangedHandler[];
  private handlersAccountsChanged: AccountsChangedHandler[];

  public constructor() {
    this.eipProvider = null;
    this.web3Provider = null;

    this.handlersChainChanged = [];
    this.handlersAccountsChanged = [];
  }

  protected getEipProviderOrThrow(): EipProvider {
    if (this.eipProvider == null) {
      throw new Error('eipProvider null.');
    }

    return this.eipProvider;
  }

  public getInfo(): WalletInfo {
    throw new Error('getInfo is not implemented.');
  }

  public isInstalled(): boolean {
    return false;
  }

  public getWeb3Provider(): BrowserProvider | null {
    return this.web3Provider;
  }

  public async getChainId(): Promise<number> {
    const chainIdHex = await this.getEipProviderOrThrow().request({ method: 'eth_chainId' }) as string;

    return Number(chainIdHex);
  }

  public requestUnlock(chainId: number): Promise<InitInfo | null> {
    return Promise.resolve(null);
  }

  public requestLock(): Promise<void> {
    return Promise.resolve();
  }

  public requestAddToken(token: Token): Promise<void> {
    return Promise.resolve();
  }

  public requestAddChain(chain: Chain): Promise<void> {
    return Promise.resolve();
  }

  public requestSwitchChain(chain: Chain): Promise<void> {
    return Promise.resolve();
  }

  public removeAllListeners(): void {
    if (this.eipProvider == null) {
      return;
    }

    for (const handler of this.handlersChainChanged) {
      this.eipProvider.removeListener?.(EipEvent.CHAIN_CHANGED, handler);
    }

    this.handlersChainChanged = [];

    for (const handler of this.handlersAccountsChanged) {
      this.eipProvider.removeListener?.(EipEvent.ACCOUNTS_CHANGED, handler);
    }

    this.handlersAccountsChanged = [];
  }

  public onChainChanged(handler: ChainChangedHandler): void {
    this.getEipProviderOrThrow().on(EipEvent.CHAIN_CHANGED, handler);

    this.handlersChainChanged.push(handler);
  }

  public onAccountsChanged(handler: AccountsChangedHandler): void {
    this.getEipProviderOrThrow().on(EipEvent.ACCOUNTS_CHANGED, handler);

    this.handlersAccountsChanged.push(handler);
  }
}
