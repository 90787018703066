import type { Profile } from '../entity';

import { AppError, ContractError, UserRejectionError } from '../entity';
import { regexCapture } from './StringUtil';

function getContractErrorCode(cause: any): string | null {
  const message = cause?.error?.message ?? cause?.data?.message ?? cause?.message ?? '';
  const regex = new RegExp('execution reverted: ([a-zA-Z0-9]+:[ ]?[0-9]+)');
  const match = regexCapture(message, regex);

  return match?.replace(/[: ]+/g, '_') ?? null;
}

function getContractError(cause: any, profile: Profile): AppError | null {
  const code = getContractErrorCode(cause);

  return (code == null) ? null : new ContractError({
    profile,
    message: cause?.message ?? 'A contract error has occurred.',
    cause,
    code,
  });
}

export function parseError(cause: any, profile: Profile): AppError {
  // https://eips.ethereum.org/EIPS/eip-1193
  if (cause?.info?.error?.code === 4001) {
    return new UserRejectionError({
      profile,
      message: 'The user rejected the request.',
      cause,
    });
  }

  const error = getContractError(cause, profile);

  if (error != null) {
    return error;
  }

  return new AppError({
    profile,
    message: cause?.message ?? 'An error has occurred.',
    cause,
  });
}
