// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GkycQPDiWYohElZourgg{display:flex;flex-flow:row wrap;align-items:center;gap:.25em 1em}`, "",{"version":3,"sources":["webpack://./src/component/input/CheckboxGroup.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA","sourcesContent":[".CheckboxGroup {\n  display: flex;\n  flex-flow: row wrap;\n  align-items: center;\n  gap: 0.25em 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CheckboxGroup": `GkycQPDiWYohElZourgg`
};
export default ___CSS_LOADER_EXPORT___;
