// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FhzM9AWFaKSvQKtt6JwH{display:flex;align-items:center;gap:.5em;cursor:pointer}.FhzM9AWFaKSvQKtt6JwH .eUEhjjngRpSxjLOFLkXw{color:var(--palette-primary-main);font-size:1em}`, "",{"version":3,"sources":["webpack://./src/component/input/Radio.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,cAAA,CACA,4CACE,iCAAA,CACA,aAAA","sourcesContent":[".Radio {\n  display: flex;\n  align-items: center;\n  gap: 0.5em;\n  cursor: pointer;\n  .icon {\n    color: var(--palette-primary-main);\n    font-size: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Radio": `FhzM9AWFaKSvQKtt6JwH`,
	"icon": `eUEhjjngRpSxjLOFLkXw`
};
export default ___CSS_LOADER_EXPORT___;
