// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.u2nEKinYVH0tTRWmpkfx){background:none;color:inherit;font-size:inherit;border:none;padding:0;outline:none;cursor:pointer}:where(.u2nEKinYVH0tTRWmpkfx):disabled{cursor:not-allowed}`, "",{"version":3,"sources":["webpack://./src/component/html/Button.module.scss"],"names":[],"mappings":"AACA,8BACE,eAAA,CACA,aAAA,CACA,iBAAA,CACA,WAAA,CACA,SAAA,CACA,YAAA,CACA,cAAA,CACA,uCACE,kBAAA","sourcesContent":["// Reduce CSS specificity using :where() pseudo-class.\n:where(.Button) {\n  background: none;\n  color: inherit;\n  font-size: inherit;\n  border: none;\n  padding: 0;\n  outline: none;\n  cursor: pointer;\n  &:disabled {\n    cursor: not-allowed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": `u2nEKinYVH0tTRWmpkfx`
};
export default ___CSS_LOADER_EXPORT___;
