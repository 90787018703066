import { useCallback } from 'react';

import styles from './Input.module.scss';

export type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function Input(props: InputProps) {
  const { onChange, className } = props;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value, event);
  }, [onChange]);

  return (
    <input
      {...props}
      onChange={handleChange}
      className={`${styles.Input} ${className}`}
    />
  );
}
