import type { TransactionReceipt, TransactionResponse } from 'ethers';
import type { AppError, Operation } from '../entity';

import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TokenAmount } from '../entity';
import { parseError } from '../util';
import { useBlockchain } from './UseBlockchain';
import { useUiProfile } from './UseUiProfile';

const State = {
  Begin: 0,
  PendingApproval: 1,
  AfterApproval: 2,
  PendingConfirmation: 3,
};

export type TransactionResult = {
  id: string;
  operation?: Operation | null;
  response?: TransactionResponse | null;
  receipt?: TransactionReceipt | null;
  error?: any;
};

export type UseTransactionOption = {
  warning?: string | null | undefined;
  payAmount?: TokenAmount;
  operation?: Operation | null | undefined;
};

export type UseTransactionReturn = {
  warning: string | null | undefined;
  error: AppError | null;
  setError: (error: AppError | null) => void;
  gasFee: TokenAmount;
  isInputDisabled: boolean;
  isApproveVisible: boolean;
  isApproveLoading: boolean;
  isApproveDisabled: boolean;
  isConfirmLoading: boolean;
  isConfirmDisabled: boolean;
  handleApprove: () => Promise<TransactionResult>;
  handleConfirm: () => Promise<TransactionResult>;
};

export function useTransaction(option: UseTransactionOption): UseTransactionReturn {
  const { warning, payAmount, operation } = option;

  const { app, profile } = useUiProfile();
  const { operator } = useBlockchain();

  const [state, setState] = useState(State.Begin);
  const [gasFee] = useState(TokenAmount.zero(profile.nativeToken));
  const [allowance, setAllowance] = useState(TokenAmount.zero(operation?.token));
  const [error, setError] = useState<AppError | null>(null);
  const [isConfirmLoading, setConfirmLoading] = useState(false);

  const isApprovalRequired = (operation?.token == null) ? false : !operation.token.isNative;
  const isAllowanceEnough = (payAmount == null) ? true : allowance.gte(payAmount);

  const token = operation?.token;
  const getContract = operation?.getContract;

  useEffect(() => {
    if (token == null || getContract == null || !isApprovalRequired) {
      return;
    }

    const queryAllowance = async () => {
      try {
        const value = await operator.getAllowance(getContract, token);

        setAllowance(value);
      } catch (e) {
        app.logger.log(`Failed to query allowance for ${token.symbol}.`, e);
      }
    };

    queryAllowance();
  }, [app, getContract, token, operator, isApprovalRequired]);

  const doApprove = useCallback(async (): Promise<TransactionResult> => {
    const id = DateTime.now().valueOf().toString();

    if (operation == null) {
      return { id, error: new Error('operation is null.') };
    }

    try {
      const approveAmount = payAmount?.roundUp(0).toBigInt() ?? 0n;

      const { response, receipt } = await operator.approve(operation, approveAmount);

      return { id, operation, response, receipt };
    } catch (e) {
      app.logger.log(`Failed to approve ${operation.token?.symbol ?? ''}.`, e);

      return { id, operation, error: e };
    }
  }, [payAmount, operation, app, operator]);

  const doConfirm = useCallback(async (): Promise<TransactionResult> => {
    const id = DateTime.now().valueOf().toString();

    if (operation == null) {
      return { id, error: new Error('operation is null.') };
    }

    try {
      const response = await operator.sendTransaction(operation);

      const receipt = await operator.waitTransaction(response.hash);

      return { id, operation, response, receipt };
    } catch (e) {
      app.logger.log(operation.message, e);

      return { id, operation, error: e };
    }
  }, [operation, app, operator]);

  const handleApprove = useCallback(async () => {
    setState(State.PendingApproval);
    setError(null);

    const result = await doApprove();

    if (result.receipt != null) {
      setError(null);
      setState(State.AfterApproval);
    }

    if (result.error != null) {
      setError(parseError(result.error, profile));
      setState(State.Begin);
    }

    return result;
  }, [profile, doApprove]);

  const handleConfirm = useCallback(async () => {
    setState(State.PendingConfirmation);
    setConfirmLoading(true);
    setError(null);

    const result = await doConfirm();

    const { receipt } = result;

    if (receipt != null && result.operation != null) {
      try {
        await app.indexClient.requestIndex({
          profile,
          transactionHash: receipt.hash,
          eventNames: result.operation.eventNames,
        });
      } catch (e) {
        app.logger.log(`Failed to index ${result.operation.eventNames.join(', ')} events with transaction hash ${receipt.hash}.`, e);
      }

      setState(State.Begin);
      setConfirmLoading(false);
      setError(null);
    }

    if (result.error != null) {
      const parsedError = result.operation?.parseError?.(result.error, profile) ?? parseError(result.error, profile);

      setState(State.Begin);
      setError(parsedError);
      setConfirmLoading(false);
    }

    return result;
  }, [app, profile, doConfirm]);

  const tx = useMemo(() => ({
    warning,
    error,
    setError,
    gasFee,
    isInputDisabled: state !== State.Begin,
    isApproveVisible: isApprovalRequired,
    isApproveLoading: state === State.PendingApproval,
    isApproveDisabled: warning != null
      || (state === State.Begin && isApprovalRequired && isAllowanceEnough)
      || state === State.AfterApproval
      || state === State.PendingConfirmation,
    isConfirmLoading,
    isConfirmDisabled: warning != null
      || (state === State.Begin && isApprovalRequired && !isAllowanceEnough)
      || state === State.PendingApproval,
    handleApprove,
    handleConfirm,
  }), [
    warning, state, gasFee, error, isConfirmLoading, isApprovalRequired, isAllowanceEnough,
    handleApprove, handleConfirm,
  ]);

  return tx;
}
