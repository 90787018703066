import type { ContractRunner } from 'ethers';
import type { AddressConfig, Token } from '../entity';

import * as contracts from '../contract';

export class ContractManager {
  private readonly runner: ContractRunner;
  private readonly address: AddressConfig;

  public constructor(runner: ContractRunner, address: AddressConfig) {
    this.runner = runner;
    this.address = address;
  }

  public getTokenContract(token: Token): contracts.ERC20Upgradeable {
    return contracts.ERC20Upgradeable__factory.connect(token.address, this.runner);
  }

  public getTokenBridgePierContract(): contracts.TokenBridgePier {
    return contracts.TokenBridgePier__factory.connect(this.address.TokenBridgePier, this.runner);
  }
}
