import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { Button } from '../html/Button';
import { CircularProgress } from '../layout/CircularProgress';

import styles from './ActionButton.module.scss';

export type ActionButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function ActionButton(props: ActionButtonProps) {
  const {
    onClick,
    disabled = false,
    loading = false,
    className = '',
    style,
    children,
  } = props;

  const handleClick = useCallback(() => {
    if (!loading) {
      onClick?.();
    }
  }, [loading, onClick]);

  const buttonClassName = useMemo(() => {
    return classNames(styles.ActionButton, className, {
      [styles.ActionButton_loading as string]: loading,
    });
  }, [loading, className]);

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      className={buttonClassName}
      style={style}
    >
      <span>{ children }</span>
      { loading && <CircularProgress />}
    </Button>
  );
}
