// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qq02Skv4ACayAlFDfRIj{background:var(--palette-error-main);padding:.5em;border-radius:.75em}.qq02Skv4ACayAlFDfRIj .T9vb9GNeLu6eN3je1WKQ{color:var(--palette-link);margin-left:.5em;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/component/transaction/ErrorMessage.module.scss"],"names":[],"mappings":"AAAA,sBACE,oCAAA,CACA,YAAA,CACA,mBAAA,CACA,4CACE,yBAAA,CACA,gBAAA,CACA,cAAA","sourcesContent":[".ErrorMessage {\n  background: var(--palette-error-main);\n  padding: 0.5em;\n  border-radius: 0.75em;\n  .copy {\n    color: var(--palette-link);\n    margin-left: 0.5em;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ErrorMessage": `qq02Skv4ACayAlFDfRIj`,
	"copy": `T9vb9GNeLu6eN3je1WKQ`
};
export default ___CSS_LOADER_EXPORT___;
