import type { Profile } from '../entity';

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiProfileSlice } from '../store';
import { useApp } from './UseApp';

export function useUiProfile() {
  const dispatch = useDispatch();
  const sliceState = useSelector(selectUiProfileSlice);

  const { app } = useApp();

  const setProfile = useCallback((profile: Profile) => {
    dispatch(app.slices.uiProfile.actions.setUiProfile({ profile }));
  }, [dispatch, app]);

  return {
    app,
    profile: sliceState.profile,
    setProfile,
  };
}
