import { AdaptiveLink } from 'ccms-web-core';
import ImageEmail from 'asset/contact/Email.webp';
import ImageLinkedIn from 'asset/contact/LinkedIn.webp';
import ImageX from 'asset/contact/X.webp';
import Logo from 'asset/logo/LogoColor.webp';

import styles from './Footer.module.scss';

export function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.top}>
        <img src={Logo} alt="MetaRisk" className={styles.logo} />
      </div>
      <div className={styles.contacts}>
        <AdaptiveLink href="mailto:support@metarisk.com">
          <img src={ImageEmail} alt="Email" className={styles.contact} />
        </AdaptiveLink>
        <AdaptiveLink href="https://twitter.com/MetaRisk_Labs">
          <img src={ImageX} alt="X" className={styles.contact} />
        </AdaptiveLink>
        <AdaptiveLink href="https://www.linkedin.com/company/metarisklabs">
          <img src={ImageLinkedIn} alt="LinkedIn" className={styles.contact} />
        </AdaptiveLink>
      </div>
      <div className={styles.links}>
        <AdaptiveLink href="https://docs.metarisk.com/metarisk-bridge-documentation/" className={styles.link}>
          Docs
        </AdaptiveLink>
        <a href="/AuditReport.pdf" target="_blank" className={styles.link}>
          Audited by FYEO
        </a>
        <AdaptiveLink href="https://docs.metarisk.com/metarisk-bridge-documentation/legal/terms-of-use" className={styles.link}>
          Terms of Use
        </AdaptiveLink>
        <AdaptiveLink href="https://docs.metarisk.com/metarisk-bridge-documentation/legal/privacy-policy" className={styles.link}>
          Privacy Policy
        </AdaptiveLink>
      </div>
    </div>
  );
}
