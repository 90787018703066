import type { Profile } from 'ccms-web-core';

import { shortenString, useBlockchain } from 'ccms-web-core';
import { CapsuleButton } from '../basic';

export type ConnectButtonProps = {
  target?: Profile;
};

export function ConnectButton(props: ConnectButtonProps) {
  const { target } = props;

  const { profile, account, disconnect, switchChain, setConnectWalletModalVisible } = useBlockchain();

  if (account == null) {
    return (
      <CapsuleButton onClick={() => setConnectWalletModalVisible(true)}>
        Connect Wallet
      </CapsuleButton>
    );
  }

  if (target != null && target !== profile) {
    return (
      <CapsuleButton onClick={() => switchChain(target.chain)}>
        Switch to { target.chain.name }
      </CapsuleButton>
    );
  }

  return (
    <CapsuleButton onClick={disconnect}>
      Disconnect { shortenString(account, 6, 4) }
    </CapsuleButton>
  );
}
