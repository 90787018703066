import type { Profile } from '../../entity';

import { useCallback } from 'react';
import { useBlockchain, useTranslationComponent } from '../../hook';
import { ActionButton } from '../input';

export type AdaptiveConnectButtonProps = {
  target?: Profile;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function AdaptiveConnectButton(props: AdaptiveConnectButtonProps) {
  const { target, className, style, children } = props;

  const { t } = useTranslationComponent(['wallet']);
  const { profile, account, setConnectWalletModalVisible, supportSwitchChain, switchChain } = useBlockchain();

  const handleSwitch = useCallback(() => {
    if (target != null) {
      switchChain(target.chain);
    }
  }, [target, switchChain]);

  if (account == null) {
    return (
      <ActionButton onClick={() => setConnectWalletModalVisible(true)} className={className} style={style}>
        { t('wallet:connectWallet') }
      </ActionButton>
    );
  }

  if (target != null && target !== profile && supportSwitchChain) {
    return (
      <ActionButton onClick={handleSwitch} className={className} style={style}>
        { t('wallet:switchTo', { name: target.chain.name }) }
      </ActionButton>
    );
  }

  return <>{ children }</>;
}
