import styles from './Button.module.scss';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export function Button(props: ButtonProps) {
  const { className = '' } = props;

  return (
    <button
      {...props}
      className={`${styles.Button} ${className}`}
    />
  );
}
