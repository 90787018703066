import type { Chain, Token } from '../../entity';
import type { InitInfo } from './BaseService';
import type { EipProvider } from './EipProvider';

import { BrowserProvider, toQuantity } from 'ethers';
import { BaseService } from './BaseService';

export abstract class BaseWindowEthereumService extends BaseService {
  public constructor(injected?: EipProvider | null | undefined) {
    super();

    this.eipProvider = injected ?? window.ethereum as EipProvider | undefined ?? null;
    this.web3Provider = (this.eipProvider == null) ? null : new BrowserProvider(this.eipProvider, 'any');
  }

  public override async requestUnlock(chainId: number): Promise<InitInfo | null> {
    await this.getEipProviderOrThrow().request({ method: 'eth_requestAccounts' });

    return null;
  }

  public override async requestAddToken(token: Token): Promise<void> {
    const isSuccessful = await this.getEipProviderOrThrow().request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.imageUrl,
        },
      },
    }) as boolean;

    if (!isSuccessful) {
      throw new Error();
    }
  }

  public override async requestAddChain(chain: Chain): Promise<void> {
    const error = await this.getEipProviderOrThrow().request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: toQuantity(chain.chainId),
        chainName: chain.name,
        nativeCurrency: {
          name: chain.symbol,
          symbol: chain.symbol,
          decimals: chain.decimals,
        },
        rpcUrls: [chain.rpcUrl],
        blockExplorerUrls: [chain.blockExplorer.url],
      }],
    });

    if (error != null) {
      throw error;
    }
  }

  public override async requestSwitchChain(chain: Chain): Promise<void> {
    try {
      const error = await this.getEipProviderOrThrow().request({
        method: 'wallet_switchEthereumChain',
        params: [{
          chainId: toQuantity(chain.chainId),
        }],
      });

      if (error != null) {
        throw error;
      }
    } catch (e) {
      const error = e as Error & { code?: number };

      // This error code indicates that the chain has not been added to MetaMask.
      if (error.code === 4902) {
        await this.requestAddChain(chain);
      }

      throw error;
    }
  }
}
