import type { QueryReturn } from '../entity';

import { useCallback, useMemo, useState } from 'react';
import { useApp } from './UseApp';

type QueryFunc<T> = () => Promise<QueryReturn<T>>;

export function useQueryRows<T>() {
  const { app } = useApp();

  const [rows, setRows] = useState<T[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const reset = useCallback(() => {
    setRows([]);
    setCount(0);
  }, []);

  const queryRows = useCallback(async (queryFunc: QueryFunc<T>, message: string) => {
    reset();

    setLoading(true);

    let result = null;

    try {
      result = await queryFunc();

      setRows(result.rows);
      setCount(result.count);
    } catch (error) {
      app.logger.log(message, error);
    }

    setLoading(false);

    return result;
  }, [app, reset]);

  const row = useMemo(() => {
    return rows[0] ?? null;
  }, [rows]);

  return {
    app,
    row,
    rows,
    count,
    loading,
    reset,
    queryRows,
  };
}
