import type { Profile, Token } from '../entity';

import { useCallback, useEffect, useState } from 'react';
import { TokenAmount } from '../entity';
import { useApp } from './UseApp';

export function useBridgeConfig(profile: Profile | null | undefined, token: Token | null | undefined) {
  const { app } = useApp();

  const [bridgeConfig, setBridgeConfig] = useState({
    senderAmountMax: TokenAmount.zero(token),
  });

  const queryBridgeConfig = useCallback(async () => {
    setBridgeConfig({
      senderAmountMax: TokenAmount.zero(token),
    });

    if (profile == null || token == null) {
      return;
    }

    try {
      const reader = await app.blockchainReaders.getReader(profile);
      const result = await reader.getBridgeConfig(token);

      setBridgeConfig(result);
    } catch (e) {
      // Do nothing.
    }
  }, [profile, token, app]);

  useEffect(() => {
    queryBridgeConfig();
  }, [queryBridgeConfig]);

  return {
    bridgeConfig,
    queryBridgeConfig,
  };
}
