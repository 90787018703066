import type { Theme } from 'ccms-web-core';

export const DarkTheme: Theme = {
  name: 'Dark',
  variables: {
    '--palette-normal-main': '#2F1B4D',
    '--palette-normal-text': 'white',
    '--palette-normal-highlight': 'rgba(255 255 255 / 0.1)',
    '--palette-primary-main': '#C24DFE',
    '--palette-primary-text': 'white',
    '--palette-success-main': 'rgba(40 218 152 / 0.2)',
    '--palette-success-text': 'rgb(40 218 152)',
    '--palette-warning-main': 'rgba(250 173 20 / 0.2)',
    '--palette-warning-text': 'rgb(250 173 20)',
    '--palette-error-main': 'rgba(255 0 0 / 0.2)',
    '--palette-error-text': 'rgb(255 0 0)',
    '--palette-grey-main': 'rgba(170 170 170 / 0.2)',
    '--palette-grey-text': 'rgb(170 170 170)',
    '--palette-link': '#2D8CF0',
    '--palette-border': '#999999',
    '--palette-shadow': '#E5E7EB',
    '--palette-backdrop': 'rgba(0 0 0 / 0.7)',
  },
};

export const themes = [DarkTheme];
