// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.AN03RT7DKzUhZ0dMFy9G){font-size:inherit;font-weight:inherit;margin-block:0;margin-inline:0}`, "",{"version":3,"sources":["webpack://./src/component/html/Heading.module.scss"],"names":[],"mappings":"AACA,8BACE,iBAAA,CACA,mBAAA,CACA,cAAA,CACA,eAAA","sourcesContent":["// Reduce CSS specificity using :where() pseudo-class.\n:where(.Heading) {\n  font-size: inherit;\n  font-weight: inherit;\n  margin-block: 0;\n  margin-inline: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Heading": `AN03RT7DKzUhZ0dMFy9G`
};
export default ___CSS_LOADER_EXPORT___;
