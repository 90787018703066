import type { ThemeOptions } from '@mui/material';

import { ThemeProvider, createTheme } from '@mui/material';

export type MuiProviderProps = {
  themeOption?: ThemeOptions;
  children?: React.ReactNode;
};

export function MuiProvider(props: MuiProviderProps) {
  const { themeOption, children } = props;

  const theme = createTheme(themeOption);

  return <ThemeProvider theme={theme}>{ children }</ThemeProvider>;
}
