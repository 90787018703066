import type { Profile } from 'ccms-web-core';

import { IconSelect } from 'ccms-web-core';
import { useMemo } from 'react';

import styles from './ProfileSelect.module.scss';

export type ProfileSelectProps = {
  value: Profile;
  onChange: (value: Profile) => void;
  profiles: Profile[];
};

export function ProfileSelect(props: ProfileSelectProps) {
  const { value, onChange, profiles } = props;

  const options = useMemo(() => {
    return profiles.map((item) => ({
      value: item,
      title: item.chain.name,
      src: item.chain.group.src,
    }));
  }, [profiles]);

  return (
    <IconSelect
      value={value}
      onChange={onChange}
      options={options}
      className={styles.ProfileSelect}
    />
  );
}
