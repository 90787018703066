import type { App } from '../app';

import { AppContext } from '../context';

export type AppProviderProps = {
  app: App;
  children?: React.ReactNode;
};

export function AppProvider(props: AppProviderProps) {
  const { app, children } = props;

  return (
    <AppContext.Provider value={app}>
      { children }
    </AppContext.Provider>
  );
}
