// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UJYNoXseMTOO_PRe9BQ6{padding-inline-start:1em;margin-block-start:0;margin-block-end:0}.UJYNoXseMTOO_PRe9BQ6 .TvissyiaFKtfG_y58idw:not(:first-child){margin-top:var(--unordered-list-margin)}`, "",{"version":3,"sources":["webpack://./src/component/layout/UnorderedList.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,oBAAA,CACA,kBAAA,CACA,8DACE,uCAAA","sourcesContent":[".UnorderedList {\n  padding-inline-start: 1em;\n  margin-block-start: 0;\n  margin-block-end: 0;\n  .item:not(:first-child) {\n    margin-top: var(--unordered-list-margin);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UnorderedList": `UJYNoXseMTOO_PRe9BQ6`,
	"item": `TvissyiaFKtfG_y58idw`
};
export default ___CSS_LOADER_EXPORT___;
