import { useMemo } from 'react';
import { Input } from '../html/Input';
import { Tooltip } from '../layout/Tooltip';

import styles from './AdvancedInput.module.scss';

export type AdvancedInputProps = {
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  type?: string;
  min?: number;
  max?: number;
  placeholder?: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  validate?: (value: string) => string | null;
  className?: string;
  style?: React.CSSProperties;
};

export function AdvancedInput(props: AdvancedInputProps) {
  const {
    value, onChange, disabled, type, min, max, placeholder, before, after, prepend, append, validate,
    className = '', style,
  } = props;

  const tooltip = useMemo(() => {
    return validate?.(value) ?? '';
  }, [value, validate]);

  return (
    <Tooltip content={tooltip} placement="top">
      <div className={`${styles.AdvancedInput} ${className}`} style={style}>
        { (prepend != null) && <div className={styles.prepend}>{ prepend }</div> }
        <div className={styles.mid}>
          { before }
          <Input
            value={value}
            onChange={onChange}
            disabled={disabled}
            type={type}
            min={min}
            max={max}
            placeholder={placeholder}
            className={styles.input}
          />
          { after }
        </div>
        { (append != null) && <div className={styles.append}>{ append }</div> }
      </div>
    </Tooltip>
  );
}
