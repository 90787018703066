// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.mKjpxldacIFcDclO3GoA){width:100%;background:none;color:inherit;font-family:inherit;font-size:inherit;border:none;resize:none;outline:none}`, "",{"version":3,"sources":["webpack://./src/component/html/TextArea.module.scss"],"names":[],"mappings":"AACA,8BACE,UAAA,CACA,eAAA,CACA,aAAA,CACA,mBAAA,CACA,iBAAA,CACA,WAAA,CACA,WAAA,CACA,YAAA","sourcesContent":["// Reduce CSS specificity using :where() pseudo-class.\n:where(.TextArea) {\n  width: 100%;\n  background: none;\n  color: inherit;\n  font-family: inherit;\n  font-size: inherit;\n  border: none;\n  resize: none;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextArea": `mKjpxldacIFcDclO3GoA`
};
export default ___CSS_LOADER_EXPORT___;
