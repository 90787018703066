import type { EipProvider } from './EipProvider';
import type { WalletInfo } from './WalletInfo';

import { BaseWindowEthereumService } from './BaseWindowEthereumService';
import { WalletInfos } from './WalletInfo';

type WindowMetaMask = {
  ethereum?: EipProvider & {
    isMetaMask?: boolean;
  };
};

export class MetaMaskService extends BaseWindowEthereumService {
  public override getInfo(): WalletInfo {
    return WalletInfos.MetaMask;
  }

  public override isInstalled(): boolean {
    // Can be used in desktop browsers with MetaMask extension or the built-in browser of MetaMask
    // mobile app.
    return (window as WindowMetaMask).ethereum?.isMetaMask ?? false;
  }
}
