import { Radio } from './Radio';

import styles from './RadioGroup.module.scss';

export type RadioGroupOption<T> = {
  value: T;
  label?: React.ReactNode;
};

export type RadioGroupProps<T> = {
  value: T;
  onChange?: (value: T) => void;
  options?: RadioGroupOption<T>[];
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export function RadioGroup<T>(props: RadioGroupProps<T>) {
  const { value, onChange, options = [], disabled = false, className = '', style } = props;

  return (
    <div className={`${styles.RadioGroup} ${className}`} style={style}>
      {
        options.map((item, index) => {
          return (
            <Radio
              key={index}
              value={value === item.value}
              onChange={() => onChange?.(item.value)}
              disabled={disabled}
            >
              { item.label }
            </Radio>
          );
        })
      }
    </div>
  );
}
