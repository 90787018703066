import type Provider from '@walletconnect/ethereum-provider';
import type { Chain, Profile, Token } from '../../entity';
import type { BlockchainReaders } from '../BlockchainReaders';
import type { InitInfo } from './BaseService';
import type { WalletInfo } from './WalletInfo';

import { BrowserProvider, toQuantity } from 'ethers';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { asyncReduceToObject } from '../../util';
import { BaseService } from './BaseService';
import { WalletInfos } from './WalletInfo';

export class WalletConnectService extends BaseService {
  private profiles: Profile[];
  private blockchainReaders: BlockchainReaders;
  private projectId: string;
  private walletConnect: Provider | null;

  public constructor(profiles: Profile[], blockchainReaders: BlockchainReaders, projectId: string) {
    super();

    this.profiles = profiles;
    this.blockchainReaders = blockchainReaders;
    this.projectId = projectId;
    this.walletConnect = null;
  }

  public override getInfo(): WalletInfo {
    return WalletInfos.WalletConnect;
  }

  public override isInstalled(): boolean {
    // Can be used in all browsers, including browsers without any wallet extension and the
    // built-in browsers of wallet mobile apps.
    return true;
  }

  public override async requestUnlock(chainId: number): Promise<InitInfo | null> {
    this.removeAllListeners();

    const rpcMap = await asyncReduceToObject(this.profiles, async (profile) => {
      const reader = await this.blockchainReaders.getReader(profile);

      return [profile.chain.chainId.toString(), reader.getJsonRpcUrl()];
    });

    const walletConnect = await EthereumProvider.init({
      projectId: this.projectId,
      chains: [chainId],
      optionalChains: this.profiles.map((profile) => profile.chain.chainId),
      showQrModal: true,
      rpcMap,
    });

    this.walletConnect = walletConnect;
    this.eipProvider = walletConnect;
    this.web3Provider = new BrowserProvider(this.eipProvider, 'any');

    await this.walletConnect.connect();

    return null;
  }

  public override requestLock(): Promise<void> {
    if (this.walletConnect == null) {
      throw new Error('walletConnect is not defined.');
    }

    return this.walletConnect.disconnect();
  }

  public override async requestAddToken(token: Token): Promise<void> {
    const isSuccessful = await this.getEipProviderOrThrow().request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.imageUrl,
        },
      },
    }) as boolean;

    if (!isSuccessful) {
      throw new Error();
    }
  }

  public override async requestAddChain(chain: Chain): Promise<void> {
    const error = await this.getEipProviderOrThrow().request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: toQuantity(chain.chainId),
        chainName: chain.name,
        nativeCurrency: {
          name: chain.symbol,
          symbol: chain.symbol,
          decimals: chain.decimals,
        },
        rpcUrls: [chain.rpcUrl],
        blockExplorerUrls: [chain.blockExplorer.url],
      }],
    });

    if (error != null) {
      throw error;
    }
  }

  public override async requestSwitchChain(chain: Chain): Promise<void> {
    try {
      const error = await this.getEipProviderOrThrow().request({
        method: 'wallet_switchEthereumChain',
        params: [{
          chainId: toQuantity(chain.chainId),
        }],
      });

      if (error != null) {
        throw error;
      }
    } catch (e) {
      const error = e as Error & { code?: number };

      // This error code indicates that the chain has not been added to MetaMask.
      if (error.code === 4902) {
        await this.requestAddChain(chain);
      }

      throw error;
    }
  }
}
