import type { ReducersMapObject } from '@reduxjs/toolkit';
import type { AppCache } from '../cache';
import type { Profile } from '../entity';

import { configureStore } from '@reduxjs/toolkit';
import { createUiProfileSlice } from './UiProfileSlice';
import { createWalletSlice } from './WalletSlice';

export type CreateStoreOption<T extends ReducersMapObject> = {
  cache: AppCache;
  profiles: Profile[];
  reducer?: T;
};

export function createStore<T extends ReducersMapObject>(option: CreateStoreOption<T>) {
  const walletSlice = createWalletSlice();
  const uiProfileSlice = createUiProfileSlice(option.profiles, walletSlice);

  return {
    slices: {
      uiProfile: uiProfileSlice,
      wallet: walletSlice,
    },
    store: configureStore({
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
      }),
      reducer: {
        uiProfile: uiProfileSlice.reducer,
        wallet: walletSlice.reducer,
        ...option.reducer,
      },
    }),
  };
}

export type AppStore = ReturnType<typeof createStore>['store'];
export type AppStoreSlices = ReturnType<typeof createStore>['slices'];
