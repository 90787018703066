import type { EipProvider } from './EipProvider';
import type { WalletInfo } from './WalletInfo';

import { BaseWindowEthereumService } from './BaseWindowEthereumService';
import { WalletInfos } from './WalletInfo';

type WindowCoin98 = {
  ethereum?: EipProvider & {
    isCoin98?: boolean;
  };
};

export class Coin98Service extends BaseWindowEthereumService {
  public override getInfo(): WalletInfo {
    return WalletInfos.Coin98;
  }

  public override isInstalled(): boolean {
    // Can be used in desktop browsers with Coin98 extension or the built-in browser of Coin98
    // mobile app.
    return (window as WindowCoin98).ethereum?.isCoin98 ?? false;
  }
}
