// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vSpMebbB8l9vFJvVRkad{display:flex;justify-content:flex-start;align-items:center;gap:.5em}.vSpMebbB8l9vFJvVRkad .MLtk9KB9znW86WYjB2ww{width:1.5em;height:1.5em}`, "",{"version":3,"sources":["webpack://./src/component/input/IconSelect.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,QAAA,CACA,4CACE,WAAA,CACA,YAAA","sourcesContent":[".option {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 0.5em;\n  .image {\n    width: 1.5em;\n    height: 1.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": `vSpMebbB8l9vFJvVRkad`,
	"image": `MLtk9KB9znW86WYjB2ww`
};
export default ___CSS_LOADER_EXPORT___;
