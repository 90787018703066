import type { TokenGroup } from './TokenGroup';

import { ZeroAddress } from 'ethers';
import { DEFAULT_TOKEN_GROUP } from './TokenGroup';

export type Token = {
  readonly group: TokenGroup;
  readonly symbol: string;
  readonly name: string;
  readonly isNative: boolean;
  readonly decimals: number;
  readonly fractionDigits: number;
  readonly address: string;
  readonly src: string;
  readonly imageUrl?: string;
};

export const DEFAULT_TOKEN: Token = {
  group: DEFAULT_TOKEN_GROUP,
  symbol: '',
  name: '',
  isNative: false,
  decimals: 0,
  fractionDigits: 0,
  address: ZeroAddress,
  src: '',
};
