import { gsap } from 'gsap';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useApp } from '../hook';

export type Theme = {
  name: string;
  variables: {
    '--palette-normal-main': string;
    '--palette-normal-text': string;
    '--palette-normal-highlight': string;
    '--palette-primary-main': string;
    '--palette-primary-text': string;
    '--palette-success-main': string;
    '--palette-success-text': string;
    '--palette-warning-main': string;
    '--palette-warning-text': string;
    '--palette-error-main': string;
    '--palette-error-text': string;
    '--palette-grey-main': string;
    '--palette-grey-text': string;
    '--palette-link': string;
    '--palette-border': string;
    '--palette-shadow': string;
    '--palette-backdrop': string;
    [key: string]: string;
  };
};

export const ThemeContext = createContext({
  theme: undefined as Theme | undefined,
  setTheme: (theme: Theme | undefined) => {},
});

export type ThemeProviderProps = {
  themes: Theme[];
  cssVariableTarget: HTMLElement;
  children?: React.ReactNode;
};

export function ThemeProvider(props: ThemeProviderProps) {
  const { themes, cssVariableTarget, children } = props;

  const { app } = useApp();

  const defaultTheme = useMemo(() => {
    return themes.find((item) => item.name === app.cache.getUserSettings().theme) ?? themes[0];
  }, [themes, app]);

  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    gsap.to(cssVariableTarget, {
      ...theme?.variables,
      duration: 0.2,
    });
  }, [cssVariableTarget, theme]);

  const value = useMemo(() => {
    return {
      theme,
      setTheme,
    };
  }, [theme]);

  return <ThemeContext.Provider value={value}>{ children }</ThemeContext.Provider>;
}
