// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtys4pgTEWzXTauHtHN0{position:relative}.dtys4pgTEWzXTauHtHN0 .c0YOW5j9ccmXhLZ1pNLg{position:absolute;inset:0;display:flex;justify-content:center;align-items:center;color:var(--palette-primary-main);overflow:hidden}.dtys4pgTEWzXTauHtHN0.eswAlzURhkD3LKg4HhDq .z96jl4Pu02RjTKxychwx{filter:opacity(0.3)}`, "",{"version":3,"sources":["webpack://./src/component/layout/LoadingSpin.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,4CACE,iBAAA,CACA,OAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iCAAA,CACA,eAAA,CAGA,iEACE,mBAAA","sourcesContent":[".LoadingSpin {\n  position: relative;\n  .body {\n    position: absolute;\n    inset: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: var(--palette-primary-main);\n    overflow: hidden; // Avoid scrollbar.\n  }\n  &.LoadingSpin_loading {\n    .backdrop {\n      filter: opacity(0.3);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoadingSpin": `dtys4pgTEWzXTauHtHN0`,
	"body": `c0YOW5j9ccmXhLZ1pNLg`,
	"LoadingSpin_loading": `eswAlzURhkD3LKg4HhDq`,
	"backdrop": `z96jl4Pu02RjTKxychwx`
};
export default ___CSS_LOADER_EXPORT___;
