async function choose<T>(entities: T[], evaluate: (entity: T) => Promise<unknown>): Promise<number> {
  try {
    const chosenIndex = await Promise.any(entities.map(async (entity, index) => {
      await evaluate(entity);

      return index;
    }));

    return chosenIndex;
  } catch (error) {
    return 0;
  }
}

export class Chooser<T> {
  private readonly entities: T[];
  private readonly promiseIndex: Promise<number>;

  public constructor(entities: T[], evaluate: (entity: T) => Promise<unknown>) {
    this.entities = entities;
    this.promiseIndex = choose(entities, evaluate);
  }

  public async get(): Promise<T> {
    const index = await this.promiseIndex;

    const entity = this.entities[index];

    if (entity == null) {
      throw new Error(`No entity found for index ${index}.`);
    }

    return entity;
  }
}
