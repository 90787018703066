import { AdaptiveLink, Container } from 'ccms-web-core';
import Logo from 'asset/logo/LogoColor.webp';

import styles from './ResponsiveNavBar.module.scss';

export function ResponsiveNavBar() {
  return (
    <Container maxWidth="1200px" padding="1em 0.5em">
      <div className={styles.ResponsiveNavBar}>
        <AdaptiveLink href="https://metarisk.com">
          <img src={Logo} alt="MetaRisk" className={styles.logo} />
        </AdaptiveLink>
        <AdaptiveLink href="https://docs.metarisk.com/metarisk-bridge-documentation/" className={styles.link}>
          Docs
        </AdaptiveLink>
      </div>
    </Container>
  );
}
