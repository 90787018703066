// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v4ED054VeTzqZyd_os6B{display:flex;justify-content:flex-start;align-items:center;gap:.5em}.v4ED054VeTzqZyd_os6B .l7qXY2LnLtmsXHSSXk0e{min-width:2em;text-align:center;border-radius:100em;padding:.5em}.v4ED054VeTzqZyd_os6B .l7qXY2LnLtmsXHSSXk0e.gb_QlzcF46ptRGAnH5NC{background:var(--palette-grey-main)}.v4ED054VeTzqZyd_os6B .Z2WHKtYvph3ivGrj9qQK{min-width:2em;padding:.5em}.v4ED054VeTzqZyd_os6B .Z2WHKtYvph3ivGrj9qQK:disabled{filter:opacity(50%);cursor:not-allowed}.v4ED054VeTzqZyd_os6B .Z2WHKtYvph3ivGrj9qQK .vn_dFWlJolsuhrZtJ6ct{font-size:1em}.v4ED054VeTzqZyd_os6B .VDF95DYAYQq6fPNcA0Hl{min-width:3em;border:1px solid var(--palette-border);border-radius:.1em;padding-left:.25em}`, "",{"version":3,"sources":["webpack://./src/component/table/Pagination.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,0BAAA,CACA,kBAAA,CACA,QAAA,CACA,4CACE,aAAA,CACA,iBAAA,CACA,mBAAA,CACA,YAAA,CACA,iEACE,mCAAA,CAGJ,4CACE,aAAA,CACA,YAAA,CACA,qDACE,mBAAA,CACA,kBAAA,CAEF,kEACE,aAAA,CAGJ,4CACE,aAAA,CACA,sCAAA,CACA,kBAAA,CACA,kBAAA","sourcesContent":[".base {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 0.5em;\n  .page {\n    min-width: 2em;\n    text-align: center;\n    border-radius: 100em;\n    padding: 0.5em;\n    &.page_selected {\n      background: var(--palette-grey-main);\n    }\n  }\n  .button {\n    min-width: 2em;\n    padding: 0.5em;\n    &:disabled {\n      filter: opacity(50%);\n      cursor: not-allowed;\n    }\n    .icon {\n      font-size: 1em;\n    }\n  }\n  .select {\n    min-width: 3em;\n    border: 1px solid var(--palette-border);\n    border-radius: 0.1em;\n    padding-left: 0.25em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": `v4ED054VeTzqZyd_os6B`,
	"page": `l7qXY2LnLtmsXHSSXk0e`,
	"page_selected": `gb_QlzcF46ptRGAnH5NC`,
	"button": `Z2WHKtYvph3ivGrj9qQK`,
	"icon": `vn_dFWlJolsuhrZtJ6ct`,
	"select": `VDF95DYAYQq6fPNcA0Hl`
};
export default ___CSS_LOADER_EXPORT___;
