import type { EipProvider } from './EipProvider';
import type { WalletInfo } from './WalletInfo';

import { BaseWindowEthereumService } from './BaseWindowEthereumService';
import { WalletInfos } from './WalletInfo';

type WindowOkxService = {
  okxwallet?: EipProvider;
};

export class OkxService extends BaseWindowEthereumService {
  public constructor() {
    super((window as WindowOkxService).okxwallet);
  }

  public override getInfo(): WalletInfo {
    return WalletInfos.OKXWallet;
  }

  public override isInstalled(): boolean {
    // Can be used in desktop browsers with OkxService extension or the built-in browser of OkxService
    // mobile app.
    return (window as WindowOkxService).okxwallet != null;
  }
}
