// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nKhBCSAqKYahlRYxjvmM{display:inline-flex;align-items:center;justify-content:center;gap:.5em;background:var(--palette-primary-main);color:var(--palette-primary-text);font-weight:bold;border-radius:.75em;padding:.75em}.nKhBCSAqKYahlRYxjvmM:disabled{filter:opacity(50%);cursor:not-allowed}.nKhBCSAqKYahlRYxjvmM.f6D8OTKT0WAdZe8oQ05Q{filter:opacity(50%);cursor:not-allowed}`, "",{"version":3,"sources":["webpack://./src/component/input/ActionButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,sCAAA,CACA,iCAAA,CACA,gBAAA,CACA,mBAAA,CACA,aAAA,CACA,+BACE,mBAAA,CACA,kBAAA,CAEF,2CACE,mBAAA,CACA,kBAAA","sourcesContent":[".ActionButton {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  gap: 0.5em;\n  background: var(--palette-primary-main);\n  color: var(--palette-primary-text);\n  font-weight: bold;\n  border-radius: 0.75em;\n  padding: 0.75em;\n  &:disabled {\n    filter: opacity(50%);\n    cursor: not-allowed;\n  }\n  &.ActionButton_loading {\n    filter: opacity(50%);\n    cursor: not-allowed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ActionButton": `nKhBCSAqKYahlRYxjvmM`,
	"ActionButton_loading": `f6D8OTKT0WAdZe8oQ05Q`
};
export default ___CSS_LOADER_EXPORT___;
