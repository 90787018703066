import type { CoinbaseWalletProvider } from '@coinbase/wallet-sdk';
import type { Profile } from '../../entity';
import type { BlockchainReaders } from '../BlockchainReaders';
import type { InitInfo } from './BaseService';
import type { WalletInfo } from './WalletInfo';

import { CoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import { BrowserProvider } from 'ethers';
import { getProfileByChainIdOrDefault } from '../../entity';
import { BaseService } from './BaseService';
import { WalletInfos } from './WalletInfo';

export class CoinbaseWalletService extends BaseService {
  private profiles: Profile[];
  private blockchainReaders: BlockchainReaders;
  private walletSdk: CoinbaseWalletSDK;
  private coinbaseProvider: CoinbaseWalletProvider | null;

  public constructor(profiles: Profile[], blockchainReaders: BlockchainReaders) {
    super();

    this.profiles = profiles;
    this.blockchainReaders = blockchainReaders;

    this.walletSdk = new CoinbaseWalletSDK({
      appName: 'InsurAce',
    });

    this.coinbaseProvider = null;
  }

  public override getInfo(): WalletInfo {
    return WalletInfos.CoinbaseWallet;
  }

  public override isInstalled(): boolean {
    // Can be used in all browsers, including browsers without any wallet extension and the
    // built-in browsers of wallet mobile apps.
    return true;
  }

  public override async requestUnlock(chainId: number): Promise<InitInfo | null> {
    const reader = await this.blockchainReaders.getReader(getProfileByChainIdOrDefault(this.profiles, chainId));

    const walletlinkProvider = this.walletSdk.makeWeb3Provider(reader.getJsonRpcUrl(), chainId);

    this.coinbaseProvider = walletlinkProvider;
    this.eipProvider = walletlinkProvider;
    this.web3Provider = new BrowserProvider(this.eipProvider, 'any');

    await this.eipProvider.request({ method: 'eth_requestAccounts' });

    return null;
  }

  public override async requestLock(): Promise<void> {
    if (this.coinbaseProvider == null) {
      throw new Error('waleltLinkProvider is not defined.');
    }

    this.coinbaseProvider.close();
  }
}
