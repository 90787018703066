import type { RefObject } from 'react';

import { useEffect, useState } from 'react';

export function useElementSize<T extends Element>(ref: RefObject<T>) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const { current } = ref;

    if (current == null) {
      return () => {};
    }

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setWidth(entry.target.clientWidth);
        setHeight(entry.target.clientHeight);
      }
    });

    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  }, [ref]);

  return {
    width,
    height,
  };
}
