import type { BaseContract, ContractTransaction } from 'ethers';
import type { ContractManager } from '../blockchain';
import type { AppError } from './Error';
import type { Profile } from './Profile';
import type { Token } from './Token';

export enum OperationAction {
  Unknown = 0,
  BridgeSend,
  BridgeReceive,
}

export type Operation = {
  readonly action: OperationAction;
  readonly eventNames: string[];
  readonly message: string;
  readonly token?: Token;
  readonly getContract: (manager: ContractManager) => BaseContract;
  readonly populateTx: (manager: ContractManager, account: string) => Promise<ContractTransaction>;
  readonly parseError?: (error: any, profile: Profile) => AppError | null;
};
