// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M20kqrtJfwdL3kDxxIWi{background:var(--palette-warning-main);padding:.5em;border-radius:.75em}`, "",{"version":3,"sources":["webpack://./src/component/transaction/WarningMessage.module.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAA,CACA,YAAA,CACA,mBAAA","sourcesContent":[".WarningMessage {\n  background: var(--palette-warning-main);\n  padding: 0.5em;\n  border-radius: 0.75em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WarningMessage": `M20kqrtJfwdL3kDxxIWi`
};
export default ___CSS_LOADER_EXPORT___;
