import type { Profile, Token } from '../entity';
import type { Logger } from '../log';

import { JsonRpcProvider } from 'ethers';
import { TokenAmount } from '../entity';
import { Transform, encodeAddress } from '../util';
import { ContractManager } from './ContractManager';

export class BlockchainReader {
  private readonly logger: Logger;
  private readonly profile: Profile;
  private readonly url: string;
  private readonly provider: JsonRpcProvider;
  private readonly contractManager: ContractManager;

  public constructor(logger: Logger, profile: Profile, url: string) {
    this.logger = logger;
    this.profile = profile;
    this.url = url;
    this.provider = new JsonRpcProvider(url);
    this.contractManager = new ContractManager(this.provider, profile.address);
  }

  public getJsonRpcUrl(): string {
    return this.url;
  }

  public getCurrentBlock(): Promise<number> {
    return this.provider.getBlockNumber();
  }

  public async getBalance(token: Token, owner: string): Promise<TokenAmount> {
    try {
      const balance = token.isNative
        ? await this.provider.getBalance(owner)
        : await this.contractManager.getTokenContract(token).balanceOf(owner);

      return TokenAmount.fromBigInt(token, balance);
    } catch (e) {
      this.log(`Failed to get the ${token.symbol} balance of user ${owner}.`, e);

      throw e;
    }
  }

  public async getBridgeConfig(token: Token) {
    try {
      const limit = await this.contractManager.getTokenBridgePierContract().transferLimit(token.address);

      return {
        senderAmountMax: TokenAmount.fromBigInt(token, limit),
      };
    } catch (e) {
      this.log('Failed to get bridge config.', e);

      throw e;
    }
  }

  public async getBridgeFeeSource(option: {
    senderAmount: TokenAmount;
    senderProfile: Profile;
    senderWallet: string;
    receiverProfile: Profile;
    receiverWallet: string;
  }) {
    const { senderAmount, senderProfile, senderWallet, receiverProfile, receiverWallet } = option;

    try {
      const fees = await this.contractManager.getTokenBridgePierContract().getMessagingFee({
        senderToken: encodeAddress(senderAmount.token.address),
        senderAmount: senderAmount.toBigInt(),
        senderChainID: senderProfile.chain.uniChainId,
        senderWallet: encodeAddress(senderWallet),
        receiverChainID: receiverProfile.chain.uniChainId,
        receiverWallet: encodeAddress(receiverWallet),
      });

      return {
        messagingFee: fees.map((item) => TokenAmount.fromBigInt(Transform.toToken(senderProfile, item.token), item.amount))
          .reduce((previous, current) => previous.add(current)),
      };
    } catch (e) {
      this.log(`Failed to get source bridge fee for sending ${senderAmount.formatExactSymbol()} from ${senderWallet} on ${senderProfile.chain.name} to ${receiverWallet} on ${receiverProfile.chain.name}.`, e);

      throw e;
    }
  }

  public async getBridgeFeeTarget(option: {
    senderAmount: TokenAmount;
    senderProfile: Profile;
    senderWallet: string;
    receiverProfile: Profile;
    receiverWallet: string;
  }) {
    const { senderAmount, senderProfile, senderWallet, receiverProfile, receiverWallet } = option;

    try {
      const [ownerFee, relayerFee] = await this.contractManager.getTokenBridgePierContract().getTokenBridgeFee({
        senderToken: encodeAddress(senderAmount.token.address),
        senderAmount: senderAmount.toBigInt(),
        senderChainID: senderProfile.chain.uniChainId,
        senderWallet: encodeAddress(senderWallet),
        receiverChainID: receiverProfile.chain.uniChainId,
        receiverWallet: encodeAddress(receiverWallet),
      });

      return {
        ownerFee: TokenAmount.fromBigInt(senderAmount.token, ownerFee),
        relayerFee: TokenAmount.fromBigInt(senderAmount.token, relayerFee),
      };
    } catch (e) {
      this.log(`Failed to get target bridge fee for sending ${senderAmount.formatExactSymbol()} from ${senderWallet} on ${senderProfile.chain.name} to ${receiverWallet} on ${receiverProfile.chain.name}.`, e);

      throw e;
    }
  }

  private log(...args: any[]) {
    this.logger.log(`[${this.profile.chain.name}]`, ...args);
  }
}
