import type { BackendClient } from '../backend';
import type { Operation } from './Operation';
import type { Profile } from './Profile';
import type { TokenAmount } from './TokenAmount';

import { encodeAddress } from '../util';
import { IndexEvent } from '../indexing';
import { OperationAction } from './Operation';

export class OperationCreator {
  private backendClient: BackendClient;

  public constructor(backendClient: BackendClient) {
    this.backendClient = backendClient;
  }

  public bridgeSend(option: {
    senderAmount: TokenAmount;
    senderProfile: Profile;
    senderWallet: string;
    receiverProfile: Profile;
    receiverWallet: string;
    fee: TokenAmount;
  }): Operation {
    const { senderAmount, senderProfile, senderWallet, receiverProfile, receiverWallet, fee } = option;

    return {
      action: OperationAction.BridgeSend,
      eventNames: [IndexEvent.BridgeSend],
      message: `${senderWallet} failed to send ${senderAmount.formatExactSymbol()} on ${senderProfile.chain.name} to ${receiverWallet} on ${receiverProfile.chain.name} via bridge.`,
      token: senderAmount.token,
      getContract: (manager) => manager.getTokenBridgePierContract(),
      populateTx: (manager) => {
        return manager.getTokenBridgePierContract().sendToken.populateTransaction({
          senderToken: encodeAddress(senderAmount.token.address),
          senderAmount: senderAmount.toBigInt(),
          senderChainID: senderProfile.chain.uniChainId,
          senderWallet: encodeAddress(senderWallet),
          receiverChainID: receiverProfile.chain.uniChainId,
          receiverWallet: encodeAddress(receiverWallet),
        }, {
          value: fee.toBigInt(),
        });
      },
    };
  }

  public bridgeReceive(option: {
    senderProfile: Profile;
    senderTransactionHash: string;
  }): Operation {
    const { senderProfile, senderTransactionHash } = option;

    return {
      action: OperationAction.BridgeReceive,
      eventNames: [IndexEvent.BridgeReceive],
      message: `Failed to receive token for transfer ${senderProfile.chain.name}-${senderTransactionHash}.`,
      getContract: (manager) => manager.getTokenBridgePierContract(),
      populateTx: async (manager) => {
        const { message, signatures } = await this.backendClient.getMessageSignature({
          profile: senderProfile,
          transactionHash: senderTransactionHash,
        });

        return manager.getTokenBridgePierContract().receiveToken.populateTransaction(message, signatures);
      },
    };
  }
}
