// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rt4N4YS4ZkWDLHKn1QkT .BsyTGOWVP0pHNdzRBXI2{margin-top:1em}`, "",{"version":3,"sources":["webpack://./src/component/transaction/ConfirmSection.module.scss"],"names":[],"mappings":"AACE,4CACE,cAAA","sourcesContent":[".ConfirmSection {\n  .buttons {\n    margin-top: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConfirmSection": `rt4N4YS4ZkWDLHKn1QkT`,
	"buttons": `BsyTGOWVP0pHNdzRBXI2`
};
export default ___CSS_LOADER_EXPORT___;
