import styles from './Pre.module.scss';

export type PreProps = React.HTMLAttributes<HTMLPreElement>;

export function Pre(props: PreProps) {
  const { className = '' } = props;

  return (
    <pre
      {...props}
      className={`${styles.Pre} ${className}`}
    />
  );
}
