import styles from './Checkbox.module.scss';

export type CheckboxProps = {
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

export function Checkbox(props: CheckboxProps) {
  const { value = false, onChange, disabled, className = '', style, children } = props;

  return (
    <label className={`${styles.Checkbox} ${className}`} style={style}>
      <input
        checked={value}
        onChange={() => onChange?.(!value)}
        disabled={disabled}
        type="checkbox"
      />
      <div className={styles.checkmark} />
      <div>{ children }</div>
    </label>
  );
}
