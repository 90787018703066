import type { Profile, QueryOption, QueryReturn } from '../entity';
import type * as entity from '../entity';
import type { GraphqlClientConfig } from './GraphqlClient';
import type * as types from './RealmDataClientType';

import { DateTime } from 'luxon';
import { TokenAmount } from '../entity';
import { Transform } from '../util';
import { GraphqlClient } from './GraphqlClient';

// If confirmation is not "Finalized" and epoch is earlier than 30 minutes ago, then the transaction
// is not finalized.
function isFinalized(confirmation: string, epoch: number): boolean {
  return confirmation === 'Finalized' || DateTime.now().toUnixInteger() - epoch < 30 * 60;
}

export type RealmDataClientConfig = GraphqlClientConfig;

export class RealmDataClient {
  public readonly profiles: Profile[];
  private readonly client: GraphqlClient;

  public constructor(profiles: Profile[], config: RealmDataClientConfig) {
    this.profiles = profiles;
    this.client = new GraphqlClient(config);
  }

  public async queryTransfers(option: QueryOption): Promise<QueryReturn<entity.Transfer>> {
    const fields = [
      'sender_uni_chain_id',
      'sender_token',
      'sender_token_amount',
      'sender_wallet',
      'sender_transaction_hash',
      'sender_epoch',
      'receiver_uni_chain_id',
      'receiver_token',
      'receiver_token_amount',
      'receiver_wallet',
      'receiver_transaction_hash',
      'receiver_epoch',
      'receiver_confirmation',
      'fee_owner',
      'fee_relayer',
    ];

    const { rows, count } = await this.client.queryList<types.TransferData>('metariskToken_bridge_records', fields, option);

    return {
      rows: rows.map((item) => {
        const senderProfile = Transform.toProfileFromUniChainId(this.profiles, item.sender_uni_chain_id);
        const senderToken = Transform.toToken(senderProfile, Transform.toString(item.sender_token));

        const receiverProfile = Transform.toProfileFromUniChainId(this.profiles, item.receiver_uni_chain_id);
        const receiverToken = Transform.toToken(senderProfile, Transform.toString(item.receiver_token));

        const feeOwner = TokenAmount.fromMinUnitString(receiverToken, item.fee_owner);
        const feeRelayer = TokenAmount.fromMinUnitString(receiverToken, item.fee_relayer);

        const finalized = isFinalized(item.receiver_confirmation, item.receiver_epoch);

        return {
          senderProfile,
          senderWallet: Transform.toString(item.sender_wallet),
          senderAmount: TokenAmount.fromMinUnitString(senderToken, item.sender_token_amount),
          senderTransactionHash: Transform.toString(item.sender_transaction_hash),
          senderTime: Transform.toTimeFromSeconds(item.sender_epoch),
          receiverProfile,
          receiverWallet: Transform.toString(item.receiver_wallet),
          receiverAmount: finalized ? TokenAmount.fromMinUnitString(receiverToken, item.receiver_token_amount).sub(feeOwner).sub(feeRelayer) : TokenAmount.zero(receiverToken),
          receiverTransactionHash: finalized ? Transform.toString(item.receiver_transaction_hash) : '',
        };
      }),
      count,
    };
  }
}
