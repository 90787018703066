import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import usePagination from '@mui/material/usePagination';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { Button } from '../html/Button';
import { Select } from '../input/Select';

export type PaginationProps = {
  page?: number;
  onPageChange?: (page: number) => void;
  pageSize?: number;
  onPageSizeChange?: (pageSize: number) => void;
  pageSizes?: number[];
  pageCount?: number;
  siblingCount?: number;
  showFirstButton?: boolean;
  showLastButton?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export type PaginationBaseProps = PaginationProps & {
  styles?: {
    base?: string;
    ellipsis?: string;
    page?: string;
    page_selected?: string;
    select?: string;
    button?: string;
    icon?: string;
  };
};

export function PaginationBase(props: PaginationBaseProps) {
  const {
    page = 1,
    onPageChange,
    pageSize,
    onPageSizeChange,
    pageSizes = [],
    pageCount = 0,
    siblingCount = 1,
    showFirstButton = false,
    showLastButton = false,
    className = '', style,
    styles = {},
  } = props;

  const { items } = usePagination({
    page,
    count: pageCount,
    siblingCount,
    showFirstButton,
    showLastButton,
  });

  const handlePage = useCallback((newPage: number | null) => {
    onPageChange?.(newPage ?? 1);
  }, [onPageChange]);

  const handlePageSize = useCallback((newPageSize: number) => {
    onPageChange?.(1); // Reset to the first page.
    onPageSizeChange?.(newPageSize);
  }, [onPageChange, onPageSizeChange]);

  const pageSizeOptions = useMemo(() => {
    return pageSizes.map((item) => ({
      value: item,
      label: item.toString(),
    }));
  }, [pageSizes]);

  return (
    <div className={`${styles.base} ${className}`} style={style}>
      {
        items.map((item, index) => {
          switch (item.type) {
            case 'start-ellipsis':
            case 'end-ellipsis':
              return <div key={index} className={styles.ellipsis}>⋯</div>;

            case 'page':
              return (
                <Button
                  key={index}
                  onClick={() => handlePage(item.page)}
                  disabled={item.disabled}
                  className={classNames(styles.page, {
                    [styles.page_selected as string]: item.selected,
                  })}
                >
                  { item.page }
                </Button>
              );

            case 'previous':
              return (
                <Button key={index} onClick={() => handlePage(item.page)} disabled={item.disabled} className={styles.button}>
                  <KeyboardArrowLeft className={styles.icon} />
                </Button>
              );

            case 'next':
              return (
                <Button key={index} onClick={() => handlePage(item.page)} disabled={item.disabled} className={styles.button}>
                  <KeyboardArrowRight className={styles.icon} />
                </Button>
              );

            case 'first':
              return (
                <Button key={index} onClick={() => handlePage(item.page)} disabled={item.disabled} className={styles.button}>
                  <KeyboardDoubleArrowLeft className={styles.icon} />
                </Button>
              );

            case 'last':
              return (
                <Button key={index} onClick={() => handlePage(item.page)} disabled={item.disabled} className={styles.button}>
                  <KeyboardDoubleArrowRight className={styles.icon} />
                </Button>
              );

            default:
              return null;
          }
        })
      }
      {
        (pageSizeOptions.length > 1) && (
          <Select
            value={pageSize}
            onChange={handlePageSize}
            options={pageSizeOptions}
            className={styles.select}
          />
        )
      }
    </div>
  );
}
