import { Checkbox } from './Checkbox';

import styles from './CheckboxGroup.module.scss';

export type CheckboxGroupOption<T> = {
  value: T;
  label?: React.ReactNode;
};

export type CheckboxGroupProps<P, Q extends CheckboxGroupOption<P>> = {
  value?: P[];
  onChange?: (value: P[]) => void;
  options?: Q[];
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export function CheckboxGroup<P, Q extends CheckboxGroupOption<P>>(props: CheckboxGroupProps<P, Q>) {
  const { value = [], onChange, options = [], disabled = false, className = '', style } = props;

  return (
    <div className={`${styles.CheckboxGroup} ${className}`} style={style}>
      {
        options.map((option, index) => {
          const handleChange = (checked: boolean) => {
            const newValue = checked ? value.concat(option.value) : value.filter((item) => item !== option.value);

            onChange?.(newValue);
          };

          return (
            <Checkbox
              key={index}
              value={value.includes(option.value)}
              onChange={handleChange}
              disabled={disabled}
            >
              { option.label }
            </Checkbox>
          );
        })
      }
    </div>
  );
}
