import type { BaseService } from '../blockchain';
import type { Chain, Token } from '../entity';

import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BlockchainOperator, ContractInvoker } from '../blockchain';
import { getChain } from '../entity';
import { selectWalletSlice } from '../store';
import { useApp } from './UseApp';

export function useBlockchain() {
  const dispatch = useDispatch();
  const { connectWalletModalVisible, walletInfo, chainId, account } = useSelector(selectWalletSlice);

  const { app } = useApp();

  const chain = useMemo(() => {
    return getChain(chainId);
  }, [chainId]);

  const profile = useMemo(() => {
    return app.getProfileByChainId(chainId);
  }, [chainId, app]);

  const invoker = useMemo(() => {
    if (profile == null) {
      return null;
    }

    return new ContractInvoker(app.ethereumManager, profile);
  }, [app, profile]);

  const operator = useMemo(() => {
    return new BlockchainOperator(app.ethereumManager);
  }, [app]);

  const setConnectWalletModalVisible = useCallback((visible: boolean) => {
    dispatch(app.slices.wallet.actions.setConnetWalletModalVisible(visible));
  }, [dispatch, app]);

  const connect = useCallback((connectService: BaseService, connectChainId: number) => {
    return app.ethereumManager.requestConnect(connectService, connectChainId);
  }, [app]);

  const disconnect = useCallback(() => {
    return app.ethereumManager.requestDisconnect();
  }, [app]);

  const supportAddToken = useMemo(() => {
    return walletInfo?.supportAddToken ?? false;
  }, [walletInfo]);

  const addToken = useCallback(async (token: Token) => {
    try {
      await app.ethereumManager.getServiceOrThrow().requestAddToken(token);
    } catch (error) {
      app.logger.log(`Failed to add ${token.symbol} to wallet.`);
    }
  }, [app]);

  const supportSwitchChain = useMemo(() => {
    return walletInfo?.supportSwitchChain ?? false;
  }, [walletInfo]);

  const switchChain = useCallback(async (destinationChain: Chain) => {
    try {
      await app.ethereumManager.getServiceOrThrow().requestSwitchChain(destinationChain);
    } catch (error) {
      app.logger.log(`Failed to switch chain to ${destinationChain.name}.`, error);
    }
  }, [app]);

  return {
    app,
    connectWalletModalVisible,
    setConnectWalletModalVisible,
    walletInfo,
    profile,
    chain,
    account,
    invoker,
    operator,
    connect,
    disconnect,
    supportAddToken,
    addToken,
    supportSwitchChain,
    switchChain,
  };
}
