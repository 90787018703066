// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nWD7JOI1fm8QRSm71_FA{animation:x1i0OvuiVOZoaGQ397ki 2s linear infinite;transform-origin:center center}.nWD7JOI1fm8QRSm71_FA .hSJKqACAvrwU9vRDiZxc{stroke:currentcolor;stroke-dasharray:1,200;stroke-dashoffset:0;stroke-linecap:round;animation:Taj7aiGT6rC_inHlTcgN 1.5s ease-in-out infinite}@keyframes x1i0OvuiVOZoaGQ397ki{100%{transform:rotate(1turn)}}@keyframes Taj7aiGT6rC_inHlTcgN{0%{stroke-dasharray:1,200;stroke-dashoffset:0}50%{stroke-dasharray:89,200;stroke-dashoffset:-35}100%{stroke-dasharray:89,200;stroke-dashoffset:-124}}`, "",{"version":3,"sources":["webpack://./src/component/layout/CircularProgress.module.scss"],"names":[],"mappings":"AAAA,sBACE,iDAAA,CACA,8BAAA,CACA,4CACE,mBAAA,CACA,sBAAA,CACA,mBAAA,CACA,oBAAA,CACA,wDAAA,CAIJ,gCACE,KACE,uBAAA,CAAA,CAIJ,gCACE,GACE,sBAAA,CACA,mBAAA,CAEF,IACE,uBAAA,CACA,qBAAA,CAEF,KACE,uBAAA,CACA,sBAAA,CAAA","sourcesContent":[".CircularProgress {\n  animation: rotate 2s linear infinite;\n  transform-origin: center center;\n  .path {\n    stroke: currentcolor;\n    stroke-dasharray: 1, 200;\n    stroke-dashoffset: 0;\n    stroke-linecap: round;\n    animation: dash 1.5s ease-in-out infinite;\n  }\n}\n\n@keyframes rotate {\n  100% {\n    transform: rotate(1turn);\n  }\n}\n\n@keyframes dash {\n  0% {\n    stroke-dasharray: 1, 200;\n    stroke-dashoffset: 0;\n  }\n  50% {\n    stroke-dasharray: 89, 200;\n    stroke-dashoffset: -35;\n  }\n  100% {\n    stroke-dasharray: 89, 200;\n    stroke-dashoffset: -124;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CircularProgress": `nWD7JOI1fm8QRSm71_FA`,
	"rotate": `x1i0OvuiVOZoaGQ397ki`,
	"path": `hSJKqACAvrwU9vRDiZxc`,
	"dash": `Taj7aiGT6rC_inHlTcgN`
};
export default ___CSS_LOADER_EXPORT___;
