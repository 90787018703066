import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { Pathname } from 'util/Pathname';
import { App } from 'views/App/App';

const router = createBrowserRouter([{
  path: '/',
  element: <App />,
  children: [{
    index: true,
    element: <Navigate to={Pathname.Bridge} />,
  }, {
    path: Pathname.Bridge,
    lazy: () => import('./views/Bridge/BridgePage'),
    children: [{
      index: true,
      element: <Navigate to={Pathname.Transfer} />,
    }, {
      path: Pathname.Transfer,
      lazy: () => import('./views/Transfer/TransferPage'),
    }, {
      path: Pathname.Redeem,
      lazy: () => import('./views/Redeem/RedeemPage'),
    }],
  }, {
    path: '*',
    element: <Navigate to="/" />,
  }],
}]);

export function NavProvider() {
  return <RouterProvider router={router} />;
}
