import { useCallback, useMemo } from 'react';
import { useApp, useTranslationComponent } from '../../hook';
import { copyText, translateError } from '../../util';
import { LinkInterpolate } from '../layout';

import styles from './ErrorMessage.module.scss';

export type ErrorMessageProps = {
  error?: any;
  className?: string;
  style?: React.CSSProperties;
};

export function ErrorMessage(props: ErrorMessageProps) {
  const { error, className = '', style } = props;

  const { t } = useTranslationComponent();
  const { app } = useApp();

  const handleCopy = useCallback(() => {
    copyText(app.logger.getLogs().join('\n'));
  }, [app]);

  const option = useMemo(() => {
    return (error == null) ? null : translateError(t, error);
  }, [error, t]);

  if (option == null) {
    return null;
  }

  return (
    <div className={`${styles.ErrorMessage} ${className}`} style={style}>
      <LinkInterpolate option={option} color />
      <span onClick={handleCopy} className={styles.copy}>{ t('transaction:copyDetail') }</span>
    </div>
  );
}
