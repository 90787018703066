import type { EipProvider } from './EipProvider';
import type { WalletInfo } from './WalletInfo';

import { BaseWindowEthereumService } from './BaseWindowEthereumService';
import { WalletInfos } from './WalletInfo';

type WindowCore = {
  avalanche?: EipProvider;
};

export class CoreService extends BaseWindowEthereumService {
  public constructor() {
    super((window as WindowCore).avalanche);
  }

  public override getInfo(): WalletInfo {
    return WalletInfos.Core;
  }

  public override isInstalled(): boolean {
    return (window as WindowCore).avalanche != null;
  }
}
