import type { Profile } from '../../entity';
import type { UseTransactionReturn } from '../../hook';

import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslationComponent } from '../../hook';
import { ActionButton } from '../input';
import { AdaptiveConnectButton } from '../wallet';

import styles from './ButtonSection.module.scss';

export const Mode = {
  Center: 'center',
  Start: 'start',
  End: 'end',
  Justified: 'justified',
  Vertical: 'vertical',
} as const;

export type ButtonSectionProps = {
  tx: UseTransactionReturn;
  onConfirm?: () => void;
  target?: Profile;
  mode?: typeof Mode[keyof typeof Mode];
  confirmText?: string;
  className?: string;
  style?: React.CSSProperties;
};

export function ButtonSection(props: ButtonSectionProps) {
  const { tx, onConfirm, target, mode, confirmText, className, style } = props;

  const { t } = useTranslationComponent(['transaction']);

  const divClassName = useMemo(() => {
    const classes = {
      [Mode.Center]: styles.mode_center,
      [Mode.Start]: styles.mode_start,
      [Mode.End]: styles.mode_end,
      [Mode.Justified]: styles.mode_justified,
      [Mode.Vertical]: styles.mode_vertical,
    };

    return classNames(styles.ButtonSection, classes[mode ?? Mode.Center], className);
  }, [mode, className]);

  return (
    <div className={divClassName} style={style}>
      <AdaptiveConnectButton target={target} className={styles.button}>
        {
          tx.isApproveVisible && (
            <ActionButton
              onClick={tx.handleApprove}
              disabled={tx.isApproveDisabled}
              loading={tx.isApproveLoading}
              className={styles.button}
            >
              { t('transaction:approve') }
            </ActionButton>
          )
        }
        <ActionButton
          onClick={onConfirm}
          disabled={tx.isConfirmDisabled}
          loading={tx.isConfirmLoading}
          className={styles.button}
        >
          { confirmText ?? t('transaction:confirm') }
        </ActionButton>
      </AdaptiveConnectButton>
    </div>
  );
}
