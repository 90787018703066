// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bQYnzL7kWzAoTd992vHX{min-height:100vh;background:var(--palette-normal-main);color:var(--palette-normal-text)}`, "",{"version":3,"sources":["webpack://./src/component/layout/Drawer.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,qCAAA,CACA,gCAAA","sourcesContent":[".Drawer {\n  min-height: 100vh;\n  background: var(--palette-normal-main);\n  color: var(--palette-normal-text);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Drawer": `bQYnzL7kWzAoTd992vHX`
};
export default ___CSS_LOADER_EXPORT___;
