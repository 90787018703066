import classNames from 'classnames';
import { useMemo } from 'react';
import { useInternalState } from '../../hook';

export type TabPaneProps = {
  title: string;
  tabId?: string;
  children?: React.ReactNode;
};

export function TabPane(props: TabPaneProps) {
  const { children } = props;

  return <>{ children }</>;
}

export type TabsProps = {
  tabId?: string;
  onTabIdChange?: (tabId: string) => void;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactElement<TabPaneProps>[];
};

export type TabsBaseProps = TabsProps & {
  styles?: {
    base?: string;
    top?: string;
    titles?: string;
    title?: string;
    title_selected?: string;
    tabs?: string;
    tab?: string;
    tab_selected?: string;
  };
};

export function TabsBase(props: TabsBaseProps) {
  const {
    tabId, onTabIdChange, prepend, append, className = '', style, children = [], styles = {},
  } = props;

  const panes = useMemo(() => {
    return children.map((item, index) => ({
      tabId: item.props.tabId ?? index.toString(),
      node: item,
    }));
  }, [children]);

  // Manage tabId internally if the parent does not control it.
  const [tabIdInternal, setTabIdInternal] = useInternalState(tabId, panes[0]?.tabId ?? '', onTabIdChange);

  return (
    <div className={`${styles.base} ${className}`} style={style}>
      <div className={styles.top}>
        { prepend }
        <div className={styles.titles}>
          {
            panes.map((item, index) => {
              const isActive = item.tabId === tabIdInternal;

              const titleClassName = classNames(styles.title, {
                [styles.title_selected ?? '']: isActive,
              });

              return (
                <div
                  key={index}
                  onClick={() => setTabIdInternal(item.tabId)}
                  className={titleClassName}
                >
                  { item.node.props.title }
                </div>
              );
            })
          }
        </div>
        { append }
      </div>
      <div className={styles.tabs}>
        {
          panes.map((item, index) => {
            const isActive = item.tabId === tabIdInternal;

            const paneClassName = classNames(styles.tab, {
              [styles.tab_selected ?? '']: isActive,
            });

            const paneStyle = {
              display: isActive ? 'block' : 'none',
            };

            return <div key={index} className={paneClassName} style={paneStyle}>{ item.node }</div>;
          })
        }
      </div>
    </div>
  );
}
