import type { AddressConfig } from './Address';
import type { Chain } from './Chain';
import type { Token } from './Token';
import type { TokenGroup } from './TokenGroup';

import { keyBy } from '../util/FunctionUtil';
import { DEFAULT_ADDRESS_CONFIG } from './Address';
import { DEFAULT_CHAIN } from './Chain';
import { DEFAULT_TOKEN } from './Token';

type RpcConfig = {
  readonly urls: string[];
};

export class Profile {
  public readonly chain: Chain;
  public readonly rpc: RpcConfig;
  public readonly address: AddressConfig;
  public readonly nativeToken: Token;
  public readonly tokensBridge: Token[];
  public readonly tokens: Token[];
  public readonly tokensByAddress: {
    [key: string]: Token;
  };

  public constructor(option?: {
    chain: Chain;
    rpc: RpcConfig;
    address: AddressConfig;
    nativeToken: Token;
    tokensBridge: Token[];
    tokens: Token[];
  }) {
    this.chain = option?.chain ?? DEFAULT_CHAIN;
    this.rpc = option?.rpc ?? {
      urls: [],
    };
    this.address = option?.address ?? DEFAULT_ADDRESS_CONFIG;
    this.nativeToken = option?.nativeToken ?? DEFAULT_TOKEN;
    this.tokensBridge = option?.tokensBridge ?? [],
    this.tokens = option?.tokens ?? [];
    this.tokensByAddress = keyBy(this.tokens, (token) => token.address);
  }

  public getTokenByAddress(address: string | null | undefined): Token | undefined {
    return (address == null) ? undefined : this.tokensByAddress[address];
  }

  public getTokenByAddressOrDefault(address: string | null | undefined): Token {
    return this.getTokenByAddress(address) ?? DEFAULT_TOKEN;
  }

  public getTokenByGroup(tokenGroup: TokenGroup | null | undefined): Token | undefined {
    return this.tokens.find((token) => token.group === tokenGroup);
  }

  public getTokenByGroupOrDefault(tokenGroup: TokenGroup | null | undefined): Token {
    return this.getTokenByGroup(tokenGroup) ?? DEFAULT_TOKEN;
  }
}

export const DEFAULT_PROFILE = new Profile();

export function getProfileByChainId(profiles: Profile[], chainId: number | null | undefined) {
  return profiles.find((item) => item.chain.chainId === chainId);
}

export function getProfileByChainIdOrDefault(profiles: Profile[], chainId: number | null | undefined) {
  return getProfileByChainId(profiles, chainId) ?? DEFAULT_PROFILE;
}
