(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("luxon"), require("@reduxjs/toolkit"), require("react"), require("@mui/icons-material"), require("@mui/material"), require("react-redux"), require("react-i18next"), require("i18next"), require("react-router-dom"), require("@mui/base"), require("@walletconnect/modal"));
	else if(typeof define === 'function' && define.amd)
		define(["luxon", "@reduxjs/toolkit", "react", "@mui/icons-material", "@mui/material", "react-redux", "react-i18next", "i18next", "react-router-dom", "@mui/base", "@walletconnect/modal"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("luxon"), require("@reduxjs/toolkit"), require("react"), require("@mui/icons-material"), require("@mui/material"), require("react-redux"), require("react-i18next"), require("i18next"), require("react-router-dom"), require("@mui/base"), require("@walletconnect/modal")) : factory(root["luxon"], root["@reduxjs/toolkit"], root["react"], root["@mui/icons-material"], root["@mui/material"], root["react-redux"], root["react-i18next"], root["i18next"], root["react-router-dom"], root["@mui/base"], root["@walletconnect/modal"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__26985__, __WEBPACK_EXTERNAL_MODULE__11106__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__70003__, __WEBPACK_EXTERNAL_MODULE__80901__, __WEBPACK_EXTERNAL_MODULE__34756__, __WEBPACK_EXTERNAL_MODULE__33238__, __WEBPACK_EXTERNAL_MODULE__8661__, __WEBPACK_EXTERNAL_MODULE__56128__, __WEBPACK_EXTERNAL_MODULE__35568__, __WEBPACK_EXTERNAL_MODULE__91752__) => {
return 