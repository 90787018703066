import type { Profile } from '../entity';
import type { EthereumManager } from './EthereumManager';

export class ContractInvoker {
  private profile: Profile;
  private manager: EthereumManager;

  public constructor(manager: EthereumManager, profile: Profile) {
    this.profile = profile;
    this.manager = manager;
  }

  public getCurrentBlock() {
    return this.manager.getProviderOrThrow().getBlockNumber();
  }
}
