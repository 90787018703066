import type { Token } from '../entity';

import { TokenGroups } from '../entity';

function createCommonTokens(address: {
  wamtToken: string;
  insurToken: string;
}) {
  const wamt: Token = {
    group: TokenGroups.WAMT,
    symbol: 'WAMT',
    name: 'WAMT',
    isNative: false,
    decimals: 9,
    fractionDigits: 4,
    address: address.wamtToken,
    src: require('../asset/token/amt.png'),
  };

  const insur: Token = {
    group: TokenGroups.INSUR,
    symbol: 'INSUR',
    name: 'INSUR',
    isNative: false,
    decimals: 18,
    fractionDigits: 4,
    address: address.insurToken,
    src: require('../asset/token/insur.png'),
    imageUrl: 'https://files.insurace.io/public/icon/round.png',
  };

  return {
    wamt,
    insur,
  };
}

export class TokenCreator {
  public static createForEth(address: {
    wamtToken: string;
    insurToken: string;
    ethToken: string;
  }) {
    const eth: Token = {
      group: TokenGroups.ETH,
      symbol: 'ETH',
      name: 'ETH',
      isNative: true,
      decimals: 18,
      fractionDigits: 4,
      address: address.ethToken,
      src: require('../asset/token/eth.png'),
    };

    const { wamt, insur } = createCommonTokens(address);

    return {
      nativeToken: eth,
      wamt,
      insur,
      tokens: [eth, wamt, insur],
    };
  }

  public static createForBsc(address: {
    wamtToken: string;
    insurToken: string;
    bnbToken: string;
  }) {
    const bnb: Token = {
      group: TokenGroups.BNB,
      symbol: 'BNB',
      name: 'BNB',
      isNative: true,
      decimals: 18,
      fractionDigits: 4,
      address: address.bnbToken,
      src: require('../asset/token/bnb.png'),
    };

    const { wamt, insur } = createCommonTokens(address);

    return {
      nativeToken: bnb,
      wamt,
      insur,
      tokens: [bnb, wamt, insur],
    };
  }

  public static createForPolygon(address: {
    wamtToken: string;
    insurToken: string;
    maticToken: string;
  }) {
    const matic: Token = {
      group: TokenGroups.MATIC,
      symbol: 'MATIC',
      name: 'MATIC',
      isNative: true,
      decimals: 18,
      fractionDigits: 4,
      address: address.maticToken,
      src: require('../asset/token/matic.png'),
    };

    const { wamt, insur } = createCommonTokens(address);

    return {
      nativeToken: matic,
      wamt,
      insur,
      tokens: [matic, wamt, insur],
    };
  }

  public static createForAvalanche(address: {
    wamtToken: string;
    insurToken: string;
    avaxToken: string;
  }) {
    const avax: Token = {
      group: TokenGroups.AVAX,
      symbol: 'AVAX',
      name: 'AVAX',
      isNative: true,
      decimals: 18,
      fractionDigits: 4,
      address: address.avaxToken,
      src: require('../asset/token/avax.png'),
    };

    const { wamt, insur } = createCommonTokens(address);

    return {
      nativeToken: avax,
      wamt,
      insur,
      tokens: [avax, wamt, insur],
    };
  }
}
