import type { AdaptiveLinkProps } from './AdaptiveLink';

import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { AdaptiveLink } from './AdaptiveLink';

export type LinkInterpolateProps = {
  option: string | {
    text: string;
    embed?: string[];
    values?: { [key: string]: string };
  };
  color?: AdaptiveLinkProps['color'];
  hoverColor?: AdaptiveLinkProps['hoverColor'];
  icon?: AdaptiveLinkProps['icon'];
};

export function LinkInterpolate(props: LinkInterpolateProps) {
  const { option, color, hoverColor, icon } = props;

  const { text, embed, values } = useMemo(() => {
    return (typeof option === 'string') ? {
      text: option,
      embed: [],
      values: {},
    } : {
      text: option.text,
      embed: option.embed ?? [],
      values: option.values ?? {},
    };
  }, [option]);

  const components = useMemo(() => {
    return embed.map((link, index) => (
      <AdaptiveLink key={index} href={link} color={color} hoverColor={hoverColor} icon={icon} />
    ));
  }, [embed, color, hoverColor, icon]);

  return <Trans components={components} values={values}>{ text }</Trans>;
}
